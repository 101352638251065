.homepage {

    &>* {
        padding-left: max(15px, vw(50px));
        padding-right: max(15px, vw(50px));

        .swiper-slide {
            @include transition($time:1s);
            overflow: hidden;
        }
    }

    &__landing {
        @media screen and (min-width: ($tablet + 1)) {
            padding-top: vw(40px);
        }

        @media screen and (max-width: $tablet) {
            padding-left: 0;
            padding-right: 0;
        }

        &--banner {
            width: vw(1253px);
            height: vw(816px);
            margin: auto;
            margin-bottom: 103px;

            @media screen and (max-width: $tablet) {
                width: 100%;
                height: auto;

                img {
                    object-fit: cover;
                    min-height: calc(var(--screen-height) - var(--header-height))
                }
            }

            .video-wrap {
                width: 100%;
                height: 100%;

                video {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;

                    //@media screen and (max-width: $tablet) {
                    //    min-height: calc(var(--screen-height) - var(--header-height))
                    //}
                }
            }
        }

        &--title {
            @extend .title--big;
            position: absolute;
            top: 25px;
            left: 25px;
            color: $white;
        }

        &--cta {
            position: absolute;
            bottom: 20px;
            right: 40px;
            color: $white;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            color: $white;
            text-decoration: none;

            span {
                &:first-child {
                    @media screen and (max-width: $tablet) {
                        margin-bottom: 10px;
                    }
                }

                &:last-child {
                    @extend .title--big;
                }
            }

            &:hover {
                color: $white;
                text-decoration: none;
            }
        }
    }

    &__recommended, &__section_six {

        &--title {
            @extend .title--big;
            margin-bottom: vw(-140px);

            @media screen and (max-width: $tablet) {
                margin-bottom: 40px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        
        @media screen and (max-width: $tablet) {
            .slider-wrap {
                max-width: 515px;
                margin: auto;
            }
        }

        .arc {
            display: flex;
            justify-self: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            img {
                width: max(332px, vw(685px));
                height: 100%;
                margin: auto;

                @media screen and (max-width: $tablet) {
                    width: 100%;
                    max-width: 90%;
                    content: var(--arc-mob);
                }
            }

        }

        &--slider {
            display: flex;
            align-items: center;
            min-height: vw(801px);

            @media screen and (min-width: ($tablet + 1)) and (max-width: $laptop-xs) {
                padding: 40px 0;
            }

        }

        &__sliderwrap {
            align-items: center;
            left: vw(-210px);
            margin-bottom: vw(-130px);
            
            @media screen and (max-width: $laptop-xs) {
                margin-bottom: vw-rs($laptop-xs, -65px);
                min-height: vw(801px);
            }

            @media screen and (max-width: 515px) {
                min-height: max(444px, vw-rs(515px, 575px));
            }

            @media screen and (max-width: $tablet) {
                margin-bottom: unset;
                left: unset;
                margin-top: 85px;
            }
        }

        .swiper-slide {
            margin: 0;
            height: auto;
            width: 100%;
            padding: 0;
                    
            @media screen and (min-width: ($tablet + 1)) {
                margin-top: 70px;
                .card_view {
                    .card_view--image {

                        &>img {
                            @include transition($time: 1s);
                            min-width: 265px;
                            max-width: 265px;
                            min-height: max(165px, vw(347px));
                            max-height: max(165px, vw(347px));
                            object-fit: cover;
                        }
                    }
                }

                &-active {
                    margin-top: 0;

                    .card_view {
                        .card_view--image {

                            &>img {
                                min-width: max(257px, vw(446px));
                                max-width: max(257px, vw(446px));
                                min-height: max(337px, vw(583px));
                                max-height: max(337px, vw(583px));
                                object-fit: cover;
                            }
                        }
                    }

                }
            }

            &-active {
                opacity: 1;

                @media screen and (min-width: ($tablet + 1)) {
                    width: vw(446px) !important;
                    margin-left: vw(127px);
                    margin-right: vw(127px) !important;

                }
            }

            &:hover {
                .card_view--active {
                    background-color: rgba(#333333, 0.65);
                }
            }
            
            .card_view {

                &--active {
                    @include transition;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    pointer-events: none;

                    &-description {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: vw(270px);
                        width: vw(446px);
                        margin: auto;
                        color: $white;
                        width: 100%;
                        max-width: 65%;

                        .out-of-stock {
                            @include font($font-default, #{max(14px, vw(22px))}, $weight-light, normal, 1);
                            color: $white;
                            text-transform: uppercase;
                            text-align: center;
                        }
                        .pre-order {
                            text-align: center;
                            @include font($font-default, #{max(10px, vw(18px))}, $weight-light, normal, 1);
                        }

                        @media screen and (max-width:$laptop) {
                            max-width: 82%;
                        }
                    }

                    &-options {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        padding-bottom: vw(20px);
                        padding-left: vw(35px);
                        padding-right: vw(35px);
                        @media screen and (max-width:1225px) {
                            padding-left: 5px;
                            padding-right: 5px;
                        }
                        @media screen and (max-width:$tablet) {
                            padding-left: vw(35px);
                            padding-right: vw(35px);
                        }

                        &>* {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            color: $white;
                            flex: 1;

                            &.disabled {
                                // opacity: .25;
                                pointer-events: none;
                            }
                        }
                    }

                    &-left {
                        &>* {
                            &:not(:last-child) {
                                margin-bottom: 10px;
                            }
                        }
                        .card_view--active-title {                            
                            word-break: break-word;
                        }
                    }

                    &-center {
                        text-align: center;
                        flex: 2;
                        margin-bottom: 10px;
                    }

                    &-right {
                        text-align: right;
                        margin-bottom: 22px;
                        opacity: 0!important;
                        display: none;
                    }

                    &-title {}

                    &-available-sizes-static {
                        span {
                            &:not(:last-child) {
                                margin-right: 15px;
                            }
                        }
                    }

                    &-price {}

                    &-cta-pre {
                        margin-bottom: 10px;
                        cursor: pointer;
                    }

                    &-cta-add {
                        cursor: pointer;
                    }

                    &-cta-view {
                        @include transition($time:1s);
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        margin-top: 15px;
                        opacity: 0;
                        pointer-events: none;
                        .underline--black{
                            &:after{
                                content: "";
                                width: 0;
                                height: 2px;
                                position: absolute;
                                bottom: -3px;
                                left: 0;
                                background-color: #e7d6c8;
                                transition: width 0.8s;
                            }
                            &:hover:after{
                                width: 101%;
                            }
                        }
                        .card_view--favorites {
                            margin-left: 20px;
                        }
                    }
                }
            }
        }

        .swiper-slide {
            &-active {
                .card_view--footer {
                    opacity: 0;
                    pointer-events: none;
                    max-height: 0;
                    padding: 0;
                }
            }
        }

        @extend .slider-type--inline;
    }

    &__sub-recommended {

        &--slider {
            margin-left: vw(96px);
            margin-right: vw(96px);
        }

        &--title {
            @extend .title--big;
            margin-bottom: 25px;

            @media screen and (max-width: $tablet-sm) {
                margin-bottom: 100px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        .swiper-slide {

            @media screen and (min-width: ($tablet + 1)) {
                &-active {
                    scale: .85;
                }

                .card_view {
                    .card_view--image {
                        &>img {
                            @include transition($time: 1s);
                            min-width: vw(295px);
                            max-width: vw(295px);
                            min-height: vw(444px);
                            max-height: vw(444px);
                            object-fit: cover;
                        }
                    }
                }
            }
        }


        @extend .slider-type--inline;
    }

    &__best-sellers {

        @media screen and (min-width: ($laptop + 1)) {
            &__sliderwrap {
                left: -1px;
            }
        }

        &--slider {
            margin-left: vw(62px);
            margin-right: vw(115px);
        }

        &--title {
            @extend .title--big;
            margin-bottom: 25px;

            @media screen and (max-width: $tablet-sm) {
                margin-bottom: 100px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        .swiper-slide {
            @media screen and (min-width: ($tablet + 1)) {
                &-active {
                    @media (hover: hover) {
                        scale: .8;
                        transform-origin: right;
                    }
                }

                .card_view {
                    .card_view--image {
                        &>img {
                            @include transition($time: 1s);
                            min-width: vw(409px);
                            max-width: vw(409px);
                            min-height: vw(618px);
                            max-height: vw(618px);
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        &__sliderwrap{
            &.less-than-expected {
                .swiper-slide {
                    @media screen and (min-width: ($tablet + 1)) {
                        &-active {
                            @media (hover: hover) {
                                scale: 1;
                            }
                        }
                    }
                }
            }
        }

        @extend .slider-type--inline;
    }

    &__shop-the-look {

        &--title {
            @extend .title--big;
            margin-bottom: vw(-50px);

            @media screen and (max-width: $tablet-sm) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        @media screen and (max-width: $tablet) {
            .slider-wrap {
                max-width: 515px;
                margin: auto;
            }
        }

        &--slider {
            display: flex;
            align-items: center;
            min-height: vw(801px);

            .arc {
                display: flex;
                justify-self: center;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                img {
                    width: max(332px, vw(699px));
                    height: 100%;
                    margin: auto;

                    @media screen and (max-width: $tablet) {
                        width: 100%;
                        max-width: 90%;
                        content: var(--arc-mob);
                    }
                }

            }
        }

        &__sliderwrap {
            align-items: center;

            @media screen and (min-width: ($tablet + 1)) {
                min-height: vw(806px);
                left: vw(-257px);
            }

            @media screen and (max-width: $tablet) {
                padding-bottom: 50px;
            }

            .swiper-slide {

                @media screen and (max-width: $tablet) {
                    margin-top: vw-rs(375px, 80px);
                }

                @media screen and (min-width: ($tablet + 1)) {
                    // min-width: 176px;
                    // max-width: 176px;
                    min-height: max(165px, vw(220px));
                    max-height: max(165px, vw(220px));
                    margin-bottom: vw(-80px);

                    .card_view {
                        .card_view--image {
                            &>img {
                                @include transition($time: 1s);
                                min-width: 176px;
                                max-width: 176px;
                                min-height: max(165px, vw(220px));
                                max-height: max(165px, vw(220px));
                                object-fit: cover;
                            }
                        }
                    }

                    &-active {
                        // min-width: max(276px, vw(484px));
                        // max-width: max(276px, vw(484px));
                        min-width: vw(484px);
                        max-width: vw(484px);
                        min-height: max(345px, vw(605px));
                        max-height: max(345px, vw(605px));

                        .card_view {
                            .card_view--image {
                                &>img {
                                    // min-width: max(276px, vw(484px));
                                    // max-width: max(276px, vw(484px));
                                    min-width: vw(484px);
                                    max-width: vw(484px);
                                    min-height: max(345px, vw(605px));
                                    max-height: max(345px, vw(605px));
                                    object-fit: cover;
                                }
                            }
                        }

                    }
                }
            }
        }

        &__item {
            margin: 0;
            height: auto;
            width: 100%;
            padding: 0;

            &.swiper-slide-active {

                @media screen and (min-width: ($tablet + 1)) {
                    width: vw(446px) !important;
                    margin-left: vw(107px);
                    margin-right: vw(107px) !important;

                    &~* {
                        opacity: 0;
                        pointer-events: none;
                    }
                }

                .card_view--item-trigger {
                    display: unset;
                }
            }

            &.swiper-slide-active {
                .card_view--footer {
                    display: none;
                }

                .card_view--active {
                    &.selection-open {
                        opacity: 1;
                        pointer-events: all;
                    }
                }
            }
        }

        @extend .slider-type--inline;
    }

    &__special-offers {
        margin-top: vw(140px);

        &--slider {
            margin-left: vw(115px);
            margin-right: vw(115px);
        }

        &--title {
            @extend .title--big;
            margin-bottom: max(70px, vw(75px));

            @media screen and (max-width: $tablet-sm) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        .swiper-slide {
            @media screen and (min-width: ($tablet + 1)) {

                &:hover {
                    @media (hover: hover) {
                        scale: .85;
                    }
                }

                .card_view {
                    .card_view--image {
                        &>img {
                            @include transition($time: 1s);
                            min-width: vw(295px);
                            max-width: vw(295px);
                            min-height: vw(444px);
                            max-height: vw(444px);
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        @extend .slider-type--inline;
    }

    &__social {
        margin-top: max(100px, vw(250px));
        margin-bottom: vw(110px);
        margin-left: vw(65px);
        margin-right: vw(65px);

        &--title {
            @extend .title--big;
            text-align: center;
            margin-bottom: max(30px, vw(110px));
        }

        &__item {
            width: vw(507px);
            height: vw(507px);
            margin-right: vw(80px);

            &:before {
                @include transition;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(#ffffff, .25);
                z-index: 1;
                pointer-events: none;
                opacity: 0;
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26.1 26.5' style='enable-background:new 0 0 26.1 26.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23333333;%7D%0A%3C/style%3E%3Cg%3E%3Cg%3E%3Cpath class='st0' d='M20,26.5H6c-3.3,0-6-2.7-6-6V6c0-3.3,2.7-6,6-6h14c3.3,0,6,2.7,6,6v14.4C26,23.8,23.3,26.5,20,26.5z M6,0.3 C2.8,0.3,0.2,2.9,0.2,6v14.4c0,3.2,2.6,5.8,5.8,5.8h14c3.2,0,5.8-2.6,5.8-5.8V6c0-3.2-2.6-5.8-5.8-5.8H6z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st0' d='M13,20.3c-3.9,0-7.1-3.2-7.1-7.1S9.1,6.2,13,6.2s7.1,3.2,7.1,7.1S16.9,20.3,13,20.3z M13,6.4 c-3.8,0-6.8,3.1-6.8,6.8s3.1,6.8,6.8,6.8s6.8-3.1,6.8-6.8S16.8,6.4,13,6.4z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st0' d='M20.7,7.2c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C22.7,6.3,21.8,7.2,20.7,7.2z M20.7,3.4 c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8S21.6,3.4,20.7,3.4z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
                background-size: max(19px, vw(83px));
                background-repeat: no-repeat;
                background-position: center;
            }

            a {
                display: flex;
                width: 100%;
                height: 100%;

                img {
                    object-fit: cover;
                    height: 100%;
                }
            }

            .social-view {
                a {
                    img {
                        width: vw(507px);
                        height: vw(507px);
                        object-fit: cover;
                    }
                }
            }

            &:hover {
                @media (hover: hover) {
                    scale: .8;
                    transform-origin: center;
                }

                &:before {
                    @include transition;
                    opacity: 1;
                }
            }
        }
    }

}

.selections {
    @include transition(background-color);
    z-index: 2;
    position: absolute;
    width: max(200px, vw(250px));
    top: vw(83px);
    right: vw(254px);

    @media screen and (max-width: 1366px) {
        right: vw(150px);
    }

    @media screen and (max-width: $tablet) {
        position: fixed;
        top: var(--header-height);
        left: 0;
        width: 100%;
        height: calc(var(--screen-height) - var(--header-height));
        display: flex;
        justify-content: center;
        pointer-events: none;

        &.overlay {
            pointer-events: all;
            // background-color: rgba(#4D4D4D, .85);            
            background-color: rgb(43, 40, 38, .85);
            color: $white;
            overflow: auto;
            overscroll-behavior-y: contain;
            overflow-y: auto;
        }
    }

    .selections-wrap {
        @media screen and (max-width: $tablet) {
            width: 200px;
        }
    }

    .card_view--active {
        @include transition;
        display: flex;
        flex-direction: column;
        position: absolute;

        // width: max(245px, vw(276px));
        @media screen and (min-width: ($tablet + 1)) {
            top: -30px;
        }

        left: 0;
        opacity: 0;
        pointer-events: none;
        visibility: hidden;

        &.selection-open {
            opacity: 1;
            pointer-events: all;
            visibility: visible;

            @media screen and (max-width: $tablet) {
                padding-top: 50px;
                padding-bottom: 50px;
            }
        }

        .details--pop-up-title {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .card_view--favorites {
                display: flex;
                cursor: pointer;
            }
        }

        &-close {
            position: absolute;
            top: 8px;
            right: 10px;
            max-width: 31px;
            cursor: pointer;

            @media screen and (max-width: $tablet) {
                position: fixed;
                top: calc(var(--header-height) + 15px);
                right: 15px;
                filter: invert(1);
            }
        }

        &-image {
            padding: 12.5px;
            border: 1px solid $black;

            &>a  {
                display: flex;
                position: relative;
                img {
                    min-height: max(221px, vw(310px));
                    max-height: max(221px, vw(310px));
                    min-width: max(178px, vw(250px));
                    max-width: max(178px, vw(250px));
                    object-fit: cover;
                    object-position: top;
                }
                .pre-order, .out-of-stock {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(51, 51, 51, 0.65);
                    opacity: 1;
                    pointer-events: none;
                    line-height: 1.2;
                    color: $white;
                }
                .pre-order {
                    font-size: 14px;
                    padding: 0 5px;
                }
                .out-of-stock {
                    text-transform: uppercase;
                }
            }


            @media screen and (max-width: $tablet) {
                border-color: $white;
            }
        }

        &-description {
            padding: 10px;
            min-height: 200px;
            @media screen and (max-width: $mobile) {
                min-height: unset;
            }
            ul {
                margin-top: 20px;

                li {
                    margin-bottom: 5px;

                    &:before {
                        content: var(--list-icon);
                        margin-right: 5px;

                        @media screen and (max-width: $tablet) {
                            filter: invert(1);
                        }
                    }
                }
            }
        }

        &-options {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding: 10px;

            &>* {
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: $white;
                flex: 1;

                &.disabled {
                    // opacity: .25;
                    pointer-events: none;
                }
            }
        }


        &-title {}

        &-price {}

        &-add-to-cart {
            padding-top: 15px;
        }

        &-cta-add {
            cursor: pointer;
        }

        &-cta-view {
            cursor: pointer;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
            align-self: center;
        }
    }

    &--all-current {
        @include transition(opacity);
        background-color: rgb(43, 40, 38, .85);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: var(--screen-height);
        max-height: var(--screen-height);
        overscroll-behavior-y: contain;
        overflow: auto;
        display: unset;
        justify-content: unset;
        right: unset;
        z-index: -100;
        opacity: 0;
        pointer-events: none;

        &-close {
            position: absolute;
            top: 8px;
            right: 10px;
            max-width: 31px;
            cursor: pointer;
            filter: invert(1);
            z-index: 1;
        }

        &--show {
            opacity: 1;
            pointer-events: all;
            z-index:100;
        }

        .selections-wrap {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding-bottom: 30px;

            @media screen and (max-width: $mobile) {
                justify-content: center;
            }

            .card_view--active {
                opacity: 1;
                pointer-events: all;
                position: unset;
                top: unset;
                left: unset;
                color: $white;
                margin-right: 10px;
                margin-left: vw(160px);
                visibility: visible;
                margin-top: 150px;

                @media screen and (max-width: $laptop) {
                    margin-top: vw(150px);                    
                }

                @media screen and (max-width: $tablet) {
                    margin-top: max(30px, vw-rs($tablet, 70px));                    
                }

                &-image {
                    border-color: $white;
                }

                &-description {
                    ul li:before {
                        filter: invert(1);
                    }
                }
            }

        }
    }
}
.homepage__social .slider-wrap{
    display: none;
}
.details--pre-order {
    margin-bottom: 10px;
    cursor: pointer;
}

.old-price {
    text-decoration: line-through;
    font-style: italic;
}