// * INPUT STRACTURE
@mixin inputStracture( //

    $border-width: null,
    $border-style: null,
    $border-radius: null,
    $padding: null //

) {

    border-width: $border-width;
    border-style: $border-style;
    border-radius: $border-radius;
    padding: $padding;
    display: flex;
    width: 100%;
    white-space: nowrap;
    margin: 0;
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

@mixin inputStyle( //

    $background-color,
    $text-color,
    $border-color,
    $hover-color: null,
    $error-color: null //

) {

    @include transition;
    background-color: $background-color;
    color: $text-color;
    border-color: $border-color;

    &::placeholder {
        @include transition;
        color: $text-color;
        opacity: 1;
    }

    &:-ms-input-placeholder {
        @include transition;
        color: $text-color;
    }

    &::-ms-input-placeholder {
        @include transition;
        color: $text-color;
    }

    @if type-of($hover-color) !=null {

        &:hover,
        &:active {
            color: $hover-color;
            border-color: $hover-color;

            &::placeholder {
                color: $hover-color;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: $hover-color;
            }

            &::-ms-input-placeholder {
                color: $hover-color;
            }
        }

        &:focus {
            &::placeholder {
                color: transparent;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: transparent;
            }

            &::-ms-input-placeholder {
                color: transparent;
            }
        }
    }

    @if type-of($error-color) !=null {

        &.wpcf7-not-valid,
        &.has-error {
            color: $error-color;
            border-color: $error-color;

            &::placeholder {
                color: $error-color;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: $error-color;
            }

            &::-ms-input-placeholder {
                color: $error-color;
            }
        }
    }
}