.offwhite-collections {
	&__wrap {
		padding: max(50px, vw(75px)) max(15px, vw(105px)) max(35px, vw(120px));
		@media screen and (max-width: $tablet) {
			padding-left: 30px;
			padding-right: 30px;
		}
		@media screen and (max-width: $tablet-sm) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	&__hero {
		margin-bottom: max(15px, vw(100px));
		height: max(140px, vw(627px));
		@media screen and (max-width: $mobile) {
			margin-bottom: 15px;
		}

		video {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.image{
			width: 100%;
			height: 100%;
			img{
				height: 100%;
				object-fit: cover;
			}
		}

		.text{
			width: 100%;
			text-align: center;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			color: $white;
			font-family: $font-default;
			font-size: max(30px, vw(340px));
			font-weight: 300;
		}
	}

	&--slider {
		&-section{

		}

		&__sliderwrap {

		}

		&__item {

		}

		&__navigation {
			display: flex;
			justify-content: space-between;
			width: 100%;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			&>div{
				width: 23px;
			}
		}

		&-wrap {
			width: 95%;
			margin: 0 auto 4.844vw;
			@media screen and (max-width: $tablet-sm) {
				width: 100%;
			}
		}
	}

	&__visit{
		display: flex;
		justify-content: space-between;
		@media screen and (max-width: $tablet-sm){
			flex-direction: column;
		}
		.map{
			flex: 1;
			width: max(300px , vw(896px));
			height: max(350px , vw(896px));
			@media screen and (max-width: $tablet-sm){
				flex: unset;
				width: 100%;
				margin-bottom: 20px;
			}
		}
		.details{
			flex: 1;
			margin-left: max(0px, vw(61px));
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			@media screen and (max-width: $tablet-sm){
				margin-left: 0;
				width: 75%;
			}
			@media screen and (max-width: $mobile){
				width: 100%;
			}
			&__helper{
				max-width: vw(390px);
				@media screen and (max-width: $tablet-sm){
					max-width: 350px;
				}
			}
			&__title{
				@extend .title--big;
				text-transform: uppercase;
				@media screen and (max-width: $tablet-sm){
					max-width: 500px;
					margin: 0 0 30px;
				}
				@media screen and (max-width: $mobile){
					max-width: 240px;
				}
			}
			&__subtitle{
				@extend .title--small;
				display: inline-flex;
				font-size: max(16px, vw(29px));
				border-bottom: 1px solid $black;
				margin-bottom: max(23px, vw(23px));
				@media screen and (max-width: $tablet-sm){
					line-height: 1.3;
				}
				@media screen and (max-width: $mobile){
					max-width: 220px;
				}
			}
			&__text{
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				span,a{
					@extend .title--small;
					font-size: max(14px, vw(24px));
					display: inline-flex;
					margin-bottom: 7.5px;
				}

				.book-modal-trigger{
					display: inline-flex;
					margin-top: max(15px, vw(38px));
					font-size: 16px;
					line-height: 24px;
					font-weight: 400;
					@media screen and (max-width: $tablet-sm){
						text-decoration: underline;
						text-underline-offset: 6px;
					}
					&:before{
						@media screen and (max-width: $tablet-sm){
							display: none;
						}
					}
				}
			}
		}
	}
}