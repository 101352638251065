.single-post-container {
  background-color: #e7d6c8;
  padding: max(15px, vw(60px)) max(0px, vw(107px)) max(35px, vw(90px));
  @media screen and (max-width: $tablet-sm){
    padding: 15px 0 35px;
  }
  .go-back {
    position: unset;
    display: inline-flex;
    margin-bottom: 7px;
    @media screen and (max-width: $tablet-sm){
      margin-left: 3px;
    }
  }

  .single-post-video-container {
    margin-bottom: max(10px, vw(100px));
    video{
      width: 100%;
    }
  }
  .single-post-slider-wrap {
    &:not(.margin-none){
      margin-bottom: max(35px, vw(94px));
    }
    @media screen and (max-width: $tablet-sm){
      padding: 0 3px;
    }
  }
  .single-post-slider-container {
    width: 95%;
    @media screen and (max-width: $tablet-sm){
      width: 100%;
    }
  }
  .slider-handle {
    &-single-post{
      top: 0;
      @media screen and (max-width: $tablet-sm){
        display: none;
      }
    }
  }
  .default-info{
    p{
      margin-bottom: unset;
    }
  }
}