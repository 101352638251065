/*========================================================*/
/* Product Page */
/*========================================================*/
.product-container {
    padding: max(35px, vw(80px)) max(15px, vw(90px)) max(35px, vw(135px));

    @media (max-width: $tablet) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (max-width: $tablet) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .breadcrumbs-wrap {
        padding: 0;
    }

    .product-row {
        max-width: 1460px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        @media (max-width: $tablet-sm) {
            flex-direction: column;
        }

        .left-col {
            width: 100%;
            max-width: 755px;
            padding-bottom: 100px;
            @media (max-width: $tablet) {
                padding-bottom: 0;
            }

            .product--images {
                max-width: max(340px, vw(755px));
                display: flex;
                flex-direction: row;
                height: max(440px, vw(900px));
                max-height: max(440px, vw(900px));

                .gallery-top {
                    img {
                        width: 100%;
                        object-fit: cover;
                        max-width: max(285px, vw(600px));
                        height: 100%;

                        @media (max-width: $tablet-sm) {
                            max-width: 100%;
                        }
                    }
                }

                @media (max-width: $tablet-sm) {
                    height: 85vw;
                    min-height: 600px;
                    max-height: 600px;
                    max-width: 560px;
                }

                @media (max-width: $mobile) {
                    max-height: 530px;
                    min-height: 530px;
                }

                @media (max-width: $mobile-sm) {
                    height: 95vw;
                    max-height: 465px;
                    min-height: 465px;
                }

                .swiper-container {
                    overflow: hidden;
                    max-height: vw(900px);

                    @media (max-width: $tablet-sm) {
                        max-height: 95vw;
                    }
                }

                .gallery-top {
                    margin-right: 5px;
                    max-width: max(285px, vw(600px));

                    @media (max-width: $tablet-sm) {
                        max-width: 80vw;
                    }

                    .MagicZoom {
                        z-index: 999 !important;
                    }

                    video {
                        width: 100%;
                        object-fit: cover;
                        max-width: max(285px, vw(600px));
                        height: 100%;

                        @media (max-width: $tablet-sm) {
                            max-width: 100%;
                        }
                    }
                }

                .gallery-thumbnail {
                    position: relative;
                    width: 100%;
                    max-width: max(70px, vw(149px));

                    .swiper-slide {
                        cursor: pointer;
                        width: 100%;
                        max-width: max(70px, vw(149px));

                        img {
                            object-fit: cover;
                            width: 100%;
                            max-width: max(70px, vw(149px));
                            height: 100%;
                        }

                        span.video-overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: #fff;
                            z-index: 9;
                            opacity: 0.3;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .video-play {
                                cursor: pointer;
                                display: flex;
                                width: max(35px, vw(70px));
                                height: max(35px, vw(70px));

                                svg {
                                    display: flex;
                                    width: max(35px, vw(70px));
                                    height: max(35px, vw(70px));

                                    .st0 {
                                        fill: none;
                                        stroke: #000000;
                                        stroke-width: 0.5669;
                                        stroke-miterlimit: 10;
                                    }
                                }
                            }
                        }
                    }

                    video {
                        width: 100%;
                        object-fit: cover;
                        max-height: max(90px, vw(225px));
                    }

                    @media (max-width: $tablet-sm) {
                        max-width: 16vw;

                        .swiper-slide {
                            max-width: 16vw;

                            img {
                                max-width: 16vw;
                            }
                        }
                    }

                    .swiper-button-next {
                        margin: 0 !important;
                        top: unset;
                        bottom: 0;
                        width: 26px;
                        height: 18px;
                        left: 50%;
                        -webkit-transform: translateX(-50%);
                        -moz-transform: translateX(-50%);
                        -o-transform: translateX(-50%);
                        transform: translateX(-50%);

                        &:after {
                            content: '';
                            background-image: url("../../../icons/arrow-next-white.svg");
                            background-repeat: no-repeat;
                            background-size: cover;
                            width: 26px;
                            height: 18px;
                        }
                    }

                    .swiper-button-prev {
                        margin: 0 !important;
                        top: 0;
                        width: 26px;
                        height: 18px;
                        right: unset;
                        left: 50%;
                        -webkit-transform: translateX(-50%);
                        -moz-transform: translateX(-50%);
                        -o-transform: translateX(-50%);
                        transform: translateX(-50%);

                        &:after {
                            content: '';
                            background-image: url("../../../icons/arrow-prev-white.svg");
                            background-repeat: no-repeat;
                            background-size: cover;
                            width: 26px;
                            height: 18px;
                        }
                    }
                }
            }

        }

        .right-col {
            width: 100%;
            max-width: 460px;
            position: sticky;
            top: calc(var(--header-height) + 32px);

            @media (max-width: $tablet-sm) {
                max-width: 100%;
                padding-top: 25px;
                position: unset;
                top: unset;
            }

            .product-details {
                width: 100%;
            }
        }
    }
}

.product-container {
    .product-row {
        .left-col {
            &.off-white {
                .product--images {
                    .gallery-thumbnail {
                        .swiper-button {
                            &-next {
                                &:after {
                                    background-image: url("../../../icons/arrow-next-gray.svg");
                                }
                            }
                            &-prev {
                                &:after {
                                    background-image: url("../../../icons/arrow-prev-gray.svg");
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.photo-slider-handlers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: max(250px, vw(600px));
    margin-top: 20px;

    li {
        display: flex;

        &:not(:last-child) {
            margin-right: max(10px, vw(25px));
        }

        #go-to-first-element {
            cursor: pointer;
            display: flex;
            width: max(20px, vw(40px));
            height: max(20px, vw(40px));

            svg {
                width: max(20px, vw(40px));
                height: max(20px, vw(40px));

                .st0 {
                    fill: none;
                    stroke: #000000;
                    stroke-width: 0.5669;
                    stroke-miterlimit: 10;
                }
            }
        }

        #go-to-video {
            cursor: pointer;
            width: max(20px, vw(40px));
            height: max(20px, vw(40px));

            svg {
                width: max(20px, vw(40px));
                height: max(20px, vw(40px));

                .st0 {
                    fill: none;
                    stroke: #000000;
                    stroke-width: 0.5669;
                    stroke-miterlimit: 10;
                }
            }
        }
    }
}

.fancybox-navigation {
    top: 50%;
}

.fancybox-thumbs__list a:before {
    border-color: $beige;
}

.fancybox-progress {
    background: $beige;
}

/***Product info***/
h1.product-name {
    @include font($font-default, #{max(14px, vw(36px))}, $weight-thin, normal, 1);
    margin-bottom: 5px;
}

h2.product-description {
    @include font($font-default, #{max(12px, vw(29px))}, $weight-thin, normal, 1);
    color: $gray-light;
}
.price-range {
    margin: 20px 0;
    @include font($font-default, #{max(12px, vw(24px))}, $weight-thin, normal, 1);
}

.product-price-list {
    margin: 10px auto;
    display: flex;
    flex-direction: row;

    li {
        &:not(:last-child) {
            margin-right: 35px;
        }

        .product-price {
            @include font($font-default, #{max(12px, vw(24px))}, $weight-thin, normal, 1);
            color: $brown;

            &.special {}

            &.old {
                font-style: italic;
                text-decoration: line-through;
            }
        }
    }
}

.product-color-title,
.product-quantity-title {
    @include font($font-default, #{max(14px, vw(20px))}, $weight-thin, normal, 1);
    color: $brown;
    padding-bottom: 10px;
    padding-top: 10px;
}

.product-colors {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;

    li {
        &:not(:last-child) {
            margin-right: 15px;
        }

        .product-color {
            @include font($font-default, #{max(14px, vw(20px))}, $weight-thin, normal, 1);
            color: $brown;
            position: relative;

            &:after {
                display: inline-block;
                content: '';
                height: 1px;
                width: 0;
                background-color: $brown;
                position: absolute;
                bottom: -2px;
                left: 0;
                @include transition;
            }

            &:hover,
            &:focus {
                text-decoration: none;

                &:after {
                    width: 100%;
                }
            }

            &.color-selected {
                &:after {
                    width: 100%;
                }
            }
        }
    }
}

#product,
#gift-card-form {
    .form-group {
        .control-label {
            display: none;
        }

        .input-radio-inline {
            display: flex;
            flex-direction: row;
            align-items: center;

            .radio {
                margin: 0;

                &:not(:last-child) {
                    margin-right: 6px
                }

                label {
                    display: block;
                    position: relative;
                    padding-left: 44px;
                    cursor: pointer;
                    @include font($font-default, 14px, $weight-light, normal, 1);
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    width: 46px;
                    height: 46px;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid $black;
                    margin-bottom: 0;

                    input[type="radio"] {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;

                        &:checked~span {
                            background-color: $black;
                            color: $white;
                        }

                        &:checked~span:after {
                            display: block;
                        }
                    }

                    span {
                        position: absolute;
                        top: 2px;
                        left: 2px;
                        height: 40px;
                        width: 40px;
                        background-color: transparent;
                        border-radius: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        //border: 1px solid $black;

                        input[type="radio"] {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;

                            &:checked~span {
                                background-color: $black;
                                color: $white;
                            }

                            &:checked~span:after {
                                display: block;
                            }
                        }

                        span {
                            position: absolute;
                            top: 2px;
                            left: 2px;
                            height: 30px;
                            width: 30px;
                            background-color: transparent;
                            border-radius: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        &:hover input~span {
                            background-color: $gray;
                        }
                    }

                    &:not(:last-child) {
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}

.quantity-input-field {
    width: 100%;
    max-width: 120px;

    @media (max-width: $mobile) {
        max-width: 95px;
    }

    .quantity {
        border-top: 1px solid $black;
        border-bottom: 1px solid $black;

        .number-input {
            position: relative;
        }

        input[type="text"] {
            background-color: transparent;
            @include font($font-default, #{max(15px, vw(22px))}, $weight-thin, normal, 1);
            padding: 0;
            color: $black;
            text-align: center;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: none;
            border-radius: 0;
            min-height: 23px;
        }

        .quantity-nav {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .quantity-button {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px;
                border-left: 1px solid $black;
                border-right: 1px solid $black;
                cursor: pointer;

                &.quantity-down {
                    &:after {
                        content: '';
                        background-image: url('../../../assets/icons/minus-icon.svg');
                        width: 13px;
                        height: 13px;
                        background-position: center;
                        background-repeat: no-repeat;

                        @media (max-width: $mobile) {
                            width: 10px;
                            height: 10px;
                        }
                    }
                }

                &.quantity-up {
                    &:after {
                        content: '';
                        background-image: url('../../../assets/icons/plus-icon.svg');
                        width: 13px;
                        height: 13px;
                        background-position: center;
                        background-repeat: no-repeat;

                        @media (max-width: $mobile) {
                            width: 10px;
                            height: 10px;
                        }
                    }
                }
            }
        }
    }
}

.add-to-cart-cta {
    margin-top: 15px;

    @media (max-width: $mobile) {
        position: fixed;
        bottom: 42px;
        left: 0;
        z-index: 10;
        margin-bottom: 0;
        width: 100%;
        margin-top: 0;
    }

    #button-cart {
        &.cta--outlined {
            width: 100%;
            max-width: 330px;
            font-size: max(24px, vw(24px));
            font-weight: 100;
            padding: 8px;

            @media (max-width: $mobile) {
                max-width: 100%;

                &:hover,
                &:focus {
                    background-color: $black;
                    color: $white;
                }
            }
        }
    }
}

.wishlist-btn {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    margin: 25px 0 10px;

    @media (max-width: $mobile) {
        margin-top: 15px;
    }

    span {
        display: inline-block;

        &.wishlist-text {
            margin-right: 10px;
            @include font($font-default, #{max(12px, vw(20px))}, $weight-thin, normal, 1);
            color: $brown;
        }

        &.wishlist-fill-icon {
            display: block;
            width: 15px;

            svg {
                display: block;
                width: 15px;
            }
        }
    }
}


.share-section {
    display: flex;
    align-items: center;

    .share-link {
        display: inline-block;
        margin: 0;

        .share-text {
            @include font($font-default, #{max(12px, vw(20px))}, $weight-thin, normal, 1);
            color: $brown;
            margin-right: 8px;
        }

        .share-icon {
            display: inline-block;
            width: 16px;
            height: 15px;

            svg {
                display: inline-block;
                width: 16px;
                height: 15px;

                .st0 {
                    fill: none;
                    stroke: #231F20;
                    stroke-width: 0.75;
                    stroke-miterlimit: 10;
                }
            }
        }
    }

    .share-choices {
        display: none;
        flex-direction: row;
        padding-left: 15px;

        &.active {
            display: flex;
        }

        .share-icons {
            display: flex;
        }
    }
}


.free-delivery-msg {
    display: block;
    width: 100%;
    max-width: 275px;
    margin: 35px 0 50px;
    @include font($font-default, #{max(16px, vw(16px))}, $weight-normal, normal, 1);
}

.product-info.panel-group {
    width: 100%;
    max-width: 460px;
    min-width: 460px;
    margin-bottom: 0;

    @media (max-width: $tablet) {
        max-width: max(350px, vw(460px));
        min-width: max(350px, vw(460px));
    }

    @media (max-width: $mobile) {
        max-width: 100%;
        min-width: 100%;
    }

    .panel.panel-default {
        border-radius: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border: none;

        &:last-child {
            border: none;
        }

        &+.panel {
            margin: 0;
        }

        .panel-heading {
            padding: 0;
            background-color: transparent;

            a {
                display: inline-flex;
                align-items: center;
                @include font($font-default, 16px, $weight-medium, normal, 1);
                color: $black;

                @media (max-width: $tablet-sm) {
                    font-size: 14px;
                }

                &:after {
                    display: inline-flex;
                    content: '';
                    background-image: url('../../../assets/icons/arrow-down.svg');
                    width: 12px;
                    height: 8px;
                    background-repeat: no-repeat;
                    margin-left: 5px;
                }

                &.collapsed {
                    &:after {
                        transform: rotate(180deg);
                        transition: transform 0.3s ease;
                    }
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }

            &+.panel-collapse>.panel-body {
                border: none;
                padding-top: 0;

                p {
                    margin-bottom: 0;
                }
            }
        }

        .panel-collapse {
            .panel-body {
                padding: 0 0 5px 0;

                & * {
                    @include font($font-default, 14px, $weight-thin, normal, 1.2);
                    color: $black;

                    @media (max-width: $tablet-sm) {
                        font-size: 14px;
                    }
                }

                & b {
                    font-weight: bold;
                }
            }
        }
    }
}

.extra--links {
    li {
        a {
            display: inline-block;
            position: relative;
            @include font($font-default, 16px, $weight-thin, normal, 1);

            @media (max-width: $tablet-sm) {
                font-size: 14px;
            }

            &:after {
                content: "";
                display: inline-block;
                width: 100%;
                height: 1px;
                background-color: var(--black);
                position: absolute;
                bottom: -3px;
                left: 0;
                @include transition;
            }

            &:hover,
            &:focus {
                color: $black;
            }
        }
    }
}


.product-info .table {
    margin-bottom: 0;
}

.product-info .table>thead>tr>td,
.product-info .table>tbody>tr>td {
    padding-left: 0;
}

.product__bottom {
    &--title {
        @include font($font-default, #{max(30px, vw(41px))}, $weight-thin, normal, 1.2);
        text-align: right;
        padding-right: vw(182px);
        margin-bottom: 50px;

        @media (max-width: $tablet-sm) {
            padding-right: 0;
            text-align: center;
            margin-bottom: 35px;
        }

        @media (max-width: $mobile) {
            margin-bottom: 15px;
            font-size: 20px;
        }

        &.related {
            padding-right: vw(125px);

            @media (max-width: $tablet-sm) {
                padding-right: 0;
            }
        }

    }

    &--section {
        padding-top: max(35px, vw(130px));
        padding-bottom: max(35px, vw(130px));
        display: flex;
        width: 100%;
        max-width: 1740px;

        @media (max-width: $tablet-sm) {
            flex-direction: column;
        }

        &--left {
            width: 100%;
            max-width: max(350px, vw(1130px));

            @media (max-width: $tablet-sm) {
                max-width: 100%;
            }

            .related-products,
            .combined-products {
                position: relative;
                margin: 0 50px;

                @media (max-width: $tablet-sm) {
                    padding: 0;
                    margin: 0 30px;
                }

                @media (max-width: $mobile-sm) {
                    margin: 0 15px;
                }

                .swiper-slide {
                    @include transition($time:1s);

                    &:hover {
                        @media (hover: hover) {
                            scale: .8;

                        }
                    }
                }
            }

            .combined-products {
                margin-bottom: max(45px, vw(55px));
            }
        }

        &--right {
            width: 100%;
            max-width: 480px;

            .product-summary {
                position: sticky;
                top: var(--header-height);
                padding-top: 80px;
                left: 0;
                margin-left: max(30px, vw(90px));

                .cta--outlined {
                    width: 100%;
                    max-width: 330px;
                    font-size: max(24px, vw(24px));
                    font-weight: 100;
                    padding: 8px;
                }
            }


            @media (max-width: $tablet-sm) {
                display: none;
            }
        }
    }
}

.swiper-button-prev-related,
.swiper-button-next-related,
.swiper-button-prev-combined,
.swiper-button-next-combined {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 50%;
    z-index: 9;

    span {
        display: inline-block;
        width: max(15px, 1.4583333333vw);
    }
}

.swiper-button-prev-related,
.swiper-button-prev-combined {
    left: -50px;

    @media (max-width: $tablet-sm) {
        left: -30px;
    }

    @media (max-width: $mobile-sm) {
        left: -20px;
    }
}

.swiper-button-next-related,
.swiper-button-next-combined {
    right: -50px;

    @media (max-width: $tablet-sm) {
        right: -30px;
    }

    @media (max-width: $mobile-sm) {
        right: -20px;
    }
}

/*add to cart message*/
@keyframes note {
    0% {
        margin-top: 25px;
        opacity: 0;
    }

    10% {
        margin-top: 0;
        opacity: 1;
    }

    90% {
        margin-top: 0;
        opacity: 1;
    }

    100% {
        margin-top: 25px;
        opacity: 0;
    }
}

.size-guide-popup {
    display: none;
    width: 85%;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin-bottom: 0;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 0;
    z-index: 9999;
    max-height: 85vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        @include prefix((box-shadow: inset 0 0 6px transparent), webkit moz);
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        @include prefix((box-shadow: inset 0 0 6px $gray-light), webkit moz);
        background-color: $gray-light;
        border-radius: 4px;
    }

    &.active {
        display: block;
    }

    .size-guide-container {
        padding: max(15px, vw(50px));
        border: none;

        .close-size-guide-btn {
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 99;
        }
    }

    //animation: note 6500ms forwards;
}

.product-details {
    .main-info {
        .right {
            display: none;
        }

        @media (max-width: $tablet-sm) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            @media (max-width:$tablet-sm) {
                max-width: 345px;
            }

            .right {
                display: block;

                .share-section {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;

                    .share-choices {
                        padding: 5px 0 0;
                    }
                }

                .wishlist-btn {
                    margin-top: 0;

                    .desktop-only {
                        display: none;
                    }
                }
            }
        }
    }

    .desktop-only {
        display: flex;

        @media (max-width: $tablet-sm) {
            display: none;
        }
    }
}


.popup-alert {
    margin: 0 !important;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 70%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 50px 20px;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 0;
    z-index: 9999;
    -webkit-box-shadow: 0 0 5px rgb(0 0 0 / 30%);
    -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 5px rgb(0 0 0 / 30%);
    animation: note 6500ms forwards;

    @media (max-width: $tablet-sm) {
        width: 85%;
    }

    .fa {
        display: none;
    }

    .alert {
        margin: 0;
        @include font($font-default, #{max(16px, vw(16px))}, $weight-normal, normal, 1);
    }

    &.alert-dismissible {
        .close {
            position: relative;
            top: -30px;
            right: 0;
            color: inherit;

            @media (max-width: $tablet-sm) {
                top: -40px;
            }

            @media (max-width: $mobile-sm) {
                top: -50px;
            }

            .close-btn-icon {
                display: inline-block;
                width: 20px;
                height: 20px;

                svg {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

}

.hidden-color-select {
    display: none;
}

.back-in-stock {
    &__cta {
        @include font($font-default, #{max(16px, vw(16px))}, $weight-normal, normal, 1);
        margin-top: 10px;

        .icon--bell {
            display: inline-block;
            width: 15px;

            svg {
                margin-bottom: -3px;
            }
        }
    }

    &__pop-up {
        @include transition(opacity);
        position: fixed;
        top: var(--header-height);
        left: 0;
        width: 100%;
        height: calc(var(--screen-height) - var(--header-height));
        background: rgba(#333333, 0.65);
        z-index: 97;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;

		@media screen and (max-width: $mobile) {
			align-items: flex-start;
		}

        &.js-has-scrolled {
            top: 0;
            height: var(--screen-height);
            z-index: 100;

			@media screen and (max-width: $mobile) {
				top: 50px;
			}
        }

        &.js-pop {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }

        .icon-pop-up--close {
            position: absolute;
            top: 25px;
            right: 30px;
            display: inline-block;
            cursor: pointer;
            width: max(22px, vw(30px));
			z-index: 1;

			@media screen and (max-width: $mobile) {
				top: 40px;
				right: 22px;
			}

            svg {
                fill: none;
                stroke: black;
            }
        }

        &--wrap {
            background-color: $white;
            padding: max(28px, vw(50px));
            width: 100%;
            max-width: max(375px, vw(1025px));

			@media screen and (max-width: $mobile) {
				padding-top: 115px;
				max-width: 100%;
				height: calc(var(--screen-height) - 50px);
			}

			@media screen and (max-width: $mobile) and (max-height: $mobile) {
				overflow-y: auto;
			}
        }

        &--underlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &--head,
        &--body {
            max-width: 860px;
        }

        &--head {
            display: flex;
            flex-direction: column;

            span {

                &:first-child {
                    @include font($font-default, #{max(31px, vw(50px))}, $weight-thin, normal, 1.2);
					margin-bottom: max(15px, vw(35px));
                }

                &:last-child {
                    @include font($font-default, #{max(12.5px, vw(23px))}, $weight-thin, normal, 1.2);
					margin-bottom: max(30px, vw(35px));
                }
            }
        }

        &--body {
            form {
                &>div {

                    &:first-child,
                    &:nth-child(2) {
                        margin-bottom: 30px;
                    }

					@media screen and (max-width: $mobile) {
						&:nth-child(2) {
							margin-bottom: 50px;
						}
					}
                }

                .form {
                    &--consent {
                        label {
                            @include font($font-default, #{max(12px, vw(16px))}, $weight-light, normal, 1.2);
                            display: inline-flex;
                            text-transform: unset;

							.checkbox-input {
								min-width: 17px;
							}
                        }
                    }
                }
            }

            .icon--submit-arrow {
                display: inline-block;
                width: max(26px, vw(40px));

                svg {
                    fill: none;
                    stroke: black;
                }
            }
        }

    }
}

.back_in_stock--response {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;

    &.response {

        &--success {
            color: $green-dark;
        }

        &--danger {
            color: $red;
        }

        &--warning {
            color: #8a6d3b;
        }
    }
}

.hidden-color-select{
  display: none;
}

.wishlist-fill-icon svg g path:first-child{
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  fill: transparent;
}

.wishlist-fill-icon:hover svg g path:first-child, .wishlist-fill-icon.is-favorite svg g path:first-child {
  fill: unset;
}

.prd-size-price {
    display: none!important;
}
.stock-alert {
    color: #ff0000;
    text-decoration: underline;
    font-size: 13px;
    margin-bottom: 0;
}
.product-info.panel-group .panel.panel-default .panel-collapse .panel-body ul{
    padding-inline-start: 48px !important;
}
.product-info.panel-group .panel.panel-default .panel-collapse .panel-body li{
    list-style-type: disc !important;
}
.info {
    &-icon {
        position: absolute;
        inset: 0;
        left: auto;
        display: flex;
        align-items: center;
        margin-right: 8px;
        @media screen and (max-width: $mobile){
            margin-right: 17px;
        }

        @media (hover:hover) {
            display: none;
        }
    }

    &-content {
        @include transition($time: 0.8s);
        position: absolute;
        left: -1px;
        width: calc(100% + 2px);
        z-index: 1;
        font-size: max(14px, vw(16px));
        line-height: 1;
        padding: 15px;
        background-color: #DBDBDB;
        color: $black;
        text-align: left;
        pointer-events: none;
        opacity: 0;

        &.add {
            bottom: calc(100% + 1px);
            margin-bottom: 10px;
        }

        &.book {
            top: calc(100% + 1px);
            margin-top: 10px;
            @media screen and (max-width: $mobile){
                top: unset;
                margin-top: unset;
                bottom: calc(200% + 1px);
                margin-bottom: 10px;
            }
        }
    }
}
@media (hover:hover) {
    .has-info {
        &:hover {
            .info-content {
                opacity: 1;
            }
        }
    }
}
@media (hover:none) {
    .has-info {
        .info-icon:hover ~ span {
            opacity: 1;
        }
    }
}

.or-text{
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 330px;
    font-weight: 100;
    font-size: max(24px, vw(24px));
    margin: 2px 0 4px;
    @media screen and (max-width: $mobile){
        display: none;
    }
}
.tooltip-parent{
    width: 100%;
    max-width: 330px;
    &:not(#button-cart){
        @media screen and (max-width: $mobile){
            position: fixed;
            bottom:0;
            left: 0;
            z-index: 8;
            width: 100%;
            max-width: 100%;
            background-color: $white;
        }
    }
    &>div.book-modal-trigger{
        width: 100%;
        border: 1px solid $black;
        text-align: center;
        padding: 2px;
        font-weight: 100;
        font-size: max(24px, vw(24px));
        @include transition($time: 0.8s);
        &:hover{
            background-color: $black;
            color: $white;
        }
        &:before{
            display: none;
        }
        @media screen and (max-width: $mobile){
            margin-bottom: 0;
        }
    }
}