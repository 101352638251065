%in-page-width {
	width: 100%;
	max-width: 760px;
	margin-left: auto;
	margin-right: auto;
}

%in-modal-width {
	width: 100%;
	max-width: 569px;
	margin: auto;
}

.book-block {
	.checkbox-label {
		align-items: center;
	}

	.form-element-book {
		margin-bottom: 30px;

		@media screen and (max-width: $tablet-sm) {
			margin-bottom: 20px;
		}

		.select2 {
			width: 100% !important;

			.select2-selection__arrow {
				height: 100%;
			}
		}

		.text-danger {
			padding-left: 10px;
			padding-top: 5px;
		}
	}

	form {
		input[type="text"] {
			@include inputStracture(1px, solid, 100px, max(10px, vw(20px)) 20px);
			@include inputStyle(transparent, $gray-dark, $gray-dark, $gray-dark, $error-color: $error-color);
		}

		.book-submit {
			display: flex;
			justify-content: center;

			button {
				width: 100%;
				max-width: 300px;
				border-radius: 100px;
				font-size: 17px;
				padding-top: 24px;
				padding-bottom: 24px;
				margin-top: 20px;

				@media screen and (max-width: $tablet-sm) {
					margin-top: 10px;
					padding-top: 12px;
					padding-bottom: 12px;
					font-size: 13px;
				}

				@media screen and (max-width: $mobile-sm) {
					max-width: unset;
					width: 100%;
				}
			}
		}
	}

	&.in-page {
		margin-top: max(50px, vw(65px));
		margin-bottom: max(165px, vw(180px));

		@media screen and (max-width: $tablet) {
			padding-left: 40px;
			padding-right: 40px;
		}

		.book-block__introduction,
		form {
			@extend %in-page-width;
		}

		.book-block__introduction {
			.title {
				font-weight: 400;
				font-size: 17px;
				margin-bottom: max(0px, vw(28px));
				text-transform: uppercase;

				@media screen and (max-width: $tablet-sm) {
					font-size: 13px;
					margin-bottom: 0;
				}
			}

			.description {
				font-size: 13px;
				font-weight: 400;
				margin-bottom: 40px;

				@media screen and (max-width: $tablet-sm) {
					font-size: 12px;
					width: 100%;
				}
			}

			.subtitle {
				font-size: 17px;
				font-weight: 400;
				margin-bottom: 40px;

				@media screen and (max-width: $tablet-sm) {
					display: none;
				}
			}
		}
	}

	&.in-modal {
		.book-block__introduction,
		form {
			@extend %in-modal-width;
		}

		form {
			.book-submit {
				button {
					max-width: unset;
				}
			}
		}

		.book-block__introduction {
			.title {
				@include font($font-default, #{max(16px, vw(16px))}, $weight-light, normal, #{max(19px, vw(19px))});
				text-transform: uppercase;
				margin-bottom: 30px;
			}

			.description {
				@include font($font-default, #{max(12px, vw(12px))}, $weight-thin, normal, #{max(15px, vw(15px))});
				margin-bottom: 6px;
				text-align: initial;
			}

			.subtitle {
				@include font($font-default, #{max(12px, vw(12px))}, $weight-thin, normal, #{max(15px, vw(15px))});
				margin-bottom: 28px;
			}
		}
	}
}