.size-guide {

	&-modal {
		display: none;
		position: fixed;
		inset: 0;
		overflow-y: auto;
		width: calc(100% - vw(285px));
		max-height: 70vh;
		margin: auto;
		margin-top: 200px;
		background-color: #fff;
		color: #000;
		z-index: 9999;

		@media screen and (max-width: 1200px) {
			margin-top: 140px;
		}

		@media screen and (max-width: $tablet-sm) {
			width: calc(100% - 20px);
			max-height: unset;
			margin-top: 120px;
			margin-bottom: 10px;
		}

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-track {
			@include prefix((box-shadow: inset 0 0 6px transparent), webkit moz);
			border-radius: 4px;
		}

		&::-webkit-scrollbar-thumb {
			@include prefix((box-shadow: inset 0 0 6px $gray-light), webkit moz);
			background-color: $gray-light;
			border-radius: 4px;
		}

		&.active {
			display: block;
		}
	}

	&-close {
		position: absolute;
		top: 15px;
		right: 15px;
		z-index: 99;

		@media screen and (max-width: $laptop-sm) {
			width: 20px;
			height: 20px;

			svg {
				width: 20px;
				height: 20px;
			}
		}
	}
}

.size-guide-tabs {
	.nav {
		margin: unset;
		list-style: none;
		padding: max(15px, vw(55px)) max(15px, vw(90px)) 0;

		&-tabs {
			border: unset;

			li {
				&:not(:last-child) {
					margin-right: max(15px, vw(30px));
				}

				&.active {
					text-decoration: underline;
					text-underline-offset: 7px;
				}

				a {
					padding: unset !important;
					border: unset !important;
					color: unset !important;
					background-color: unset !important;
				}
			}
		}
	}

	.tab-content {
		padding: max(46px, vw(55px)) max(15px, vw(90px)) max(30px, vw(42px));
		min-height: 578px;
	}
}

.size-guide,
.measuring-guide {
	display: flex;

	@media screen and (max-width: $laptop-sm) {
		flex-direction: column;
	}
}

.size-guide {
	&-block {
		margin-bottom: max(15px, vw(85px));

		&:last-child {
			@media screen and (max-width: $laptop-sm) {
				margin-bottom: unset;
			}
		}

		&:not(:last-child) {
			margin-right: 30px;

			@media screen and (max-width: $laptop-sm) {
				display: flex;
				flex-direction: column;
				margin-right: unset;
			}
		}

		.unit-btn {
			cursor: pointer;
			text-decoration: underline;
			text-underline-offset: 3px;
		}

		&__title {
			@include font($font-default, #{max(14px, vw(14px))}, $weight-normal, normal, 1);
			margin-bottom: max(32px, vw(42px));

			&.space-left {
				margin-left: vw(90px);

				@media screen and (max-width: $laptop-sm) {
					margin-left: unset;
				}
			}
		}

		&__wrap {
			.title {
				@include font($font-default, #{max(14px, vw(14px))}, $weight-normal, normal, 1);
				margin-bottom: 15px;
				@media screen and (max-width: $laptop-sm) {
					margin-bottom: 38px;
				}
			}

			table {
				border: 1px solid #000000;

				@media screen and (max-width: $laptop-sm) {
					width: 100%;
				}

				th {
					@include font($font-default, #{max(14px, vw(14px))}, $weight-thin, normal, 1);
					border: 1px solid #000000;
					min-width: max(98px, vw(110px));
					padding: 7px 5px;
				}

				td {
					@include font($font-default, #{max(14px, vw(14px))}, $weight-thin, normal, 1);
					padding: 7px 5px;
					width: 85px;
					border-right: 1px solid #000000;
				}
			}

			.content {
				display: flex;

				& > .image {
					max-width: max(180px, vw(212px));
				}

				& > .details {
					@include font($font-default, #{max(14px, vw(14px))}, $weight-thin, normal, 1);
					padding-left: vw(45px);

					@media screen and (max-width: $laptop-xs) {
						padding-left: unset;
					}

					& > * {
						&:not(:last-child) {
							margin-bottom: 13px;
						}
					}
				}
			}

			&.right {
				display: flex;
				padding-top: 29px;

				@media screen and (max-width: $laptop-sm) {
					justify-content: center;

					.content {
						flex-direction: column;
						align-items: center;

						.details {
							margin-top: 40px;
						}
					}
				}
			}
		}
	}
}

.measuring-guide {
	&-block {
		flex: 1;

		&:first-child {
			margin-right: max(30px, vw(60px));

			@media screen and (max-width: $laptop-sm) {
				margin-top: 15px;
				margin-right: unset;
				order: 2;
			}
		}

		.details {
			@include font($font-default, #{max(14px, vw(14px))}, $weight-thin, normal, 1.5);

			& > * {
				&:not(:last-child) {
					margin-bottom: 43px;
				}
			}
		}

		img,
		video {
			width: vw(728px);
			height: vw(410px);
			object-fit: cover;

			@media screen and (max-width: $laptop-sm) {
				width: 100%;
				height: auto;
			}
		}
	}
}

.js-play-measuring-bridal-video {
	position: absolute;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		@include transition;
		width: auto;
		height: auto;
		opacity: 1;
	}

	&.video-playing {
		img {
			opacity: 0;
		}
	}
}