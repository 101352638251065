.prev-collection-container {
  padding: max(50px, vw(75px)) max(15px, vw(105px)) max(35px, vw(120px));
  background-color: #e7d6c8;
  @media screen and (max-width: $tablet){
    padding-left: 30px;
    padding-right: 30px;
  }
  @media screen and (max-width: $tablet-sm){
    padding-left: 15px;
    padding-right: 15px;
  }
  .prev-collection-video-container {
    margin-bottom: max(15px, vw(100px));
    @media screen and (max-width: $mobile) {
      margin-bottom: 15px;
    }
    video {
      width: 100%;
    }
  }
  .prev-collection-last-items-title{
    font-size: max(22px, vw(63px));
    text-align: center;
    font-weight: 100;
    margin-bottom: max(15px, vw(100px));
    @media screen and (max-width: $tablet-sm) {
      text-align: left;
    }
    @media screen and (max-width: $mobile) {
      margin-bottom: 15px;
    }
  }
  .prev-collection-slider-wrap{
    &:not(.margin-none){
      margin-bottom: 4.844vw;
    }
    .prev-collection-slider{
      width: 95%;
      @media screen and (max-width: $tablet-sm) {
        width: 100%;
      }
      .prev-collection-d__item{
        transition: all 1s;
        &:hover{
          cursor: pointer;
          scale: 0.8;
        }
        .card_view{
          &--footer-top{
            a{
              &:hover{
                color: unset;
              }
            }
          }
        }
      }
    }
    .slider-handle-prev-collection{
      top: 0;
      //@media screen and (max-width: $tablet-sm){
      //  width: 115%;
      //  left: -7.5%;
      //}
    }
  }

}