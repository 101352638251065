// * SCREEN WIDTH CONSTANTS
$desktop: 1920px;
$laptop: 1440px;
$laptop-m: 1366px;
$laptop-sm: 1280px;
$laptop-xs: 1140px;
$tablet-lg: 1024px;
$tablet: 991px;
$tablet-sm: 767px;
$mobile: 580px;
$mobile-sm: 420px;
$mobile-xs: 375px;

:root {
    // * PALETTE
    // ? neutral
    --black: #000000;
    --white: #FFFFFF;

    // ? gray
    --gray: #e6e6e6;
    --gray-dark: #4D4D4D;
    --gray-light: #b3b3b3;
    --gray-medium: #666666;
    //--gray-footer: #D8D8D8;
    //--gray-indicator: #A1A1A1;

    --beige: #e7d6c8;
    --beige-dark: #7c4848;


    // ? beige
    --beige: #e7d6c8;
    --beige-dark: #d1b8a6;

    // ? brown
    --brown: #231f20;

    // ? green
    --green: #A1C5C5;
    --green-dark: #224344;
    --green-darker: #1C3A3A;
    --green-darkest: #0b2525;
    --green-light: #EDF5F5;

    // ? red - errors default
    --red: #ff0000;

    // * FONT
    --font-default: 'Helvetica';

    // * LAYOUT
    // --side-padding: clamp(60px, 8.3333vw, 160px);
    --bloat: 20%;
    
    // * RESPONSIVE VARIABLES
    @media (max-width: $laptop) {}

    @media (max-width: $tablet-lg) {}
}

// * PALETTE ASSIGNMENT
// ? neutral
$black: var(--black);
$white: var(--white);


// ? beige
$beige: var(--beige);
$beige-dark: var(--beige-dark);

// ? gray
$gray: var(--gray);
$gray-dark: var(--gray-dark);
$gray-light: var(--gray-light);
$gray-medium: var(--gray-medium);
$gray-footer: var(--gray-footer);
$gray-indicator: var(--gray-indicator);

// ? beige
$beige: var(--beige);
$beige-dark: var(--beige-dark);

// ? brown
$brown: var(--brown);

// ? green
$green: var(--green);
$green-dark: var(--green-dark);
$green-darker: var(--green-darker);
$green-darkest: var(--green-darkest);
$green-light: var(--green-light);

// ? red - errors default
$red: var(--red);

// * FONT ASSIGNMENT
$font-default: var(--font-default)+', sans-serif';

// * LAYOUT ASSIGNMENT
// $side-padding: var(--side-padding);

// ! $_variable to $_variable
// ! from this point down
// * THEME RE-ASSIGNMENTS

// * BODY DEFAULT COLORS
$body-background-color: $gray-medium;
$body-text-color: $black;

// * PROJECT DEFAULT ERROR COLOR
$error-color: $red;

// * PROJECT TEXT COLOR VARIATIONS
$text-color: $black;
$text-emphasis-color: $beige;
$text-inactive-color: $gray-dark;

// * PROJECT INPUT FIELDS
$input-border-color-inactive: $gray;
$input-text-color-inactive: $gray-dark;

// * CTA
$cta-text-color: $white;
$cta-background-color: $brown;
$cta-text-color-hover: $white;
$cta-background-color-hover: transparent;

// * INTERACTION
$interaction-text-color: $white;
$interaction-background-color: $green-dark;






// ! TO CHANGE

$primary_color: null;
$primary_font: null;
$header_1_fs: null;
$header_2_fs: null;
$header_3_fs: null;
$header_4_fs: null;
$hero_title_fs: null;
$banner_title_fs: null;
$text_fs: null;
$text_small_fs: null;
$text_large_fs: null;
$input_fs: null;
$border_color: null;
$border_radius_input: null;
$placeholder_color: null;
$border_color_focus: null;
$modal_title: null;
$side_padding: null;
$btn_fs: null;
$btn_font_color: null;
$btn_background_color: null;
$border_radius_button: null;
$secondary_color: null;
$light-gray: null;
$footer_background_color: null;
$side_padding_footer: null;
$side_padding_header: null;
$side_padding_checkout: null;
$mobile-xs: null;
$promo_bar_background_color: null;




// :root {
//     // palette
//     --black: #1b1b1b;
//     --white: #ffffff;
//     --gray: #747474;
//     --light-gray: #eaeaea;

//     // colors
//     --primary-color: #000000;
//     --secondary-color: #86867f;
//     --input-border-color: #dddddd;
//     --input-border-color-focus: #747474;
//     --input-placeholder-color: #747474;
//     --footer-background-color: #eaeaea;
//     --promo-bar-background-color: #eaeaea;

//     // fonts
//     --primary-font: 'Roboto';
//     --secondary-font: 'Roboto';

//     --header-1: 2.4rem;
//     --header-2: 1.8rem;
//     --header-3: 1.6rem;
//     --header-4: 1.4rem;
//     --banner-title: 3rem;
//     --hero-title: clamp(3rem, 2.6042vw, 5rem);
//     --text: 1.4rem;
//     --text-small: 1.2rem;
//     --text-large: 1.6rem;
//     --btn-font: 1.4rem;
//     --input-font: 1.6rem;
//     --modal-title: clamp(2.4rem, 1.8229vw, 3.5rem);

//     // layout
//     --input-border-radius: 0px;
//     --button-border-radius: 0px;
//     --side-padding: 30px;
//     --side-padding-header: 30px;
//     --side-padding-footer: 30px;
//     --side-padding-checkout: clamp(30px, 4.1667vw, 80px);
// }

// /*========================================================*/
// /* Colors */
// /*========================================================*/

// $primary_color: var(--primary-color);
// $secondary_color: var(--secondary-color);

// $placeholder_color: var(--input-placeholder-color);
// $border_color: var(--input-border-color);
// $border_color_focus: var(--input-border-color-focus);

// $btn_font_color: var(--white);
// $btn_background_color: var(--black);

// $footer_background_color: var(--footer-background-color);
// $promo_bar_background_color: var(--promo-bar-background-color);

// $black: var(--black);
// $white: var(--white);
// $gray: var(--gray);
// $light_gray: var(--light-gray);

// /*========================================================*/
// /* Fonts */
// /*========================================================*/

// $primary_font: var(--primary-font), sans-serif;
// $secondary_font: var(--secondary-font), sans-serif;

// $header_1_fs: var(--header-1);
// $header_2_fs: var(--header-2);
// $header_3_fs: var(--header-3);
// $header_4_fs: var(--header-4);
// $banner_title_fs: var(--banner-title);
// $hero_title_fs: var(--hero-title);
// $text_fs: var(--text);
// $text_small_fs: var(--text-small);
// $text_large_fs: var(--text-large);
// $btn_fs: var(--btn-font);
// $input_fs: var(--input-font);
// $modal_title: var(--modal-title);

// /*========================================================*/
// /* Layout */
// /*========================================================*/

// $border_radius_input: var(--input-border-radius);
// $border_radius_button: var(--button-border-radius);
// $side_padding: var(--side-padding);
// $side_padding_header: var(--side-padding-header);
// $side_padding_footer: var(--side-padding-footer);
// $side_padding_checkout: var(--side-padding-checkout);

// /*========================================================*/
// /* Breakpoints */
// /*========================================================*/

// $laptop-lg: 1600px;
// $laptop: 1440px;
// $laptop-m: 1366px;
// $laptop-sm: 1280px;
// $laptop-xs: 1140px;
// $tablet-lg: 1024px;
// $tablet: 991px;
// $tablet-sm: 767px;
// $mobile: 660px;
// $mobile-sm: 421px;
// $mobile-xs: 375px;

// /*========================================================*/
// /* Responsive Variables */
// /*========================================================*/
// :root {
//     @media (max-width: $tablet-lg) {
//         --side-padding-footer: 15px;
//     }
//     @media (max-width: $mobile) {
//         --input-font: 1.4rem;
//     }
// }
