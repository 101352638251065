body {
    padding-top: var(--header-height);
}

// * CALL TO ACTIONS
.cta {
    @include buttonsStracture(#{max(12px, vw(12px))}, #{max(12px, vw(12px))}, unset, 1px, solid);
    @include button($cta-background-color, $cta-text-color, $cta-background-color-hover, $cta-text-color-hover);

    &--full {
        @include buttonsStracture(#{max(9px, vw(9px))}, #{max(12px, vw(12px))}, unset, 1px, solid);
        @include button($cta-background-color, $cta-text-color, $cta-background-color-hover, $cta-text-color-hover);
        width: 100%;
        @include transition($time:0.8s);
    }

    &--shop-the-look {
        @include buttonsStracture(#{max(8.5px, vw(8.5px))}, #{max(61.5px, vw(61.5px))}, unset, 1px, solid);
        @include button($cta-background-color, $cta-text-color, $cta-background-color-hover, $cta-text-color-hover);
        @include transition($time:0.8s);
    }

    &--outlined {
        @include buttonsStracture(#{max(8.5px, vw(8.5px))}, #{max(61.5px, vw(61.5px))}, unset, 1px, solid);
        @include button($cta-background-color, $cta-text-color, transparent, #000);
        @include transition($time:0.8s);
    }

    &--outlined--white {
        @include buttonsStracture(#{max(8.5px, vw(8.5px))}, #{max(61.5px, vw(61.5px))}, unset, 1px, solid);
        @include button($white, $black, $black, $black);
        @include transition($time:0.8s);
        border-radius: 100px;
        color:$black!important;
        &:hover {
            border-color: $white;
            color: $white!important;
            background-color: transparent;
        }
    }

    &--account {
        @include buttonsStracture(#{max(20px, vw(20px))}, #{max(20px, vw(20px))}, 100px, 1px, solid);
        @include button(#333333, $cta-text-color !important, transparent, #333333 !important);
        display: flex;
        width: 66.666%;
        max-width: 400px;
        margin: auto;
        text-decoration: none !important;

        &-small {
            @include buttonsStracture(#{max(12px, vw(12px))}, #{max(12px, vw(12px))}, 100px, 1px, solid);
            @include button($white, $black, transparent, $white);
            width: 100%;
        }
    }

}

// ! _modals.scss
.modal-open {
    overflow: hidden;
}

.modal-title {
    @include font($font-default, #{max(16px, vw(18px))}, $weight-medium, normal, 1);
}

.modal-body {
    padding: clamp(15px, 2.0833vw, 40px);

    h2,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $primary_font;
        font-size: $header_1_fs;
        font-weight: 500;
    }
}

.modal-header {
    position: relative;

    .close {
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: 0;
        transform: translateY(-50%);
        z-index: 1;
    }
}

.modal-content {
    border-radius: 0;
    border: none;
    -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 50%);
    box-shadow: 0 3px 6px rgb(0 0 0 / 50%);
}

// ! _modals.scss END

.overlay_card {
    @include transition;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#333333, 0.65);
    opacity: 0;
    pointer-events: none;

    &-description {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: vw(270px);
        width: vw(446px);
        margin: auto;
        color: $white;
        width: 100%;
        max-width: 90%;
        .out-of-stock {
            @include font($font-default, #{max(14px, vw(22px))}, $weight-light, normal, 1);
            color: $white;
            text-transform: uppercase;
            text-align: center;
        }
        .pre-order {
            @include font($font-default, #{max(14px, vw(16px))}, $weight-light, normal, 1);
            text-align: center;
        }
        @media screen and (max-width:$laptop) {
            max-width: 82%;
        }
    }

    &-options {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: vw(20px);
        padding-left: vw(35px);
        padding-right: vw(35px);

        &>* {
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: $white;
            flex: 1;
        }
    }

    &-center {
        text-align: center;
        flex: 2;
        margin-bottom: 10px;
    }
}

.card_view {

    &--active {
        opacity: 0;
        pointer-events: none;
    }

    &--image {
        &:hover {
            .overlay_card {
                opacity: 1;
            }
        }
    }

    &:hover {
        .card_view--image {
            &.is-hidden {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &--image {
        display: block;
        position: relative;
        a {
            display: flex;
        }


        &.is-hidden {
            @include transition($time: 1s);
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
        }

        img {}

        .cta-wrap {
            @include transition($time: 0);
            position: absolute;
            bottom: 8px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: center;
            white-space: nowrap;
            opacity: 0;
            pointer-events: none;

            .cta {
                padding-left: vw(60px);
                padding-right: vw(60px);
            }
        }
    }

    &--footer {
        @include transition($time: 1s);
        padding-top: 15px;
        padding-bottom: 5px;
        height: auto;
        max-height: 70px;
        padding-right: 3px;

        &--space {
            padding-left: 5px;
            padding-right: 5px;
        }

        &>* {
            display: flex;
            justify-content: space-between;
        }

        &-top {
            margin-bottom: 5px;
        }

        &-bottom {
            min-height: 24px;
            align-items: flex-end;

            .card_view--footer-cta{
                .underline--black{
                    &:after{
                        content: "";
                        width: 0;
                        height: 2px;
                        position: absolute;
                        bottom: -3px;
                        left: 0;
                        background-color: #d1b8a6;
                        transition: width 0.8s;
                    }
                    &:hover:after{
                        width: 101%;
                    }
                }
            }
        }

        &-title {
            &:hover {
                color: inherit;
                text-decoration: none;
            }
        }

        &-favorites {
            display: flex;
            cursor: pointer;

            svg,
            img {
                width: 14px;
                height: 12px;
            }

            svg {
                g {
                    path {
                        &:first-child {
                            @include transition;
                            fill: transparent;
                        }
                    }
                }
            }

            &:hover,
            &.is-favorite {
                svg {
                    g {
                        path {
                            &:first-child {
                                fill: unset;
                            }
                        }
                    }
                }
            }


        }

        &-price {
            span {
                display: block;

                &.old-price {
                    text-decoration: line-through;
                    font-style: italic;
                }
            }
        }

        &-cta {}

    }

    &--favorites {
        display: flex;
        cursor: pointer;

        svg,
        img {
            width: 14px;
            height: 12px;
        }

        svg {
            g {
                path {
                    &:first-child {
                        @include transition;
                        fill: transparent;
                    }
                }
            }
        }

        &:hover,
        &.is-favorite {
            svg {
                g {
                    path {
                        &:first-child {
                            fill: unset;
                        }
                    }
                }
            }
        }
    }

    &--item-trigger {
        position: absolute;
        top: var(--trigger-top);
        left: var(--trigger-left);
        cursor: pointer;
        opacity: 0;
        pointer-events: none;
    }

    &.onPoint {
        .card_view--active {
            opacity: 1;
            pointer-events: all;
        }

        .card_view--footer {
            opacity: 0;
            pointer-events: none;
            max-height: 0;
            padding: 0;
        }

        .card_view--image {
            .cta-wrap {
                @include transition(opacity, 1s);
                opacity: 1;
                pointer-events: all;
            }
        }

        .card_view--item-trigger {
            @include transition(opacity, 1s);
            opacity: 1;
            pointer-events: all;
        }
    }
}

.swiper-slide {
    &.onPoint {
        .card_view--active-cta-view {
            opacity: 1 !important;
            pointer-events: all !important;
        }
    }
}

.underline {

    &--white,
    &--gray,
    &--black,
    &--black--rs-swap,
    &--team {
        @include transition;
        cursor: pointer;
        display: inline-block;
        margin-bottom: 3px;

        a {
            color: inherit;
            text-decoration: none;
        }

        &:before {
            @include transition;
            content: "";
            display: flex;
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%;
            height: 1px;
        }

        &.small-cta {
            font-size: 12px;
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &--white {
        &:before {
            background-color: $white;
        }

        &.hover-anim {

            &:hover,
            &:focus {
                color: $black;

                &:before {
                    background-color: $black;
                }
            }
        }
    }

    &--black {
        &:before {
            background-color: $black;
        }

        &.hover-anim {

            &:hover,
            &:focus {
                color: $black;

                &:before {
                    background-color: $black;
                }
            }
        }
    }
    
    &--black--rs-swap {
        &:before {
            background-color: $black;

            @media screen and (max-width: $tablet) {
                background-color: $white;                
            }
        }

        &.hover-anim {

            &:hover,
            &:focus {
                color: $black;
                
                @media screen and (max-width: $tablet) {
                    color: $white;                
                }

                &:before {
                    background-color: $black;
                    
                    @media screen and (max-width: $tablet) {
                        background-color: $white;
                    }
                }
            }
        }
    }

    &--gray {
        &:before {
            background-color: $gray-light;
        }

        &.hover-anim {

            &:hover,
            &:focus {
                color: $black;

                &:before {
                    background-color: $black;
                }
            }
        }
    }

    &--team {
        &:before {
            background-color: $white;
        }

        &.hover-anim {

            &:hover,
            &:focus {
                color: $white;

                &:before {
                    background-color: $white;
                }
            }
        }
    }

    &--space {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.swiper-button-disabled {
    opacity: 0;
}

.slider-handle {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    justify-content: space-between;
    margin: auto;
    pointer-events: none;
    max-height: vw(584px);

    &>div {
        &>span {
            display: block;
            width: max(15px, vw(28px));
        }
    }

    &.recommended {
        top: vw(100px);
        width: vw(580px);

        @media screen and (max-width: $tablet) {
            width: vw-rs($tablet, 686px)
        }

        @media screen and (max-width: $tablet-sm) {
            width: vw-rs(375px, 307px)
        }
    }

    &.sub-recommended,
    &.best-sellers,
    &.special-offers {
        top: 0;
        width: 100%;

        @media screen and (max-width: $tablet-sm) {
            top: -35px;
            width: 65%;
            filter: invert(1);
        }
    }

    &.prev-collection {
        top: 0;

        &--cover-flow {
            @media screen and (max-width: $tablet-sm) {
                width: 65%;
                filter: invert(1);
            }
        }
    }

    &.single-post {
        top: 0;
        width: 100%;

        &--cover-flow {
            @media screen and (max-width: $tablet-sm) {
                width: 65%;
                filter: invert(1);
            }
        }
    }

    &.shop-the-look {
        top: vw(100px);
        width: vw(640px);

        @media screen and (max-width: $tablet) {
            width: vw-rs($tablet, 686px)
        }

        @media screen and (max-width: $tablet-sm) {
            width: vw-rs(375px, 307px)
        }
    }

    &.social {
        top: 0;
        width: 100%;

        .homepage__social__item-prev {
            right: vw(65px);
        }

        .homepage__social__item-next {
            left: vw(65px);
        }
    }

    &>* {
        cursor: pointer;
        pointer-events: all;
        padding: 15px 20px;

        @media screen and (min-width: ($tablet + 1)) {
            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    &.store-handles {
        top: 0;
        width: 100%;

        width: 106%;
        left: -3%;

        @media screen and (max-width: $laptop) {
            width: 107%;
            left: -3.5%;
        }

        @media screen and (max-width: $tablet-lg) {
            width: 110%;
            left: -5%;
        }

        @media screen and (max-width: $tablet-sm) {
            width: 110%;
            left: -5%;

            &>* {
                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        @media screen and (max-width: $mobile) {
            width: 120%;
            left: -10%;
        }
    }
}

.slider-type {
    &--inline {

        @media screen and (max-width: $tablet-sm) {
            padding-left: 0 !important;
            padding-right: 0 !important;

            .swiper {
                margin-left: 0;
                margin-right: 0;
            }

            .swiper-slide {
                @include transition($time:1s);

                .card_view--footer {
                    @include transition($time:1s);
                    opacity: 0;
                    pointer-events: none;
                }

                &-active {
                    .card_view--footer {
                        opacity: 1;
                        pointer-events: all;
                    }
                }
            }
        }
    }
}

.discover {
    display: flex;
    justify-content: flex-end;
    margin-top: max(85px, vw(100px));
    margin-bottom: max(80px, vw(100px));

    &--mb-large {
        margin-bottom: max(100px, vw(260px));
    }

    @media screen and (max-width: $tablet-sm) {
        justify-content: center;
    }

    a {
        &:hover {
            color: inherit;
            text-decoration: none;
        }
    }
}

label {
    &:not(.non-text) {
        @include font($font-default, #{max(15px, vw(15px))}, $weight-normal, normal, 1);
        color: $black;
        text-transform: uppercase;
        margin-bottom: 10px;

        &.checkbox-label {
            text-transform: unset;
        }
    }

    &.non-text {
        display: unset;
        max-width: unset;
        margin-bottom: unset;
        font-weight: unset;

        input[type="radio"],
        input[type="checkbox"] {
            border: unset;
            margin: 0;
            position: absolute;
            height: 100% !important;
            line-height: unset;
            cursor: pointer;
        }
    }
}

input {
    @include inputStracture(1px, solid, 100px, max(10px, vw(20px)) 20px);
    @include inputStyle(transparent, $gray-dark, $gray-dark, $gray-dark, $error-color: $error-color);
    @include font($font-default, #{max(15px, vw(15px))}, $weight-thin, normal, 1);
    color: $black;

    &.input--white {
        @include inputStyle(transparent, $white, $white, $white, $error-color: $error-color);
        color: $white;
    }

    // height: auto !important;
    &::-webkit-input-placeholder {
        font-style: italic;
    }

    &.checkbox-input {
        display: inline-flex;
    }

    &.checkbox-input,
    &.checkbox-wrap {
        cursor: pointer;
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        margin: 0 10px -3px 0;
        width: 17px;
        height: 17px !important;
        border: 1px solid $gray-dark;
        position: relative;
        padding: 4px;
        border-radius: 4px;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 8px;
            height: 8px;
            transform: scale(0) translate(-50%, -50%);
            background-color: $white;
            transform-origin: bottom left;
            -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        }

        &:checked {
            background-color: $gray-dark;
            border-color: $gray-dark;

            &:before {
                transform: scale(1) translate(-50%, -50%);
            }
        }

        &.error-checkbox {
            border-color: var(--red);
            &:checked {
                border-color: var(--gray-dark);
            }
        }

        &:disabled {
            border-color: $gray-dark;
            cursor: not-allowed;
        }
    }
}

/* RADIO STRACTURE */
$radio-size: 18px;
$radio-inner-size: 12px;
$radio-border-color: $black;
$radio-border-width: 1px;
$radio-border-radius: 50%;
$radio-background: transparent;
$radio-check-color: $gray_dark;

/* RADIO */
.radio-input {
    &:not(#guest):not(#register) {
        position: relative !important;
        display: inline-grid;
        place-content: center;
        cursor: pointer;
        width: $radio-size;
        height: $radio-size;
        border: $radio-border-width solid $radio-border-color;
        -webkit-appearance: none;
        appearance: none;
        background-color: $radio-background;
        margin: 0;
        margin-right: 9px;
        border-radius: $radio-border-radius;

        &:before {
            content: "";
            width: $radio-inner-size;
            height: $radio-inner-size;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $radio-check-color;
        }

        &:checked {
            &:before {
                transform: scale(1);
            }
        }
    }
}

.checkbox-label {
    display: inline-flex;
    align-content: flex-start;
    align-items: flex-start;

    span {
        flex: 1;
        font-size: 1rem;

        a {
            font-weight: bold;
            text-decoration: underline;

            &:hover,
            &:focus {
                color: $black;
            }
        }
    }
}

@media screen and (min-width: ($tablet-sm + 1)) {
    .d-none {
        display: none !important;
    }

}

@media screen and (max-width: $tablet-sm) {
    .m-none {
        display: none !important;
    }
}

@media screen and (max-width: $tablet-sm) {
    .mb-x-15 {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.fade-children {
    &>* {
        opacity: 0;
    }
}

.fade-in {
    opacity: 0;
}

.default-photo-text-container {
    display: flex;
    margin-bottom: max(35px, vw(90px));
    align-items: flex-end;
    flex-direction: row-reverse;

    @media screen and (max-width: $tablet-sm) {
        flex-direction: column-reverse;
        align-items: unset;
    }

    .default-inner-text-container {
        flex-basis: 38%;
        margin-left: 40px;

        @media screen and (max-width: $laptop-sm) {
            margin-left: 15px;
        }

        @media screen and (max-width: $tablet-sm) {
            margin-left: 0;
        }

        .default-info {
            width: 100%;
            max-width: 500px;

            @media screen and (max-width: $tablet-sm) {
                max-width: 100%;
                padding: 15px 10px;
            }

            h2 {
                font-weight: 100;
                font-size: max(22px, vw(63px));
                margin-bottom: 30px;

                @media screen and (max-width: $laptop-sm) {
                    margin-bottom: 15px;
                }
            }

            .default-text {
                font-size: 15px;
                font-weight: 100;
                line-height: 18px;
                margin-bottom: 15px;

                @media screen and (max-width: $tablet) {
                    font-size: 13px;
                }
            }

            a{
                &:not(.underline--black) {
                    display: inline-block;
                    font-size: 13px;
                    font-weight: 500;
                    border-bottom: 1px solid #a6a6a6;
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                    color: $black;
                }
            }
        }
    }

    &.reversed {
        flex-direction: row;

        @media screen and (max-width: $tablet-sm) {
            flex-direction: column-reverse;
        }

        .default-inner-text-container {
            margin-right: 15px;
            margin-left: 0;

            @media screen and (max-width: $tablet-sm) {
                margin-right: 0;
            }
        }
    }

    .default-inner-photo-container {
        flex-basis: 62%;

        .desktop-photo {
            display: block;

            @media screen and (max-width: $mobile-sm) {
                display: none;
            }
        }

        .mobile-photo {
            display: none;

            @media screen and (max-width: $mobile-sm) {
                display: block;
            }
        }
    }
}

.breadcrumbs-wrap {
    padding-top: max(30px, vw(50px));
    padding-left: max(15px, vw(60px));
    padding-right: max(15px, vw(60px));
    padding-bottom: max(10px, vw(30px));
    @media screen and (max-width: $tablet-sm) {
        padding-right: 15px;
        padding-left: 15px;
    }

    &.in-search {
        display: flex;
        justify-content: space-between;
        padding-left: max(15px, vw(50px));
        padding-right: max(15px, vw(50px));
        padding-bottom: max(10px, vw(30px));
        align-items: center;

        .breadcrumb {
            padding-bottom: 0;
        }
    }

    .breadcrumb {
        margin: 0;
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        border: none;
        background-color: transparent;

        li {
            padding: 0;
            &.text--breadcrumbs--static {
                @include font($font-default, #{max(13px, vw(13px))}, $weight-light, normal, 1);
            }

            a {
                text-transform: uppercase;
                display: inline-block;

                &:hover {
                    color: inherit;
                    text-decoration: none;
                }
            }

            &::after {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6.6 10.7' style='enable-background:new 0 0 6.6 10.7' xml:space='preserve'%3E%3Cpath style='fill:none;stroke:%23333;stroke-width:1.0006;stroke-linejoin:bevel;stroke-miterlimit:10' d='m.4.4 5.9 5-6 5'/%3E%3C/svg%3E");
                display: inline-block;
                width: 6px;
                margin: 0 7px 0 10px;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }

            &+li {
                &:before {
                    display: none;
                }
            }
        }
    }
}

.video-wrap {
    display: flex;
    width: 100%;
    height: 100%;

    video {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.card_view--available-sizes {
    &>* {
        border: 1px solid $white;
        display: inline-flex;
        pointer-events: all;

        &:not(:last-child) {
            margin-right: 5px;
        }

        &>label,
        &>span {
            @include transition;
            font-size: max(12px, vw(16px));
            display: inline-flex;
            justify-content: center;
            align-items: center;
            min-width: max(22px, vw(30px));
            height: max(22px, vw(30px));
            margin: 2px;
            cursor: pointer;
            @media screen and (max-width: $laptop-xs) {
                min-width: max(19px, vw(30px));
            }
        }

        &:hover,
        &.selected-size {

            &>label,
            &>span {
                background-color: $black;
                color: $white;
            }
        }
    }

    &.theme-black {
        &>* {
            border-color: $black;

            &>label,
            &>span {
                color: $black;
            }

            &:hover,
            &.selected-size {

                &>label,
                &>span {
                    background-color: $black;
                    color: $white;
                }
            }
        }

        &--rs-swap {
            &>* {
                border-color: $black;

                @media screen and (max-width: $tablet) {
                    border-color: $white;                    
                }
    
                &>label,
                &>span {
                    color: $black;
                    
                    @media screen and (max-width: $tablet) {
                        color: $white;                    
                    }
                }
    
                &:hover,
                &.selected-size {
    
                    &>label,
                    &>span {
                        background-color: $black;
                        color: $white;
                        
                        @media screen and (max-width: $tablet) {
                            background-color: $white;
                            color: $black;
                        }
                    }
                }
            }
        }
    }

    &.theme-white {
        &>* {

            &:hover,
            &.selected-size {

                &>label,
                &>span {
                    background-color: $white;
                    color: $black;
                }
            }
        }
    }
}

// select2
$s2-radius: 100px;
$s2-hover-selected-bg: $gray-dark;
$s2-hover-selected-color: $white;
$s2-opened-top-bg: $white;
$s2-opened-top-border-color: $gray-dark;
$s2-opened-bottom-border-color: $gray-dark;
$s2-opened-top-radius: 0;
$s2-opened-bottom-radius: 0;
$s2-opened-bottom-padding: 15px 0 15px 0;

.select2-container {

    &--default {

        .select2 {

            &-selection {

                &--single {
                    @include font($font-default, #{max(15px, vw(15px))}, $weight-thin, normal, 1);
                    @include inputStracture(1px, solid, $s2-radius, max(10px, vw(20px)) 20px);
                    border-color: $gray-dark;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    height: unset;
                    background-color: transparent;
                    color: $black;
                    width: 100% !important;
                    line-height: 1 !important;

                    .select2-selection__arrow {
                        height: 100%;
                        position: absolute;
                        top: 0;
                        right: 20px;
                        width: 11px;
                        height: 100%;

                        b {
                            margin-top: unset;
                            // border-color: $gray-dark transparent transparent transparent;
                            // border-width: 6px 5px 0 5px;
                            transform: translate(-50%, -50%);
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10.8 6.7' style='enable-background:new 0 0 10.8 6.7' xml:space='preserve'%3E%3Cpath style='fill:none;stroke:%23333;stroke-width:1.0006;stroke-linejoin:bevel;stroke-miterlimit:10' d='m10.4.4-5 5.9-5-6'/%3E%3C/svg%3E");
                            background-repeat: no-repeat;
                            border: unset;
                            width: 11px;
                            height: 11px;
                            transform: rotate(-90deg) translatex(5px) scale(.9);
                            opacity: .5;
                        }
                    }
                }

                &__rendered {
                    line-height: 18px !important;
                    color: $gray-dark !important;
                    padding-right: 10px;
                }
            }

            &-results__option {
                @extend .text--breadcrumbs;
                margin: 0 25px;
                padding: 15px 0;

                &:hover,
                &--selected {
                    background-color: $s2-hover-selected-bg !important;
                    color: $s2-hover-selected-color !important;
                    margin-left: 10px;
                    margin-right: 10px;
                    border-radius: $s2-radius;
                    padding: 15px;
                }

                &--highlighted {
                    &.select2-results__option--selectable {
                        background-color: $s2-hover-selected-bg !important;
                        color: $s2-hover-selected-color !important;
                        margin-left: 10px;
                        margin-right: 10px;
                        border-radius: $s2-radius;
                        padding: 15px;
                    }
                }
            }
        }

        &.select2-container--open {
            .select2 {
                &-selection {
                    &--single {
                        border-radius: $s2-opened-top-radius;
                        background-color: $s2-opened-top-bg !important;
                        border: 1px solid $s2-opened-top-border-color;

                        .select2-selection__arrow b {
                            // border-color: transparent transparent $black transparent !important;
                            // border-width: 0 5px 6px 5px !important;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10.8 6.7' style='enable-background:new 0 0 10.8 6.7' xml:space='preserve'%3E%3Cpath style='fill:none;stroke:%23333;stroke-width:1.0006;stroke-linejoin:bevel;stroke-miterlimit:10' d='m.4 6.2 5-5.9 5 6'/%3E%3C/svg%3E");
                            background-repeat: no-repeat;
                            border: unset;
                            width: 11px;
                            height: 11px;
                            transform: rotate(-180deg) translatey(7px) scale(.9);
                        }

                        .select2-selection__rendered {
                            color: $gray-dark !important;
                        }
                    }
                }
            }

            .select2 {
                &-dropdown {
                    &--below {
                        border-color: $s2-opened-bottom-border-color;
                        border-bottom-left-radius: $s2-opened-bottom-radius;
                        border-bottom-right-radius: $s2-opened-bottom-radius;
                        padding: $s2-opened-bottom-padding;
                    }

                    &--above {
                        border-color: $s2-opened-bottom-border-color;
                        border-top-left-radius: $s2-opened-top-radius;
                        border-top-right-radius: $s2-opened-top-radius;
                        padding: $s2-opened-bottom-padding;
                    }
                }
            }
        }
    }
}

.js-select2-custom {
    &.select2-container {
        width: 150px !important;
    
        &--default {
    
            .select2 {
    
                &-selection {
    
                    &--single {
                        @extend .text--breadcrumbs;
                        padding: 0;
                        border: unset;
                    }
                }
            }
        }
    }
    &-dropdown {
        width: 200px;
        border: none;
        box-shadow: 10px 10px 15px -10px #000;
        margin-left: -20px;

        .select2 {           

            &-results__option {
                @extend .text--breadcrumbs;
                margin: 10px 0;
                padding: 0;
                margin-left: 28px !important;

                &:hover,
                &--selected {
                    background-color:transparent !important;
                    text-decoration: underline;
                    color: $black !important;
                    margin-left: unset;
                    margin-right: unset;
                    border-radius: unset;
                    padding: unset;
                }

                &--highlighted {
                    &.select2-results__option--selectable {
                        background-color:transparent !important;
                        text-decoration: underline;
                        color: $black !important;
                        margin-left: unset;
                        margin-right: unset;
                        border-radius: unset;
                        padding: unset;
                    }
                }
            }
        }
    }
}

.filters__product.open,
.select2-results__options {
    margin-right: 9px;
    margin-top: 0;
    margin-bottom: 0;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        @include prefix((box-shadow: inset 0 0 6px transparent), webkit moz);
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        @include prefix((box-shadow: inset 0 0 6px $gray-light), webkit moz);
        background-color: $gray-light;
        border-radius: 4px;
    }
}

.required-label {
    label {
        &:before {
            content: '*'
        }
    }
}

.field__wrap--input {
    &.required {
        @extend .required-label;
    }
}

.required {
    .account-form--field {
        @extend .required-label;
    }
}

.show--hidden {
    @include transition;
}

.homepage__recommended,
.homepage__sub-recommended,
.homepage__best-sellers,
.homepage__shop-the-look,
.homepage__special-offers,
.the-team__arc {
    .slider-wrap {
        opacity: 0;

        &.show--hidden {
            opacity: 1;
        }
    }
}

.go-back {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 1;
    text-decoration: underline;
    &:hover, &:focus, &:visited {
        text-decoration: underline;
    }

    @media screen and (max-width: $tablet-sm) {
        text-decoration: underline;
    }
}

.page_category {
    .select2-results__option--disabled {
        display: none !important;
    }
    .select2-container--default .select2-selection__arrow {
        //left: 65px;
        //right: unset;
        height: 11px;
    }
}

.js-show {
    @include transition;
    opacity: 0;
}
.js-show-slow {
    @include transition;
    opacity: 0;
}

.js-restrict-overflow {
    overflow: hidden;
    
    @media (hover: hover) {
      padding-right: 17px;
    }
}

.decor {
    &--underline {
        &,
        &:hover {
            text-decoration: underline !important;
        }
    }
}

.mini-decorated {
    .account-form--field {
        margin-bottom: 15px !important;

        span {
            flex: 1;
            font-size: 1rem;
    
            a {
                font-weight: bold;
                text-decoration: underline;
    
                &:hover,
                &:focus {
                    color: $black;
                }
            }
        }
    }
}

.eye-pass {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-36px, -50%);
    cursor: pointer;
}

.cc-settings-dialog {
    .checkbox-label span {
        color: $white;
        font-size: 1.6rem;
        font-weight: 500;
    }
    .cc-btn {
        font-weight: 500!important;
        &.cc-btn-close-settings {
            border: 1px solid $white;
        }
    }
}
.cc-btn {
    font-weight: 500!important;
    &.cc-btn-save-settings {
        &:hover, &:focus {
            color:$black;
            font-weight: 500;
        }
    }
}

.cc-settings-dialog div.cc-highlight .cc-btn:first-child {
    font-size: unset!important;
    position: relative!important;
}

.card_view,
.category-listing__item {
    &.js-pre-touch {
        a {
            pointer-events: none;
        }
    }
}

.video-handler {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
    cursor: pointer;

    img {
        display: block;
        width: 20px;
        height: 20px;
        object-fit:unset !important;
        min-height: unset !important;
    }
    &.sound-on img {
        display: block;
        content: url("../../../../default/assets/icons/sound-on.svg");
        width: 20px;
        height: 20px
    }
}
