.the-team {
    &>* {
        padding-left: max(15px, vw(50px));
        padding-right: max(15px, vw(50px));

        .swiper-slide {
            @include transition($time:1s);
            overflow: hidden;
        }
    }

    &__intro {
        margin-top: max(70px, vw(110px));
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        &--title {
            margin-bottom: max(25px, vw(45px));
        }

        &--content {
            max-width: max(275px, vw(730px));
            margin-bottom: max(25px, vw(60px))
        }
    }

    &__arc {

        margin-bottom: max(40px, vw(120px));

        &--slider {
            display: flex;
            align-items: center;
            min-height: vw(801px);

            .arc {
                display: flex;
                justify-self: center;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                img {
                    width: max(332px, vw(699px));
                    height: 100%;
                    margin: auto;

                    @media screen and (max-width: $tablet) {
                        width: 100%;
                        max-width: 90%;
                        content: var(--arc-mob);
                    }
                }

            }
        }
        
        @media screen and (max-width: $tablet) {
            .slider-wrap {
                max-width: 515px;
                margin: auto;
            }
        }

        &__sliderwrap {
            align-items: center;
            // margin-top: 100px;
            left: vw(-210px);
            // margin-bottom: 60px;

            @media screen and (max-width: $tablet) {
                left: unset;
            }
        }

        .swiper-slide {
            margin: 0;
            height: auto;
            width: 100%;
            padding: 0;

            @media screen and (max-width: $tablet) {
                .card_view {
                    .card_view--image {
                        margin-top: vw-rs(375px, 50px);
                    }
                }
            }

            @media screen and (min-width: ($tablet + 1)) {
                .card_view {
                    .card_view--image {
                        margin-top: 50px;

                        &>img {
                            @include transition($time: 1s);
                            min-width: 265px;
                            max-width: 265px;
                            min-height: max(165px, vw(347px));
                            max-height: max(165px, vw(347px));
                            object-fit: cover;
                        }
                    }
                }

                &-active {
                    // min-width: max(257px, vw(446px));
                    // max-width: max(257px, vw(446px));
                    // min-height: max(337px, vw(583px));
                    // max-height: max(337px, vw(583px));

                    .card_view {
                        .card_view--image {

                            &>img {
                                min-width: max(257px, vw(446px));
                                max-width: max(257px, vw(446px));
                                min-height: max(337px, vw(583px));
                                max-height: max(337px, vw(583px));
                                object-fit: cover;
                            }
                        }
                    }

                }
            }

            &-active {
                opacity: 1;

                @media screen and (min-width: ($tablet + 1)) {
                    width: vw(446px) !important;
                    margin-left: vw(127px);
                    margin-right: vw(127px) !important;

                }
            }

            .card_view {
                a {
                    &:hover {
                        color: inherit;
                        text-decoration: none;
                    }
                }                

                &--active {
                    @include transition;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;

                    &-description {
                        @include transition;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: vw(270px);
                        width: vw(446px);
                        margin: auto;
                        color: $white;

                        width: 100%;
                        padding-left: vw(94px);
                        padding-right: vw(94px);

                        background-color: rgba(#333333, 0.65);
                        opacity: 0;
                        pointer-events: none;

                        @media screen and (max-width:$laptop-sm) {
                            padding-left: 20px;
                            padding-right: 20px;
                        }
    
                        @media screen and (max-width:$tablet-sm) {
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                        @media screen and (max-width:$mobile) {
                            justify-content: unset;
                        }


                        .text--light--big {
                            margin-bottom: 25px;
                            @media screen and (max-width:$mobile) {
                                padding-top: 30px;
                            }
                        }
                        //.short--text {
                        //    @include shortText(var(--short--text));
                        //
                        //    @media screen and (max-width:1700px) {
                        //        @include shortText(calc(var(--short--text) - 10));
                        //    }
                        //
                        //    @media screen and (max-width:1500px) {
                        //        @include shortText(calc(var(--short--text) - 15));
                        //    }
                        //
                        //    @media screen and (max-width:$tablet) {
                        //        @include shortText(20);
                        //    }
                        //
                        //    @media screen and (max-width: $mobile) {
                        //        @include shortText(calc(var(--short--text) - 10));
                        //    }
                        //
                        //    @media screen and (max-width: 500px) {
                        //        @include shortText(calc(var(--short--text) - 15));
                        //    }
                        //}
                        
                    }

                    &-options {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        padding-bottom: vw(20px);
                        padding-left: vw(35px);
                        padding-right: vw(35px);

                        &>* {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            color: $white;

                            &:not(:nth-child(2)) {
                                opacity: 1;
                            }

                            &:nth-child(2) {
                                @include transition;
                                opacity: 0;
                                pointer-events: none;
                            }
                        }
                    }

                    &.content-open {
                        .card_view--active-description {
                            opacity: 1;
                            pointer-events: all;
                        }
                        .card_view--active-options {
                            &>* {                                
                                &:nth-child(2) {
                                    opacity: 1;
                                    pointer-events: all;
                                }
                            }
                        }
                    }

                    &-left {
                        &>* {
                            &:not(:last-child) {
                                //margin-bottom: 10px;
                            }
                        }
                    }

                    &-center {
                        text-align: right;
                        flex: 2;
                        margin-bottom: 10px;
                        position: absolute;
                        right:vw(35px);
                        bottom:max(25px, vw(35px));

                        @media screen and (max-width:400px) {
                            right: vw(35px);
                            left: vw(35px);
                            text-align: center;
                            bottom: 40px;
                        }
                    }

                    &-right {
                        text-align: right;

                        .details {
                            //font-style: italic;
                            font-size: 13px;
                            .underline--team {
                                &:before {
                                    bottom: -1px;
                                }
                            }

                        }
                    }

                    &-title {}

                    &-available-sizes {
                        span {
                            &:not(:last-child) {
                                margin-right: 15px;
                            }
                        }
                    }

                    &-price {}

                    &-cta-pre {
                        margin-bottom: 10px;
                        cursor: pointer;
                    }

                    &-pre-available-sizes {
                        span {
                            @include transition;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            width: 30px;
                            height: 30px;
                            border: 1px solid $white;
                            cursor: pointer;

                            &:hover,
                            &.selected-size {
                                background-color: $black;
                                color: $white;
                            }
                        }
                    }

                    &-cta-add {
                        cursor: pointer;
                        &:hover {
                            color: $white;
                        }
                    }

                    &-cta-view {
                        cursor: pointer;
                        position: absolute;
                        bottom: -35px;
                        margin: auto;
                        align-self: center;
                    }
                }
            }
        }

        .swiper-slide {
            &-active {
                .card_view--footer {
                    opacity: 0;
                    pointer-events: none;
                    max-height: 0;
                    padding: 0;
                }
            }
        }

        @extend .slider-type--inline;
    }
}