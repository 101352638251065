.contact-container {
  padding: 0 max(25px, vw(70px)) max(50px, vw(150px));
  .contact-content-container {
    display: flex;
    justify-content: space-between;
    //align-items: flex-end;
    margin-bottom: 10px;
    @media screen and (max-width: $tablet-lg){
      margin-bottom: unset;
    }
    &>div{
      width: 33.333%;
      @media screen and (max-width: $tablet-lg){
        width: 100%;
      }
    }
    @media screen and (max-width: $tablet-lg){
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .contact-info-container {
      @media screen and (max-width: $tablet-lg){
        margin-bottom: 20px;
      }
      .contact-title {
        font-size: max(20px, vw(27px));
        font-weight: 300;
        margin-bottom: max(25px, vw(50px));
      }
      &>* {
        padding-left: max(0px, vw(40px));
        //margin-bottom: max(20px, vw(40px));
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: unset;
        }
        @media screen and (max-width: $tablet-lg){
          padding-left: 0;
        }
        h3 {
          @include font($font-default, #{max(13px, vw(18px))}, $weight-normal, normal, 1);
          // font-size: max(13px, vw(18px));
          // font-weight: 400;
          //margin-bottom: 8px;
          margin-bottom: 10px;
          @media screen and (max-width: $tablet-lg){
            font-weight: 500;
          }
        }
        li {
          @include font($font-default, #{max(13px, vw(13px))}, $weight-thin, normal, 1.2);
          a{
            &:hover{
              color: unset;
            }
          }
        }
      }
    }
    .contact-map-container {
      @media screen and (max-width: $laptop-sm){
        width: 50%;
      }
      @media screen and (max-width: $tablet-lg){
        margin-bottom: 20px;
      }
      @media screen and (max-width: $tablet-sm){
        width: 70%;
      }
      @media screen and (max-width: $mobile){
        width: 100%;
      }
      .contact-map {
        width: max(323px, vw(582px));
        height: 100%;
        margin: 0 auto;
        @media screen and (max-width: $tablet-lg){
          height: max(360px, vw(470px));
        }
        @media screen and (max-width: $laptop-sm){
          width: 100%;
        }
      }
    }
    .contact-details-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-left: 30px;
      margin-bottom: -8px;
      @media screen and (max-width: $tablet-lg){
        padding-left: unset;
        margin-bottom: unset;
      }
      .contact-details {
        h3 {
          display: inline-block;
          border-bottom: 1px solid #000;
          padding-bottom: 5px;
          font-size: max(13px, vw(24px));
          text-transform: uppercase;
          font-weight: 100;
          margin-bottom: max(12px, vw(25px));
        }
        p {
          @include font($font-default, #{max(13px, vw(24px))}, $weight-thin, normal, 1);
          // font-size: max(13px, vw(24px)) ;
          // font-weight: 100;
          margin-bottom: 10px;
          a{
            &:hover{
              color:unset;
            }
          }
        }
      }
    }
  }
  .contact-book-appointment-container {
    text-align: center;
    a{
      font-weight: 300;
      &:hover{
        color: unset;
      }
    }
  }
}