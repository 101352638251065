.store {
    &>* {
        .swiper-slide {
            @include transition($time:1s);
            overflow: hidden;
        }
    }

    &__landing {
        padding-left: max(15px, vw(50px));
        padding-right: max(15px, vw(50px));

        &--banner {
            width: vw(1705px);
            height: vw(960px);
            margin: auto;
            margin-bottom: max(20px, vw(40px));
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width:$tablet-sm) {
                width: vw-rs(375px, 326px);
                height: vw-rs(375px, 184px);
            }

            img,
            video {
                object-fit: cover;
                width: vw(1705px);
                height: vw(960px);

                @media screen and (max-width:$tablet-sm) {
                    width: vw-rs(375px, 326px);
                    height: vw-rs(375px, 184px);
                }
            }
        }
    }

    &__intro {
        padding-left: max(15px, vw(50px));
        padding-right: max(15px, vw(50px));
        margin-top: max(70px, vw(110px));
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        &--title {
            margin-bottom: max(25px, vw(45px));
        }

        &--content {
            max-width: max(275px, vw(830px));
            margin-bottom: max(25px, vw(60px))
        }
    }

    &__slider-section {
        padding-left: max(50px, vw(140px));
        padding-right: max(50px, vw(140px));
        margin-bottom: max(30px, vw(60px));
    }

    &__info {
        padding-left: max(15px, vw(140px));
        padding-right: max(15px, vw(140px));
        padding-bottom: max(50px, vw(70px));
        display: flex;

        @media screen and (max-width: $tablet-sm) {
            flex-direction: column;
        }

        &>* {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;

            @media screen and (max-width: $tablet-sm) {
                width: 100%;
            }

            &:first-child {
                align-items: center;

                .map-wrap {
                    margin-bottom: 20px;
                }

                @media screen and (max-width: $tablet-sm) {
                    margin-bottom: 20px;
                }
            }

            &:last-child {

                @media screen and (min-width: ($tablet-sm + 1)) {
                    margin-bottom: 47px;
                    padding-left: 40px;
                }

                @media screen and (max-width: $tablet-sm) {
                    align-items: center;
                }

                &>* {
                    &:not(:first-child) {
                        margin-top: max(10px, vw(20px));
                    }
                }
            }
        }
    }
}

.map-wrap {
    #map {
        width: vw(811.5px);
        height: vw(811.5px);

        @media screen and (max-width: $tablet-sm) {
            width: calc(100vw - 40px);
            height: vw-rs(375px, 261px);
        }
    }
}