// ALL ACCOUNT PAGES
$secondary_font: $font_default !default;
// account palette
$color_a: #333333;
$color_b:$green-dark;

.has-page-message {

    &,
    &-error,
    &-success {
        margin: auto;
        max-width: 600px;
        text-align: center;
        border: 1px solid;
        border-radius: 100px;
        padding: 15px;
        margin-bottom: 20px;
    }

    &-wrap {
        margin-left: 15px;
        margin-right: 15px;
    }

    &-error {
        color: $red;
    }

    &-success {
        color: $green-dark;
    }
}

.account {

    //account typography @extendable
    &-titles {
        @extend .title--small;

        &--custom {
            @include font($font-default, #{max(18px, vw(22px))}, $weight-medium, normal, 1);  //psd non viable
        }
    }

    &--ul {
        // @include font($font-default, #{max(12px, vw(20px))}, $weight-light, normal, 1); //psd non viable
        @include font($font-default, #{max(16px, vw(20px))}, $weight-light, normal, 1);
    }

    &-texts {
        @extend .text--light--big;
    }

    &-table {

        &--head {
            @include font($font-default, #{max(14px, vw(14px))}, $weight-light, normal, 1);
        }

        &--body {
            @include font($font-default, #{max(14px, vw(14px))}, $weight-thin, normal, 1);
        }
    }

    //account typography @extendable end

    &-page-height {
        min-height: calc(var(--screen-height) - var(--header-height));
    }

    &-max {
        &--small {
            max-width: 600px;
            margin-bottom: 75px;
            margin-top: 35px;
        }

        &--big {
            max-width: 900px;
            margin-bottom: 75px;
            margin-top: 35px;
        }
    }

    &-title {
        @extend .account-titles;
        margin-bottom: max(20px, vw(40px));
        color: $color_a;
        text-transform: uppercase;
    }

    &-text {
        @extend .account-texts;
        margin-bottom: max(20px, vw(40px));
        color: $color_b;
    }

    &-cta {
        margin-top: auto;

        &--forgot {
            text-align: right;

            a {
                background: transparent;
                border: none;
                color: inherit;
                text-decoration: underline;
                padding: 0;
                position: absolute;
                right: 0;
                bottom: -20px;

                &:hover {
                    background-color: unset;
                    color: unset;
                    border-color: unset;
                }
            }
        }
    }

    &-page-heading {
        margin-bottom: max(20px, vw(40px));
        color: $color_a;
        display: flex;
        flex-direction: column;

        h2 {
            @include font($font-default, #{max(22px, vw(24px))}, $weight-light, normal, 1);
            text-align: center;
            color: inherit;
            margin-bottom: 0;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        &>span {

            @include font($font-default, #{max(14px, vw(16px))}, $weight-light, normal, 1);
            margin-bottom: 0;
            display: inline;
            text-align: center;
            max-width: 375px;
            margin: auto;

            &.expand-width {
                max-width: unset;
            }
        }

        &--logo {
            margin-left: auto;
            margin-right: auto;
            width: 200px;

            svg {
                width: 200px;
            }
        }
    }

    &-block {
        margin-bottom: max(20px, vw(40px));

        &-title {
            @extend .account-titles--custom;
            margin-bottom: 5px;
            text-transform: uppercase;
        }

        ul {
            @extend .account--ul;
            // text-transform: uppercase;

            li {
                margin-bottom: 5px;
            }
        }
    }

    &-content {

        .js-custom-field {
            display: none;
        }

        .if-no-socials {
            margin-bottom: max(20px, vw(40px));
        }

        .select2-container {
            min-width: 100%;
        }

        // reset bootstrap class
        @media screen and (max-width: 767px) {
            .table-responsive {
                border: unset;
            }
        }

        .input-group {
            button {
                background-color: transparent !important;
                border: unset !important;

                &:active {
                    box-shadow: unset !important;
                }
            }
        }

        // reset bootstrap class end

        a {
            &:hover {
                color: inherit;
                text-decoration: underline;
            }
        }

        p {
            a {
                text-decoration: underline;
            }
        }

        select {
            border-color: $color_a;
            color: $color_a;
        }

        input {

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                box-shadow: unset !important;
            }
        }

        .inline-option,
        ._newsletter {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 30px 0;

            &>* {
                text-align: center;
            }

            &--not-centered {
                align-items: initial;

                &>* {
                    text-align: left;
                }
            }

            label {
                left: 0;
                top: 0;

                &.radio-inline {
                    @media screen and (min-width: ($tablet + 1)) {
                        font-size: 75%;
                    }

                    padding: 5px;
                }

            }
        }

        #d_social_login {
            font-size: 12px;
        }

        .order-pag {
            font-size: 14px;
        }

        thead {
            tr {
                td {
                    color: $color_b;
                }
            }
        }

        tfoot {
            font-size: 14px;

            tr {
                &:last-of-type {
                    text-transform: uppercase;
                }
            }
        }
    }

    &-dual-block {
        display: flex;
        justify-content: space-between;
        padding-bottom: max(20px, vw(90px));
        padding-top: max(20px, vw(90px));
        border-bottom: 1px solid $color_b;

        @media screen and (max-width:$tablet) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding-top: 0;
            padding-bottom: 42px;
        }

        &--item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 50%;
            max-width: 512px;

            @media screen and (max-width:$tablet) {
                width: 100%;
                text-align: center;

                &:first-child {
                    margin-top: 25px;
                    margin-bottom: 64px;
                }
            }

            @media screen and (min-width: ($tablet + 1)) {
                &:first-child {
                    margin-right: 58px;
                }

                &:last-child {
                    margin-left: 58px;
                }

            }

            p {
                @media screen and (min-width: ($tablet + 1)) {
                    margin-bottom: 0;
                }

                @media screen and (max-width: $tablet) {

                    text-align: center !important;
                }
            }

        }
    }

    &-single-block {
        display: flex;
        justify-content: center;
        margin-bottom: max(20px, vw(90px));
        margin-top: max(20px, vw(90px));
        padding: unset;
        color: $white !important;
        min-height: 547px;

        @media screen and (min-width: ($tablet + 1) ) and (max-width: 1550px) {
            margin-top: -20px;
            transform: scale(.9);
            transform-origin: top;
        }

        // @media screen and (max-width:$tablet) {
        //     flex-direction: column;
        //     justify-content: flex-start;
        //     align-items: center;
        //     padding-top: 0;
        //     padding-bottom: 42px;
        // }


        input[name="email"],
        input[name="password"] {
            @extend .input--white;
            padding-top: 12px;
            padding-bottom: 12px;
        }

        &.arc {
            &:before {
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 477.7 547' style='enable-background:new 0 0 477.7 547' xml:space='preserve'%3E%3Cpath d='M477.5 233.5C475 104.1 369.1 0 238.9 0S2.9 104.1.3 233.5L0 547h478l-.5-313.5z'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                max-width: 480px;
                margin: auto;

                @media screen and (max-width: $tablet-sm) {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 332 521' style='enable-background:new 0 0 332 521' xml:space='preserve'%3E%3Cpath d='m331.3 378-.3-216.6C329.2 71.9 256.1 0 166.1 0S3 71.9 1.2 161.4L1 378h.1L1 521h330.3l-.2-143h.2z'/%3E%3C/svg%3E");
                    max-width: 332px;
                }
            }
        }

        &--logo {
            margin-left: auto;
            margin-right: auto;
            margin-top: 90px;
            margin-bottom: 50px;
            width: 150px;

            @media screen and (max-width:$tablet-sm) {
                margin-top: 70px;
                margin-bottom: 40px;
            }

            svg {
                width: 150px;
            }
        }

        &--item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 50%;
            max-width: max(285px, vw(350px));

            &.account-success {
                width: 75%;
                max-width: 75%;
                text-align: center;
                position: relative;

                @media screen and (max-width:$tablet) {
                    width: 300px;
                    max-width: 300px;
                }

                .account-success-bottom {
                    position: absolute;
                    width: 100%;
                    bottom: 85px;
                }

            }

            .account-success-content {
                @include font($font-default, #{max(17px, vw(17px))}, $weight-thin, normal, 1);
                width: 100%;
                max-width: 320px;
                margin: 0 auto;
                padding: 10px;
            }

            @media screen and (max-width:$tablet) {
                width: 100%;
                text-align: center;

                // &:first-child {
                //     margin-top: 25px;
                //     margin-bottom: 64px;
                // }
            }

            @media screen and (min-width: ($tablet + 1)) {
                &:first-child {
                    margin-right: 58px;
                }

                &:last-child {
                    margin-left: 58px;
                }

            }

            .account-form {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .account-form--field {
                width: 100%;

                &:first-child {
                    margin-bottom: 10px;
                }

                &:not(:first-child) {
                    margin-bottom: 10px;
                }

                .account-create {
                    @include font($font-default, #{max(16px, vw(16px))}, $weight-thin, normal, 1);
                    margin-top: 20px;

                    // @media screen and (max-width: $tablet) {
                    //     margin-top: 40px;
                    // }

                    a {
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            .account-cta--forgot {
                line-height: 0;

                a {
                    @include font($font-default, #{max(12px, vw(12px))}, $weight-light, normal, 1);
                    position: relative;
                    right: unset;
                    bottom: unset;
                }
            }

            .account-socials {
                #d_social_login {
                    margin: unset;

                    .d-social-login {
                        &__label {
                            display: none;
                        }

                        &__button {

                            &__icon,
                            &__label {
                                display: none;
                            }

                            &-list {
                                display: flex;
                                justify-content: center;
                            }

                            width: 40px;
                            height: 40px;
                            background-color: unset;

                            &:before {
                                content: "";
                                display: flex;
                                width: 40px;
                                height: 40px;
                            }

                            &--facebook {
                                //margin-right: 15px;

                                &:before {
                                    content: "";
                                    //facebook
                                    // background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 27.5 27.4' style='enable-background:new 0 0 27.5 27.4' xml:space='preserve'%3E%3Cpath fill='%23333' d='M15.3 23.9h-3.8v-9.1h-3v-3.9h3V8.4c0-.7.1-1.3.3-1.9.5-1.4 1.5-2.3 3-2.7.4-.1.8-.2 1.3-.2.8 0 1.6 0 2.4.1h.6v3.5h-2c-.3 0-.5 0-.8.1-.5.1-.8.5-.9 1v2.5h3.5l-.5 3.9h-3v1l-.1 8.2zm-3.7-.2zm.1-.1H15V14.5h3.1l.4-3.3H15V8.3c.1-.6.4-1.1 1.1-1.2.3-.1.6-.2.9-.2H18.8v-3h-.3c-.8-.1-1.6-.1-2.4-.1-.4 0-.8.1-1.2.2-1.4.4-2.3 1.2-2.8 2.5-.2.6-.3 1.1-.3 1.8v2.8h-3v3.3h2.9v9.2z'/%3E%3Cpath fill='%23333' d='M13.7 27.4C6.1 27.4 0 21.3 0 13.7S6.1 0 13.7 0s13.7 6.2 13.7 13.7-6.1 13.7-13.7 13.7zm0-27.2C6.3.2.2 6.2.2 13.7s6 13.5 13.5 13.5 13.5-6 13.5-13.5S21.1.2 13.7.2z'/%3E%3C/svg%3E");
                                    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 27.5 27.4' style='enable-background:new 0 0 27.5 27.4' xml:space='preserve'%3E%3Cpath fill='%23fff' d='M15.3 23.9h-3.8v-9.1h-3v-3.9h3V8.4c0-.7.1-1.3.3-1.9.5-1.4 1.5-2.3 3-2.7.4-.1.8-.2 1.3-.2.8 0 1.6 0 2.4.1h.6v3.5h-2c-.3 0-.5 0-.8.1-.5.1-.8.5-.9 1v2.5h3.5l-.5 3.9h-3v1l-.1 8.2zm-3.6-.3H15v-9.1h3.1l.4-3.3H15V8.3c.1-.6.4-1.1 1.1-1.2.3-.1.6-.2.9-.2h1.8v-3h-.3c-.8-.1-1.6-.1-2.4-.1-.4 0-.8.1-1.2.2-1.4.4-2.3 1.2-2.8 2.5-.2.6-.3 1.1-.3 1.8v2.8h-3v3.3h2.9v9.2z'/%3E%3Cpath fill='%23fff' d='M13.7 27.4C6.1 27.4 0 21.3 0 13.7S6.1 0 13.7 0s13.7 6.2 13.7 13.7-6.1 13.7-13.7 13.7zm0-27.2C6.3.2.2 6.2.2 13.7s6 13.5 13.5 13.5 13.5-6 13.5-13.5S21.1.2 13.7.2z'/%3E%3C/svg%3E");
                                }
                            }

                            &--google {
                                &:before {
                                    content: "";
                                    //google
                                    // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.8 27.8' style='enable-background:new 0 0 27.8 27.8' xml:space='preserve'%3E%3Cpath d='M13.9.1C6.3.1.1 6.3.1 13.9s6.2 13.8 13.8 13.8 13.8-6.2 13.8-13.8S21.5.1 13.9.1zm3.4 13.8c0 3.5-2.8 6.3-6.3 6.3s-6.3-2.8-6.3-6.3S7.5 7.6 11 7.6c1.4 0 2.7.4 3.8 1.3l-1.5 1.9c-.7-.5-1.5-.8-2.4-.8C8.8 10 7 11.7 7 13.9s1.7 3.9 3.9 3.9c1.7 0 3.2-1.1 3.7-2.7h-3.7v-2.4h6.3l.1 1.2zm6.6.8h-1.8v1.8h-1.6v-1.8h-1.8v-1.6h1.8v-1.8h1.6v1.8h1.8v1.6z' style='fill:none;stroke:%23333;stroke-width:.25;stroke-miterlimit:10'/%3E%3C/svg%3E");
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.8 27.8' style='enable-background:new 0 0 27.8 27.8' xml:space='preserve'%3E%3Cpath d='M13.9.1C6.3.1.1 6.3.1 13.9s6.2 13.8 13.8 13.8 13.8-6.2 13.8-13.8S21.5.1 13.9.1zm3.4 13.8c0 3.5-2.8 6.3-6.3 6.3s-6.3-2.8-6.3-6.3S7.5 7.6 11 7.6c1.4 0 2.7.4 3.8 1.3l-1.5 1.9c-.7-.5-1.5-.8-2.4-.8C8.8 10 7 11.7 7 13.9s1.7 3.9 3.9 3.9c1.7 0 3.2-1.1 3.7-2.7h-3.7v-2.4h6.3l.1 1.2zm6.6.8h-1.8v1.8h-1.6v-1.8h-1.8v-1.6h1.8v-1.8h1.6v1.8h1.8v1.6z' style='fill:none;stroke:%23fff;stroke-width:.25;stroke-miterlimit:10'/%3E%3C/svg%3E");
                                }
                            }
                        }
                    }
                }
            }

            .or {
                margin-top: 20px;
                margin-bottom: 10px;
            }
        }
    }

    &-form {
        &--field {
            &:not(.account-cta) {
                margin-bottom: max(15px, vw(30px));
            }

            &>div {
                &:not(.d-f-column) {                    
                    &>label {
                        &:last-child {
                            margin-left: 10px;
                        }
                    }
                }

                &.d-f-column {
                    display: flex;
                    flex-direction: column;
                }
            }

            .checkbox-label span {
                font-size: 1.3rem;
            }

            .flex-row {
                label {
                    margin-right: 20px;
                }
            }

            &.align-center {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            &--hidden {
                display: none !important;
                pointer-events: none;
            }
        }
    }

    &-table-themed {
        border: 1px solid $color_a;
        border-radius: 100px;
        padding: max(20px, vw(40px));
        margin-bottom: max(20px, vw(40px));

        table {

            >tbody>tr>td,
            >tbody>tr>th,
            >tfoot>tr>td,
            >tfoot>tr>th,
            >thead>tr>td,
            >thead>tr>th {
                border-bottom: unset !important;
            }
        }
    }

    &-table {
        width: 100%;
        margin-bottom: max(20px, vw(40px));

        .nowrap {
            white-space: nowrap;
        }

        >tbody>tr>td,
        >tbody>tr>th,
        >tfoot>tr>td,
        >tfoot>tr>th,
        >thead>tr>td,
        >thead>tr>th {
            border: none;
            border-bottom: 1px solid $gray-light;
            padding: 20px 15px;
        }

        td.text-left a {
            color: #000;
            text-decoration: none;
        }

        td.text-right>a {
            text-decoration: none;
            &.option-hidden {
                display: none;
            }
        }
        
        .add-to-cart-icon-wrap {
            margin-right: 10px;
            margin-left: 10px;
        }
        
        .add-to-cart-icon svg,
        .add-to-cart-icon {
            display: inline-block;
            width: 23px;
            height: 18px;
        }

        .remove-item-icon svg .st0 {
            fill: none;
            stroke: #000000;
            stroke-width: 3;
            stroke-linecap: round;
            stroke-miterlimit: 10;
        }

        .remove-item-icon svg,
        .remove-item-icon {
            display: inline-block;
            width: 15px;
            height: 15px;
        }

        thead>tr>td {
            @extend .account-table--head;
            color: #000;
        }

        tbody>tr>td {
            @extend .account-table--body;
            color: #000;
        }

        .table-hover>tbody>tr {
            opacity: 0.7;
            background-color: transparent;
        }

        .table-hover>tbody>tr:hover {
            opacity: 1;
        }

        td.text-right>a.edit-address-btn,
        td.text-right>a.remove-address-btn,
        td.text-right>a.btn-view-item {
            font-size: 1.1rem;
            line-height: initial;
            font-weight: 600;
            letter-spacing: 0.025em;
            color: #000;
            border: none;
            border-radius: 0;
            background-image: none;
            text-transform: uppercase;
            text-decoration: underline;
        }
    }
}

// SOCIAL LOGIN
#d_social_login {
    display: block;
    width: 100%;
    max-width: max(285px, vw(350px));
    margin: max(20px, vw(40px)) auto max(20px, vw(40px)) auto;

    .d-social-login {

        &__label {
            height: auto;
            margin: auto;
        }

        &__button {
            display: block;
            width: 100%;
            color: $white;
            text-decoration: none;

            &__icon {
                max-width: 100%;
                text-align: left;
                width: 100%;
                display: block;
                position: relative;
                padding-left: 45px;

                img {
                    max-width: 30px;
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    transform: translate(0, -50%);
                }

            }

            &--facebook {
                img {
                    max-width: 10px;
                }
            }

            &--google {

                img {
                    max-width: 25px;
                }
            }
        }
    }
}

.custom-input-group {
    button {
        background-color: transparent !important;
        border: unset !important;
        outline: unset !important;

        &:active {
            box-shadow: unset !important;
        }
    }

    .input-group-btn {
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        background: transparent;
        border: none;
        width: 60px;
        display: flex;
        pointer-events: none;
    }
}

.js-has-error {
    font-size: 12px;
    color: $red;
    margin-left: 20px;
}
.alert-message-danger{
    color: $red;
    a {
        color: $black;
    }
}

#account-account {
    ul {
        li {
            a {
                text-transform: capitalize;
            }
        }
    }
}
.iti.iti--allow-dropdown{
    width: 100%;
}
.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag{
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

//.iti__flag {background-image: url("image/flags");}