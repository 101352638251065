.story-container{
  background-color: #d8d8d8;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
  width: 100vw;
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: max(50px, vw(225px));
  padding-bottom: max(40px, vw(310px));
  @media screen and (max-width: $tablet-sm){
    background-position: center;
  }
  .story-text-container {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
    @media (max-width: $tablet) {
      max-width: 59.031vw
    }
    @media (max-width: $mobile-sm) {
      max-width: 325px;
      padding: 0 10px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    z-index: 5;
    .story-title {
      font-weight: 100;
      font-size: max(30px, vw(63px));
      margin-bottom: 30px;
      @media screen and (max-width: $tablet-sm){
        margin-bottom: 40px;
      }
    }
    .story-text {
      @include font($font-default, #{max(14px, vw(15px))}, $weight-normal, normal, 1.2);
    }
  }
}