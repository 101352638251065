.shopping-cart-product-title {
    @include font($font-default, #{max(14px, vw(24px))}, $weight-light, normal, 1);
}

.shopping-cart {
    display: flex;
    flex-direction: column;

    &__block {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: max(30px, vw(50px));

        &>* {
            flex: 1;

            @media screen and (max-width: $tablet-sm) {
                &.shopping-cart__item-info {
                    max-width: 170px;
                }
            }
        }
    }

    &__image {
        max-width: max(60px, vw(80px));
    }

    &__item-info {
        margin-left: 10px;

        &--title {
            @extend .shopping-cart-product-title;
            margin-bottom: 5px;
        }

        &--details {
            @extend .shopping-cart-product-title;
            display: inline-flex;
            margin-bottom: 5px;
            font-size: 85%;
        }
    }

    &__quantity {
        @media screen and (max-width: $tablet-sm) {
            margin-left: auto;
            max-width: 160px;
        }

        &--wrap {
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: $tablet-sm) {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &--control {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 35px;

            &>* {
                button {
                    padding: 5px;
                }
            }
        }

        &--inputs {
            display: flex;
            margin-right: 10px;

            &>div {
                border-top: 1px solid $black;
                border-bottom: 1px solid $black;
                background-color: $white;
                line-height: 0;

                &:not(:nth-child(2)) {
                    padding-left: 16px;
                    padding-right: 16px;
                    display: flex;
                    border-right: 1px solid $black;
                    border-left: 1px solid $black;
                    cursor: pointer;
                }

                input {
                    background-color: transparent;
                    outline: unset;
                    border: none;
                    border-radius: unset;
                    box-shadow: none;
                    pointer-events: none;
                    text-align: center;
                }
            }
        }

        &--input {
            min-width: 70px;
        }

        &--refresh {
            button {
                background-color: $white;
                color: $black;
                border: unset;
                outline: none;

                &:hover {
                    background-color: $white;
                    border: unset;
                    color: $black;
                }
            }
        }

        @media screen and (max-width: $tablet-sm) {
            .shopping-cart__quantity--inputs {
                transform: scale(.6);
            }

            .shopping-cart__quantity--wrap {
                justify-content: flex-start;
            }

            .shopping-cart__quantity--refresh {
                position: absolute;
                top: 0;
                left: 125px;
                bottom: 0;
                transform: scale(.8);
            }

            .shopping-cart__remove-item {
                position: absolute;
                bottom: -17px;
                left: 52px;
            }
        }
    }

    &__remove-item {

        button {
            @include font($font-default, #{max(12px, vw(12px))}, $weight-thin, italic, 1);
            margin-bottom: 10px;
            text-decoration: underline;
            background-color: transparent;
            border: none;
            outline: unset;

            .icon--delete {
                display: inline-block;
                width: 15px;
                height: 15px;

                svg {
                    display: inline-block;
                    width: 15px;
                    height: 15px;

                    .st0 {
                        fill: none;
                        stroke: #000000;
                        stroke-width: 3;
                        stroke-linecap: round;
                        stroke-miterlimit: 10;
                    }
                }
            }
        }
    }

    &__total {
        display: flex;
        justify-content: center;
        border-top: 1px solid rgba(0, 0, 0, .25);
        padding-top: 30px;
        padding-bottom: 30px;

        &>div {
            @extend .shopping-cart-product-title;
            text-transform: uppercase;
        }
    }

    &__cta {}
}