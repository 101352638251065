.blog-container{
  padding: max(50px, vw(150px)) max(15px, vw(100px)) max(35px, vw(95px));
  width: 100%;
  @media screen and (max-width: $tablet){
    padding-left: 30px;
    padding-right: 30px;
  }
  @media screen and (max-width: $tablet-sm){
    padding-left: 15px;
    padding-right: 15px;
  }
  .blog{
    margin-bottom: unset;
    .blog_grid_holder{
      margin: unset;
      padding-top: unset;
    }
  }
  a{
    &:hover{
      color:unset;
    }
  }
  .blog-title{
    font-size: max(22px, vw(63px));
    font-weight: 100;
    text-align: center;
    margin-bottom: max(20px, vw(70px));
  }
  .blog_item{
    width: 33.333%;
    transition: all 0.5s;
    padding: 0 1px;
    margin-bottom: unset;
    @media screen and (max-width: $tablet-lg){
      width: 50%;
    }
    @media screen and (max-width: $mobile){
      width: 100%;
    }
    &:hover{
      scale: 0.85;
      @media screen and (max-width: $tablet-sm){
        scale: unset;
      }
      & .summary p{
        padding-right: 0;
      }
    }
    h2{
      font-size: max(22px, vw(63px));
      font-weight: 100;
      margin-bottom: max(15px, vw(30px));
    }
    p{
      font-size: 15px;
      padding-right: max(0px, vw(80px));
      @media screen and (max-width: $mobile){
        font-size: 13px;
        padding-right: 0;
      }
    }
  }
}