.listing__search {

    .select2-container--default {
        .select2-selection__rendered {
            @include font($font-default, #{max(15px, vw(15px))}, $weight-thin, normal, 1);
            min-height: 18px;
        }
    }
    .the-search {
        padding-left: max(15px, vw(50px));
        padding-right: max(15px, vw(50px));
		max-width: calc(50% + vw(50px));

        @media screen and (max-width: $tablet-sm) {
            flex-direction: column;
            align-items: flex-start;
			max-width: 100%;
        }

        &>* {
            display: flex;
            margin-bottom: 15px;
        }

        &--criteria {

            &>* {
                min-width: calc(50% - max(15px, vw(30px)));


                &:not(:last-child) {
                    margin-right: max(15px, vw(30px));
                }
            }
        }

        &--checkboxes {
            display: flex;
            flex-direction: column;

            @media screen and (max-width: $tablet-sm) {
                flex-direction: column;
                align-items: flex-start;
            }

            label {
                display: inline-flex;
                align-items: center;

                &:not(:last-child) {
                    margin-right: 30px;
                }
            }
        }

        &--cta {
            min-width: 50%;
            max-width: 50%;

            @media screen and (max-width: $tablet-sm) {
                min-width: 100%;
                max-width: 100%;                
            }
        }

        &--text {}
    }

    .view__selections {
        .selection-wrap {

            .view__selection {
                cursor: pointer;
                margin-left: 5px;

                &:hover,
                &.view--selected {
                    text-decoration: underline;
                }

                @media screen and (min-width: ($tablet + 1)) {
                    &:not(:nth-child(4), :nth-child(6)) {
                        display: none
                    }
                }

                @media screen and (max-width: $tablet) {
                    &:not(:nth-child(1), :nth-child(2), :nth-child(4)) {
                        display: none
                    }
                }
            }
        }

    }

    .category-listing {
        padding-left: max(15px, vw(50px));
        padding-right: max(15px, vw(50px));

        &--empty {
            display: flex;
            justify-content: center;
            text-align: center;
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }
}