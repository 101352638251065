$kTabs: '.kTabs';
$kTabs-padding: 12px;
$kTabs-trigger-current-color: black;
$kTabs-trigger-line-color: $kTabs-trigger-current-color;
$kTabs-mob-placeholder-arrow: url("data:image/svg+xml,%3Csvg viewBox='0 0 10.8 6.6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath style='fill:none;stroke:%23999;stroke-width:1.0006;stroke-linejoin:bevel;stroke-miterlimit:10' d='m10.4.4-5 5.9-5-6'/%3E%3C/svg%3E");
$kTabs-mob-placeholder-arrow-width: 12px;
$kTabs-mob-placeholder-arrow-height: 7px;
$block-padding: max(15px, vw(70px)) max(15px, vw(115px));
// mob
$kTabs-trigger-mob-bg: white;
$kTabs-trigger-mob-border-color : black;

#{$kTabs} {
    padding-top: $kTabs-padding;
    padding-bottom: $kTabs-padding;

    @media screen and (min-width: ($tablet + 1)) {
        padding: $block-padding;
    }

    @media screen and (max-width: $tablet) {
        width: max(300px, vw-rs($tablet, 375px));
        margin-left: auto;
        margin-right: auto;
        padding-left: $kTabs-padding;
        padding-right: $kTabs-padding;
    }

    &__decor {
        position: sticky;
        top: var(--header-height);
        z-index: 1;
        background-color: white;
    }

    &__wrap {

        @media screen and (min-width: ($tablet + 1)) {
            display: flex;
            align-items: flex-start;
        }
    }

    &__placeholder {
        @include font($font-default, #{max(12px, vw(12px))}, $weight-normal, normal, 1);

        @media screen and (min-width: ($tablet + 1)) {
            display: none;
        }

        &:after {
            @include transition($time: .5s);
            content: "";
            background-image: $kTabs-mob-placeholder-arrow;
            background-repeat: no-repeat;
            width: $kTabs-mob-placeholder-arrow-width;
            height: $kTabs-mob-placeholder-arrow-height;
            display: flex;
            position: absolute;
            right: 0;
            top: 0;
            transform: translateY(50%);

        }

        &#{$kTabs}__dropdown {
            &:after {
                transform: translateY(50%) rotate(180deg);
            }
        }
    }

    &__trigger {
        &--wrap {
            @include transition($time: .5s);
            @include font($font-default, #{max(12px, vw(12px))}, $weight-normal, normal, 1);
            display: flex;
            flex-direction: column;
            min-width: 275px;

            @media screen and (max-width: $tablet) {
                border-top: 1px solid;
                border-color: $kTabs-trigger-mob-border-color;
                flex-direction: column;
                position: absolute;
                left: 0;
                bottom: 0;
                transform: translateY(100%);
                width: 100%;
                background-color: $kTabs-trigger-mob-bg;
                z-index: 1;
                overflow: hidden;
                max-height: 0%;
                padding-left: $kTabs-padding;
                padding-right: $kTabs-padding;
                z-index: 2;

                &#{$kTabs}__dropdown {
                    bottom: 0;
                    padding-top: $kTabs-padding;
                    padding-bottom: $kTabs-padding;
                    max-height: 100vh;
                }
            }
        }

        &--item {
            &:last-child {
                display: none;
            }
            @include transition;
            cursor: pointer;
            margin-right: auto;

            &:not(:last-child) {
                margin-bottom: 15px;
            }

            &:before {
                @include transition;
                content: "";
                position: absolute;
                bottom: -5px;
                left: 0;
                right: 0;
                width: 0%;
                height: 1px;
                background-color: $kTabs-trigger-line-color;
                opacity: 0;
            }

            &:hover,
            &--current {
                color: $kTabs-trigger-current-color;

                &:before {
                    opacity: 1;
                    width: 100%;
                }
            }
        }
    }

    &__content {
        &--wrap {
            padding: $block-padding;

            @media screen and (min-width: ($tablet + 1)) {
                margin-left: vw(50px);
            }

            flex: auto;
        }

        &--item {
            width: 100%;
            text-align: justify;

            @media screen and (min-width: ($tablet + 1)) {
                text-align: unset;
                max-width: 560px;
            }

            min-height: 30vh;
        }
    }
}

// ? LEGAL PAGES
.legal-info {
    @for $index from 1 through 6 {
        h#{$index} {
            @include font($font-default, #{max(20px, vw(22px))}, $weight-medium, normal, 1);
            margin-bottom: max(20px, vw(25px));
        }
    }

    &>* {
        &:not(:first-child) {
            @for $index from 1 through 6 {
                h#{$index} {
                    margin-top: max(35px, vw(40px));
                }
            }
        }
    }

    p {
        text-align: justify;
        &:not(:last-child) {
            margin-bottom: max(25px, vw(30px));
        }

        &:last-child {
            margin-bottom: unset;
        }
    }

    ol,
    ul {
        margin-bottom: max(25px, vw(30px));
    }

    ol,
    ul {
        //padding-left: 0 !important;

        li {
            //color: $gray;
            margin-left: 22px;

            &.depth-control {
                list-style-type: none;
                margin-top: max(15px, vw(20px));
            }
        }
    }

    ul {
        li {
            list-style-type: initial;
        }
    }

    a {
        text-decoration: underline;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
        white-space: normal;
    }

    &>* {
        &:last-child {
            margin-bottom: unset;
        }
    }
}

ul {
    &.type--disc {
        li {
            margin-left: 22px;
            list-style-type: initial;
        }
    }
}
#privacy-policy-terms-conditions {
    display: none;
}
.modal-dialog {
    a {
        text-decoration: underline;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
        white-space: normal;
    }
    p {
        text-align: justify;
    }
}