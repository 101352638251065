/*========================================================*/
/*** MINI CART ***/
/*========================================================*/
body {
  .body-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $black;
    opacity: 0;
    transition: opacity 0.3s ease;
    // visibility: hidden;
    // pointer-events: none;
    @media screen and (max-width: $mobile) {
      transition: opacity 0.8s ease;        
    }
  }
  .header-cover {
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    position: absolute;
    top: 0;
    left: 0;
    bottom: -1px;
    right: 0;
    background-color: $black;
    display: none;
    @media (max-width: $tablet) {
      display: block;
    }
  }
  &.search-is-open {
    overflow: hidden;
  }
  &.cart-is-open,
  &.menu-is-open {
    overflow: hidden;
    @media (hover: hover) {
      padding-right: 17px;
    }
    .body-cover {
      z-index: 99;
      opacity: 0.7;
      visibility: visible;
      transition: opacity 0.3s ease;
      pointer-events: unset;
    }
  }
  &.cart-is-open {
    .header {
      &--bottom {
        pointer-events: none;
      }

      &--upper, &--bottom {
        &::after {
          display: inline-block;
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: #000;
          opacity: 0.7;
        }
      }

       &--upper {
        &::after {
          z-index: -1;
        }
      }

    }
  }
  &.size-guide-is-open {
    overflow: hidden;
    .body-cover {
      z-index: 999;
      opacity: 0.7;
      visibility: visible;
      transition: opacity 0.3s ease;
    }
  }
  &.cart-is-open {
    .header-cover {
      opacity: 0.7;
      visibility: visible;
    }
  }
}

#cart {
  .minicart-body {
    position: fixed;
    padding: 40px 30px 30px 15px;
    background-color: $beige;
    border: none;
    min-width: 540px;
    top: 0;
    //top: var(--header--upper);
    right: -100%;
    @include transition;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    height: var(--screen-height);
    //height: calc(var(--screen-height) - var(--header--upper));
    margin: 0;
    &.open {
      right: 0;
    }
    @media (max-width: $tablet-sm) {
      padding: 15px;
      min-width: auto;
      width: 100%;
      overflow-y: auto;
    }
    .mini-cart-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      .mini-cart-title {
        @include font($font-default, #{max(32px, vw(32px))}, $weight-thin, normal, 1);
        color: #333333;
        @media (max-width: $tablet-sm) {
         font-size: 22px;
        }
      }
      a.close-mini-cart-btn {
        cursor: pointer;
        display: flex;
      }
    }
    .all-products-w-button {
      position: relative;
      height: calc(var(--screen-height) - 177px);
      @media (max-width: $tablet-sm){
        height: calc(var(--screen-height) - 137px);
      }
      .scrollable-products {
        overflow-y: auto;
        overflow-x: hidden;
        height: auto;
        max-height: calc(var(--screen-height) - 325px);
        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          @include prefix((box-shadow: inset 0 0 6px transparent), webkit moz);
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
          @include prefix((box-shadow: inset 0 0 6px $gray-light), webkit moz);
          background-color: $gray-light;
          border-radius: 4px;
        }
        .text-center {
          padding-top: 35px;
          @include font($font-default, #{max(16px, vw(16px))}, $weight-thin, normal, 1);
        }

        .mini-cart-products {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            &:not(:last-child) {
              padding-bottom: 25px;
            }

            .product-img {
              margin-right: 20px;
              max-width: max(115px, vw(164px));
              @media (max-width: $mobile){
                margin-right: 15px;
              }
              a {
                display: inline-block;
                .img-thumbnail {
                  padding: 0;
                  border-radius: 0;
                  border: none;
                }
              }
            }
            .prd-item {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              width: 100%;
              max-width: 215px;
              @media (max-width: $mobile){
                max-width: 160px;
              }
              .prd-item-info {
                width: 100%;
                max-width: 170px;
                margin-right: 10px;
                @media (max-width: $tablet-sm) {
                  max-width: 155px;
                }
                @media (max-width: $mobile-sm) {
                  max-width: 110px;
                }
                .prd-name {
                  @include font($font-default, #{max(15px, vw(15px))}, $weight-medium, normal, 1);
                  color:#333333;
                  text-transform: uppercase;
                  &:hover, &:focus {
                    text-decoration: none;
                  }
                  @media (max-width: $mobile){
                    font-size: 11px;
                  }
                }
                .prd-options {
                  display: flex;
                  margin-bottom: 5px;
                  li {
                    margin: 0!important;
                    @include font($font-default, #{max(11px, vw(11px))}, $weight-thin, normal, 1);
                    &:not(:last-child) {
                      &::after {
                        content: '-';
                        display: inline-block;
                        @include font($font-default, #{max(11px, vw(11px))}, $weight-thin, normal, 1);
                        margin: 0 5px;
                      }
                    }
                  }
                }
                .prd-price {
                  @include font($font-default, #{max(15px, vw(15px))}, $weight-normal, normal, 1);
                  color:#333333;
                  margin-bottom: 20px;
                  @media (max-width: $mobile){
                    font-size: 11px;
                  }
                }
              }
            }

            .remove-cart-item {
              border: none;
              background-color: transparent;
              margin-top: 5px;
              @include font($font-default,  #{max(12px, vw(12px))}, $weight-thin, italic, 1);
              color:#333333;
              padding: 0;
              &:after {
                content: "";
                display: inline-block;
                width: 100%;
                height: 1px;
                background-color: #333333;
                position: absolute;
                bottom: -2px;
                left: 0;
                -webkit-transition: all 0.3s ease-in-out;
                -moz-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
              }
              @media (max-width: $mobile) {
                font-size: 10px;
              }
            }
          }
      }
      .mini-cart-total {
        width: 100%;
        .col-total {
          @include font($font-default,  #{max(22px, vw(25px))}, $weight-thin, normal, 1);
          color: #333333;
          margin-top: 40px;
          &:nth-child(2) {
            display: none;
          }
        }
      }
      .mobile-bottom-cart {
        position: absolute;
        bottom: 0;
        width: 100%;
        .mini-cart-checkout {
          display: flex;
          justify-content: center;
          a {
            width: 100%;
            max-width: 200px;
            border: 1px solid #333333;
            color: #333333;
            text-align: center;
            padding: 15px;
            @include font($font-default, #{max(23px, vw(33px))}, $weight-light, normal, 1);
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.close-btn-icon  {
  display: inline-block;
  width: 35px;
  height: 35px;
  @media (max-width: $mobile){
    width: 30px;
    height: 30px;
  }
  svg {
    display: inline-block;
    width: 35px;
    height: 35px;
    @media (max-width: $mobile){
      width: 30px;
      height: 30px;
    }
    .st0 {
      stroke: #333333;
    }
  }
}

.minicart-quantity-input-field {
  width: 100%;
  max-width: 120px;
  @media (max-width: $mobile) {
    max-width: 95px;
  }
  .minicart-quantity {
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    .number-input {
      position: relative;
    }
    input[type="text"] {
      background-color: transparent;
      @include font($font-default, #{max(15px, vw(22px))}, $weight-thin, normal, 1);
      padding: 0;
      color: $black;
      text-align: center;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      border-radius: 0;
      min-height: 23px;
    }
    .minicart-quantity-nav {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .quantity-button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border-left: 1px solid $black;
        border-right: 1px solid $black;
        cursor: pointer;
        &.quantity-down {
          &:after {
            content: '';
            background-image: url('../../../assets/icons/minus-icon.svg');
            width: 13px;
            height: 13px;
            background-position: center;
            background-repeat: no-repeat;
            @media (max-width: $mobile) {
              width: 10px;
              height: 10px;
            }
          }
        }
        &.quantity-up {
          &:after {
            content: '';
            background-image: url('../../../assets/icons/plus-icon.svg');
            width: 13px;
            height: 13px;
            background-position: center;
            background-repeat: no-repeat;
            @media (max-width: $mobile) {
              width: 10px;
              height: 10px;
            }
          }
        }
      }
    }
  }
}