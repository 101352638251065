// * RESET
*,
*::before,
*::after {
    position: relative;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

*:visited,
*:focus,
*:active {
    outline: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

::-moz-selection {
    color: $interaction-text-color;
    background: $interaction-background-color;
}

::selection {
    color: $interaction-text-color;
    background: $interaction-background-color;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

html,
body {
    height: auto;
    width: 100%;
}

body {
    background-color: $white;
    color: $body-text-color;
    font-family: $font-default;
    font-size: 1.6rem;
    line-height: 1.5;
    margin: 0;
    .header--wrapper {
        background-color: $white;
    }
    #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu {
        background-color: $white;
    }
    &.page_story{
        background-color: #d8d8d8;
        .header--wrapper{
            background-color: #d8d8d8;
        }
        #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu {
            background-color: #d8d8d8;
        }
    }
    &.home, &.page_blog, &.page_careers, &.page_team, &.page_store, &.page_stocklist, &.page_previous_collection {
        background-color: $beige;
        .header--wrapper {
            background-color: $beige;
        }
        #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu {
            background-color: $beige;
        }

    }
    &.page_blog{
        &.page_home {
            background-color: $white;
            .header--wrapper {
                background-color: $white;
            }
            #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu {
                background-color: $white;
            }
        }
    }
}

a{
    &:hover,
    &:visited,
    &:focus{
        color:unset;
    }
}



main {
    display: flex;
    flex-direction: column;
}

@for $index from 1 through 6 {
    h#{$index} {
        margin: unset;
    }
}

p {
    margin: unset;
    margin-bottom: 15px;
}

a {
    color: inherit;
    outline: none;
    text-decoration: none;
    cursor: pointer;

    &:visited,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
    }
}

b,
strong {
    font-weight: bold;
}

ul {
    margin: 0;
    padding: 0;

    li {
        list-style-type: none;
    }
}

img,
picture {
    border: none;
    height: auto;
    vertical-align: middle;
    width: 100%;
    max-width: 100%;
}

svg {
    display: inline-block;
}

.post,
.page {
    margin: 0;
}

// * RESET END