.jobs-container {
  background-color: #e7d6c8;
  padding: max(50px, vw(135px)) max(30px, vw(180px)) max(60px, vw(115px));
  .jobs-title{
    text-align: center;
    @include font($font-default, #{max(22px, vw(63px))}, $weight-thin, normal, 1);
    margin-bottom: max(25px, vw(60px));
    @media screen and (max-width: $tablet){
      font-weight: 300;
    }
  }
  .jobs-text-desc{
    text-align: center;
    width: 100%;
    max-width: max(290px, vw(570px));
    margin: 0 auto 4.167vw auto;
    @include font($font-default, #{max(15px, vw(15px))}, $weight-light, normal, 1);
    @media screen and (max-width: $tablet){
      font-size: 13px;
      line-height: 16px;
    }
  }
  .job {
    width: 100%;
    max-width: vw(300px);
    padding: vw(50px) 0;

    @media screen and (max-width: $tablet) {
      max-width: 40%;
    }

    @media screen and (max-width: $tablet-sm) {
      margin-bottom: 20px;
      max-width: 300px;
    }

    @media screen and (min-width: ($tablet + 1)) {
      &:nth-child(3n+1),
      &:nth-child(3n+2) {
        margin-right: vw(300px);

        &:after {
          content: "";
          display: flex;
          width: 1px;
          height: 100%;
          background-color: $gray-light;
          position: absolute;
          right: vw(-150px);
          top: 0;
          transform: rotate(var(--line-rotation));

          @media screen and (max-width: 1200px) {
            transform: rotate(calc(var(--line-rotation) + 10deg));
          }
        }
      }
    }

    @media screen and (min-width: ($tablet-sm + 1)) and (max-width: $tablet) {
      &:nth-child(2n+1) {
        &:after {
          content: "";
          display: flex;
          width: 1px;
          height: 100%;
          background-color: $gray-light;
          position: absolute;
          right: vw(-150px);
          top: 0;
          transform: rotate(calc(var(--line-rotation) + 10deg));
        }
      }
    }

    &:last-child {
      margin-right: unset  ;
      &:after {
        content: unset;
      }
    }

    &--wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: vw(1500px);
      margin: auto;

      @media screen and (max-width: $tablet) {
        justify-content: space-between;
      }

      @media screen and (max-width: $tablet-sm) {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }

    &--heading {
      margin-bottom: max(10px, vw(25px))
    }

    &--title {
      @include font($font-default, #{max(15px, vw(28px))}, $weight-thin, normal, 1);
    }

    &--content {
      overflow-wrap: break-word;
      @media screen and (max-width: $tablet-sm) {
        text-align: center;
      }
    }

    &--description {
      margin-bottom: max(15px, vw(30px));
      text-align: justify;
      line-height: 18px;

      p {
        @extend .text--normal;
      }

      @media screen and (max-width: $tablet-sm) {
        text-align: center;
      }
    }

    &--cta {
      @include font($font-default, #{max(13px, vw(22px))}, $weight-thin, normal, 1);
    }
  }
  .job-more-container {
    text-align: center;
    margin-top: max(30px, vw(90px));
    margin-bottom: max(60px, vw(115px));
    .job-more-btn {
      background-color: unset;
      border: none;
    }
  }
}