/*========================================================*/
/* Header style_m */
/*========================================================*/
.header {
  &--wrapper {
    align-items: flex-start;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
  }
  &--upper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $gray;
    padding: 15px 30px 15px 0;
    z-index: 99;
    @media (max-width: $tablet-sm) {
      padding: 15px 15px 15px 0;
    }
  }
  &--bottom {
    z-index: 98;
  }
  &--promo {
    //overflow: hidden;
    @include font($font-default, #{max(18px, vw(18px))}, $weight-light, normal, 1);
    margin: 0;
    @media (max-width: $tablet-sm) {
      font-size: 12px;
    }
  }
  &--account__items {
    display: flex;
    align-items: center;
    li {
      max-height: 20px;
      &:not(:last-child) {
        margin-right: 15px;
        @media (max-width: $tablet-sm) {
          margin-right: 10px;
        }
      }
      a {
        display: inline-block;
      }
    }
  }
  &--icon {
    display: inline-block;
    width: auto;
    height: 18px;
    &.wishlist-icon {
      svg {
        g {
          path {
            &:first-child {
              -webkit-transition: all 0.3s ease-in-out;
              -moz-transition: all 0.3s ease-in-out;
              -o-transition: all 0.3s ease-in-out;
              transition: all 0.3s ease-in-out;
              fill: transparent;
            }
          }
        }
      }
      &.has-wishlist-items {
        svg {
          g {
            path {
              &:first-child {
                fill: unset;
              }
            }
          }
        }
      }
    }
    @media (max-width: $tablet-sm) {
      height: 17px;
    }
    svg {
      display: inline-block;
      width: auto;
      height: 18px;
      .st0{
        fill: $gray-dark;
      }
      @media (max-width: $tablet-sm) {
        height: 17px;
      }
    }
    &.wishlist-icon {
      .st0{
        fill:none;
        stroke:$gray-dark;
        stroke-width:0.2835;
        stroke-miterlimit:10;
      }
    }
  }
  &--logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 30px;
    @media (max-width: $tablet-sm) {
      padding: 15px;
    }
    .logo {
      z-index: 99;
      display: inline-block;
      width: 177px;
      height: 55px;
      @media (max-width: $tablet-sm) {
        width: 135px;
        height: auto;
      }
      svg {
        display: inline-block;
        width: 177px;
        height: 55px;
        @media (max-width: $tablet-sm) {
          width: 135px;
          height: auto;
        }
        .logo-color {
          fill: #000;
        }
      }
      &.white-logo {
        svg {
          .logo-color {
            fill: #fff;
          }
        }
      }
      &.dark-logo {
        svg {
          .logo-color {
            fill: #000;
          }
        }
      }
    }
  }
}

#cart {
  width: 43px;
  height: 20px;
  position: relative;
  .cart-btn  {
    background-color: transparent;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    height: 20px;
    padding: 0;
    display: flex;
    position: relative;
    top: -3px;
    .cart-icon {
      display: inline-block;
      width: 23px;
      height: 18px;
      position: relative;
      background-image: url('../../../icons/cart-icon.svg');
      background-repeat: no-repeat;
      background-size: auto;
    }
    #cart-total {
      width: 20px;
      height: 20px;
      @include font($font-default, #{max(12px, vw(12px))}, $weight-light, normal, 1);
    }
  }
}

.header-search {
  @media (max-width: 1200px) {
    display: inline-block;
    margin-left: 20px;
  }
  @media (max-width: $mobile-sm) {
    margin-left: 10px;
  }
  .open-search-bar {
    &.active {
      svg {
        .st0 {
          fill: transparent;
        }
      }
    }
    .search-icon {
      display: block;
    }
  }
}
.open-search-input {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: calc(100vh - var(--header--upper));
  left: 0;
  top: var(--header--upper);
  //background: #808080;
  background: rgba(80, 80, 80, 0.8);
  z-index: 999;
  -webkit-transition: visibility 0.3s ease, opacity 0.3s ease;
  -moz-transition: visibility 0.3s ease, opacity 0.3s ease;
  -o-transition: visibility 0.3s ease, opacity 0.3s ease;
  transition: visibility 0.3s ease, opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  &.active {
    visibility: visible;
    opacity: 1;
  }
  .close-search {
    position: absolute;
    top: 15px;
    right: 40px;
    z-index: 9999;
    @media (max-width: $tablet-sm) {
      right: 25px;
    }
    .close-btn-icon {
      cursor: pointer;
      svg {
        .st0 {
          stroke: $white;
        }
      }
    }
  }
  .open-search-input-wrapper {
    width: 100%;
    max-width: 475px;
    margin: 0 auto;
    padding: max(200px, vw(200px)) 15px;
    .search-btn {
      z-index: 9;
      padding: max(15px, vw(35px)) max(10px, vw(15px));
      background-color: transparent;
      border: none;
      position: absolute;
      right: 0;
      top: 50%;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      .search-icon {
        display: flex;
        width: max(25px, vw(45px));
        height: max(25px, vw(45px));
        svg {
          display: flex;
          width: max(25px, vw(45px));
          height: max(25px, vw(45px));
          .st0 {
            fill: #fff;
          }
        }
      }
    }
  }
  #search {
    width: 100%;
    position: relative;
    input[type="search"] {
      position: relative;
      @include inputStracture(0, solid, 0, 35px 15px);
      @include inputStyle(transparent, $white, $white, $white, $error-color: $error-color);
      @include font($font-default, #{max(26px, vw(36px))}, $weight-thin, normal, 1);
      padding: max(15px, vw(35px)) max(10px, vw(15px));
      height: unset;
      -webkit-box-shadow: unset;
      box-shadow: unset;
      border-bottom: 2px solid $white;
      text-align: center;
      &::-webkit-input-placeholder {/* Chrome/Opera/Safari/Edge */
        @include font($font-default, #{max(26px, vw(36px))}, $weight-thin, normal, 1);
        color: $white;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        @include font($font-default, #{max(26px, vw(36px))}, $weight-thin, normal, 1);
        color: $white;
      }

      &:-ms-input-placeholder {/* IE 10+ */
        @include font($font-default, #{max(26px, vw(36px))}, $weight-thin, normal, 1);
        color: $white;
      }
    }
    #search-autocomplite {
      padding: 35px 15px;
      height: unset;
      position: absolute;
      top: 0;
      @media (max-width: $tablet-sm) {
        padding: 20px 10px;
      }
    }
    #d_ajax_search_results {
      max-height: max(270px, vw(350px));
      overflow-y: auto;
      position: absolute;
      top: 100%;
      width: 100%;
      margin: 0;
      background-color: transparent;
      padding: 10px;
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        @include prefix((box-shadow: inset 0 0 6px transparent), webkit moz);
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        @include prefix((box-shadow: inset 0 0 6px $gray-light), webkit moz);
        background-color: $gray-light;
        border-radius: 4px;
      }
      .d_ajax_search_results_body {
        max-height: max(270px, vw(350px));
        overflow-y: auto;
      }
      .all_results {
        padding: 5px 0;
        @include font($font-default, #{max(16px, vw(20px))}, $weight-light, normal, 1);
        &:hover, &:focus {
          color: $white;
        }
      }
      a.result-link.sort-item {
        display: flex;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        &:hover {
          color:$white;
        }
        .search-item {
          width: 100%;
          display: flex;
          flex-direction: row;
          .search-item-category {
            flex-basis: 50%;
            p{
              margin-bottom: 0;
              @include font($font-default, #{max(20px, vw(40px))}, $weight-light, normal, 1);
            }
          }
          .search-item-image {
            flex-basis: 20%;
            max-width: 70px;
            margin-right: 8px;
          }
          .search-item-info {
            flex-basis: 30%;
            width: 100%;
            max-width: 90px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            word-wrap: break-word;
            .search-item-name {
              @include font($font-default, #{max(12px, vw(12px))}, $weight-light, normal, 1);
            }
            .search-item-price {
              @include font($font-default, #{max(12px, vw(12px))}, $weight-light, normal, 1);
            }
          }
        }
      }
      .no-results {
        text-transform: uppercase;
        @include font($font-default, #{max(14px, vw(14px))}, $weight-normal, normal, 1);
        color: $white;
        i.fa.fa-exclamation-circle {
          display: none;
        }
      }
    }
  }
}


