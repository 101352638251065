.bridal-container {
  padding: max(35px, vw(130px)) max(0px, vw(105px)) 0px;
  @media screen and (max-width: $tablet) {
    padding: 15px 30px 0px;
  }
  @media screen and (max-width: $tablet-sm) {
    padding: 15px 0 0 0;
  }
  .bridal-title {
    font-weight: 100;
    font-size: max(22px, vw(63px));
    text-align: center;
    margin-bottom: vw(38px);
  }
  .bridal-intro-text{
    width: 100%;
    max-width: max(300px, vw(830px));
    margin: 0 auto max(15px, vw(75px));
    text-align: center;
    font-size: 15px;
    line-height: 18px;
    font-weight: 100;
    @media screen and (max-width: $mobile) {
      font-size: 13px;
      margin: 0 auto;
    }
  }
  .bridal-video-container {
    margin-bottom: max(15px, vw(100px));
    @media screen and (max-width: $mobile) {
      margin-bottom: 15px;
    }
    video {
      width: 100%;
    }
  }
  .bridal-photo-container {
    display: flex;
    gap: 3px;
    margin-bottom: max(35px, vw(85px));

    &.margin-none{
      margin-bottom: 0;
    }

    img {
      width: calc((100% - 9px) / 4);
    }
  }
  .slider-wrap {
    @media screen and (max-width: $tablet-sm){
      padding: 0 3px;
      margin-bottom: 35px;
    }
  }
}