.single-job-container {
  background-color: #e7d6c8;
  padding: max(55px, vw(115px)) max(45px, vw(100px)) max(35px, vw(385px));
  @media screen and (max-width: $tablet-sm){
    padding:50px 45px 35px;
  }
  .single-job-desc-title{
    text-align: center;
    font-weight: 400;
    font-size: max(20px, vw(30px));
    margin-bottom: max(30px, vw(195px));
    position: relative;
    //.single-job-go-back-btn{
    //  position: absolute;
    //  left: 0;
    //  bottom: 0;
    //  font-size: 13px;
    //  text-decoration: underline;
    //  color: #4d4d4d;
    //  background-color: unset;
    //  border: none;
    //  @media screen and (max-width: $tablet-sm){
    //    font-size: 7px;
    //  }
    //}
  }
  .single-job-inner-container {
    display: flex;
    justify-content: space-between;
    max-width: 1720px;
    margin: 0 auto;
    @media screen and (max-width: $tablet-sm){
      flex-direction: column;
    }
    &>div {
      width: calc(100% / 3);
      max-width: 335px;
      @media screen and (max-width: $tablet-sm){
        width: 100%;
        max-width: unset;
      }
    }
    .single-job-desc-container{
      @media screen and (max-width: $tablet-sm){
        text-align: center;
      }
      .single-job-overview{
        font-size: max(13px, vw(30px));
        font-weight: 400;
        margin-bottom: 30px;
        @media screen and (max-width: $tablet-sm){
          text-align: center;
          margin-bottom: 15px;
        }
      }
      .single-job-text{
        p {
          @include font($font-default, #{max(14px, vw(24px))}, $weight-light, normal, 1);
          text-align: justify;
        }
      }
    }
    .single-job-req-container {
      text-align: center;
      @media screen and (max-width: $tablet-sm){
        margin-bottom: 20px;
      }
      .single-job-req-title{
        font-size: max(13px, vw(30px));
        font-weight: 400;
        margin-bottom: 30px;
        @media screen and (max-width: $tablet-sm){
          margin-bottom: 20px;
          text-align: center;
        }
      }
      ul {
        font-size: max(13px, vw(24px));
        margin-left: 15px;
        font-weight: 300;
        &:not(:last-child) {
          margin-bottom: 40px;
          @media screen and (max-width: $tablet-sm){
            margin-bottom: 20px;
          }
        }
        @media screen and (max-width: $tablet-sm){
          margin-left: unset;
          padding-left: 30vw;
        }
        @media screen and (max-width: $mobile-sm){
          padding-left: 20vw;
        }
        @media screen and (max-width: 230px){
          padding-left: unset;
        }
        li{
          text-align: justify;
          @media screen and (max-width: $tablet-sm){
            font-size: 13px;
          }
          span{
            width: 11px;
            height: 11px;
            margin-right: 10px;
            @media screen and (max-width: $tablet-sm){
              width: 7px;
              height: 7px;
              margin-right: 10px;
            }
            img{
              max-width: unset;
              width: 11px;
              height: 11px;
              @media screen and (max-width: $tablet-sm){
                max-width: unset;
                width: 7px;
                height: 7px;
              }
            }
          }
        }
      }
    }
    .single-job-contact-title{
      font-size: max(13px, vw(30px));
      font-weight: 400;
      margin-bottom: 30px;
      @media screen and (max-width: $tablet-sm){
        text-align: center;
        margin-bottom: 20px;
      }
    }
    .single-job-mail{
      font-size: max(13px, vw(24px));
      overflow-wrap: break-word;
      font-weight: 300;
      @media screen and (max-width: $tablet-sm){
        font-size: 13px;
        text-align: center;
      }
    }
  }
}
