.not-found-container{
  padding: max(75px, vw(150px)) max(30px, vw(375px));
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $tablet-sm){
    padding: 75px 15px;
  }
  //h3{
  //  font-size: 20px;
  //  font-weight: 300;
  //  margin-bottom: vw(45px);
  //  @media screen and (max-width: $tablet-sm){
  //    font-size: 18px;
  //    margin-bottom: 30px;
  //  }
  //}
  h1{
    font-size: vw(70px);
    font-weight: 400;
    margin-bottom: vw(60px);
    @media screen and (max-width: $tablet-sm){
      font-size: 30px;
      margin-bottom: 40px;
    }
  }
  //h4{
  //  font-size: 16px;
  //  font-weight: 500;
  //  margin-bottom: vw(55px);
  //  @media screen and (max-width: $tablet-sm){
  //    font-size: 14px;
  //    margin-bottom: 40px;
  //  }
  //}
  h2{
    font-size: 32px;
    font-weight: 100;
    margin-bottom: 50px;
    @media screen and (max-width: $tablet-sm){
      font-size: 26px;
      margin-bottom: 35px;
    }
  }
  a{
    border-radius: 50px;
    color: #fff;
  }
}