$umbrella: '#quickcheckoutconfirm';
$checkout-bp: 991px;
$block-theming: $white;
$block-theming-rotation: $white;
$blue: null;
$secondary_font: null;
$checkout-base-color: var(--black);
$field-space: max(30px, vw(30px));
$label-display: unset;
$column-gap: vw(45px);
$checkout-width: 1640px;
$checkout-side-padding: max(10px, vw(80px));
$checkout-bottom-padding: max(30px, vw(50px));
$well-type-border-radius: 45px;
$well-type-padding: 20px;
$border-colors: $gray-dark;

#banner-easy-returns {
    display: none;
    margin-bottom: 0;
}

// CHECKOUT TYPOGRAPHY
.checkout-main-title {
    @include font($font-default, #{max(20px, vw(24px))}, $weight-light, normal, 1);
}

.customer-types {
    @include font($font-default, #{max(14px, vw(24px))}, $weight-light, normal, 1);
    text-transform: uppercase;
}

.invoice-types {
    @include font($font-default, #{max(14px, vw(16px))}, $weight-light, normal, 1);
    text-transform: none;
}

.cart-section-title {
    @include font($font-default, #{max(14px, vw(36px))}, $weight-light, normal, 1);
}

.cart-product-title {
    @include font($font-default, #{max(14px, vw(24px))}, $weight-light, normal, 1);
    text-transform: uppercase;
}

.checkbox-radio-inputs {
    @include font($font-default, #{max(13px, vw(13px))}, $weight-light, italic, 1);
    text-transform: none;
}

.popup-login-title {
    @include font($font-default, #{max(30px, vw(36px))}, $weight-light, normal, 1);
}

#button-payment-method {    
    @include font($font-default, #{max(20px, vw(36px))}, $weight-light, normal, 1);
}
// CHECKOUT TYPOGRAPHY END

.checkout-hidden {
    display: none !important;
}

.popup-login-close {
    opacity: .2;

    &:focus,
    &:hover {
        cursor: pointer;
        opacity: .5;
    }
}

// EXTENDABLE CLASSES END

#{$umbrella} {
    .checkout {
        &__title {
            @extend .cart-section-title;
            margin-top: max(20px, vw(40px));
            margin-bottom: max(20px, vw(25px));

            &--no-top-space {
                margin-top: unset;
            }
        }
    }
}

#{$umbrella} {
    color: $checkout-base-color;
    max-width: $checkout-width;
    padding-left: $checkout-side-padding;
    padding-right: $checkout-side-padding;
    padding-bottom: $checkout-bottom-padding;
    margin: auto;



    .checkout__main-title {
        @extend .checkout-main-title;
        width: 100%;
        margin-bottom: max(20px, vw(40px));
        
        @media screen and (max-width: $tablet-sm) {
            text-align: center;
        }
    }

    .checkout__wrap {
        display: flex;

        @media screen and (max-width:$checkout-bp) {
            flex-direction: column;
        }

        &>* {
            width: 50%;

            @media screen and (max-width:$checkout-bp) {
                width: 100%;
            }
        }

        &--left-side {
            background-color: $white;

            @media screen and (min-width: ($checkout-bp + 1)) {
                margin-right: $column-gap;
            }

            @media screen and (max-width: $checkout-bp) {
                background-color: $block-theming;
            }

            &__content--wrap {
                @media screen and (min-width: ($checkout-bp + 1)) {
                    padding-bottom: 60px;
                }

                // &>.shipping,
                // &>.payment,
                // &>#terms,
                // &>.address {
                //     &>* {
                //         &:not(.address__type) {
                //             padding-left: 25px;
                //             padding-right: 25px;

                //             @media screen and (max-width: $checkout-bp) {
                //                 padding-left: 0;
                //                 padding-right: 0;
                //             }
                //         }
                //     }
                // }

                .payment {

                    &__wrap {
                        margin-top: 15px;
                    }
                }
            }
        }

        &--right-side {
            @media screen and (min-width: ($checkout-bp + 1)) {
                padding-left: 25px;
                padding-right: 25px;
            }

            @media screen and (max-width: $checkout-bp) {
                padding-top: 25px;
                padding-bottom: 25px;
            }

            @media screen and (min-width: ($checkout-bp + 1)) {
                margin-left: $column-gap;
            }

            &__content--wrap {
                @media screen and (min-width: ($checkout-bp + 1)) {
                    padding-bottom: 60px;
                    max-width: 600px;
                    margin-left: auto;
                }
            }
        }

        .address {
            &__type {
                display: flex;

                @media screen and (max-width: $tablet-sm) {
                    text-align: center;
                }

                // @media screen and (max-width: $checkout-bp) {
                //     flex-direction: column;
                // }

                &>* {
                    @extend .customer-types;
                    background-color: $block-theming-rotation;
                    cursor: pointer;
                    flex: 1;
                    padding: 0;
                    margin-right: 15px;

                    @media screen and (max-width: $checkout-bp) {
                        margin-bottom: 15px;
                    }

                }

                &--active {
                    background-color: $white;
                    text-decoration: underline;

                    @media screen and (max-width: $checkout-bp) {
                        background-color: $block-theming;
                    }
                }

                &--visitor {}

                &--register {}

                &--login {}
            }

            &__payment {}

            &__customer {}

            &__shipping {}

            &__customer-type {
                &--wrap {
                    margin-bottom: 15px;

                    label {
                        @extend .invoice-types;
                    }
                }
            }

            &__acknowledge {}

            &__title {
                @extend .checkout__title;
            }

            &__content {}
        }

        .terms {
            &__acknowledge {}

            &__wrap {
                background-color: red;
            }

            a {
                color: $primary_color;
            }
        }

        .payment,
        .shipping {
            // &__wrap {
            //     &:before {
            //         content: "";
            //         border-bottom: 1px solid #ececec;
            //         width: 100%;
            //         display: flex;
            //         padding-top: 20px;
            //     }
            // }

            &__title {
                @extend .checkout__title;
            }
        }
    }

    label {
        a {
            font-weight: bold;
            text-decoration: underline;
        }
    }

    .option {
        &__wrap {
            &:not(:last-child) {
                margin-bottom: 10px;
            }

            &__heading {
                display: none;
            }

            &__content {}
        }
    }

    [type="button"] {
        // @extend .btn,
        // .btn-default;
        width: 100%;
        background-color: $black;
        padding-top: max(10px, vw(20px));
        padding-bottom: max(10px, vw(20px));

        &:hover {
            color: $black !important;
            background-color: transparent !important;
            border-color: $black !important;
        }
    }

    .radio__wrap,
    .checkbox__wrap {
        display: flex;
        align-items: center;

        [type="radio"],
        [type="checkbox"] {
            margin-right: 10px;
        }
    }

    .checkbox__wrap {
        line-height: 1;
        margin-bottom: 10px;
        width: 100%;

        span {
            cursor: pointer;
        }

        &--show-passwords {
            display: none;
        }

        &--create-account {}

        &--shipping-address {}

        &--newsletter {}

        &--agree {
            @media screen and (max-width: 600px) {
                width: 100%;
                margin-bottom: 15px !important;
                justify-content: center;
            }
        }
    }

    .radio__wrap {

        span,
        input {
            cursor: pointer;
        }
    }

    .field__wrap {
        display: inline-flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }

        &--input {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            width: calc(50% - calc($field-space / 2));

            &-control {
                width: 100%;
            }

            @media screen and (max-width: 768px) {
                margin-right: 0 !important;
                width: 100%;
            }

            label {
                display: $label-display;
            }
        }
    }
}

.checkout__cart {
    padding-top: 15px;
    padding-bottom: 15px;

    &__head,
    .checkout__cart__content--product {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid $border-colors;
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: $block-theming;

        &>* {
            width: 20%;

            @media screen and (max-width: 600px) {
                width: 18%;
                font-size: 1.1rem;
            }
        }

        .checkout__cart--image {
            width: 12%;
            min-width: max(60px, vw(80px));
        }

        .checkout__cart--name {
            width: 40%;
            margin-right: auto;

            @media screen and (max-width: $tablet-sm) {
                word-break: break-word;
            }

            small {
                font-size: 85% !important;
            }
        }

        .checkout__cart--quantity {
            text-align: center;
        }

        .checkout__cart--price {
            display: none;
        }

        .checkout__cart--total {
            text-align: right;
        }
    }

    &__head {
        padding-top: 8px;
        padding-bottom: 18px;

        .checkout__cart--image {
            opacity: 0;
        }
    }

    &__content {

        &--product {}



        .checkout__cart--name {
            text-decoration: none;
            font-size: 1.4rem;
            color: $black;

            &>* {
                &:first-child {
                    @extend .cart-product-title;
                }
            }

            &--giftwrap {
                margin-top: 10px;

                .checkbox__wrap {
                    font-size: 1.2rem;
                    margin-bottom: unset;

                    span {
                        margin-bottom: 3px;
                    }
                }
            }
        }
    }

    &__output {
        &--totals {
            padding-top: max(10px, vw(30px));
            padding-bottom: max(10px, vw(30px));
            display: flex;
            justify-content: space-between;

            span {
                font-size: 1.4rem;

                &:first-child {
                    font-weight: 500;
                }
            }

            &:first-child {
                margin-top: 15px;
            }

            &:last-child {
                border-top: 1px solid $border-colors;
                border-bottom: 1px solid $border-colors;
                margin-top: 15px;

                span {
                    font-size: 1.5rem;
                    font-weight: 500;
                    text-transform: uppercase;
                }
            }
        }
    }

    &--image {
        img {
            mix-blend-mode: multiply;
            border: none;
            width: 100%;
            max-width: 80px;
        }
    }

    &--name {
        @media screen and (max-width:600px) {
            .checkbox__wrap {
                font-size: 1.1rem;
            }
        }
    }

    &--quantity {
        &__wrap {
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: $mobile) {
                width: 0;
            }
        }

        &__control {
            @include transition;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (min-width: ($tablet + 1)) {
                @media screen and (max-width: 1280px) {
                    transform: scale(.8);
                }

                @media screen and (max-width: 1166px) {
                    transform: scale(.7);
                }

                @media screen and (max-width: 1024px) {
                    transform: scale(.5);
                }
            }

            @media screen and (max-width: $mobile) {
                transform: scale(.7);
            }

            &>* {
                padding: 12px !important;
                display: flex;
                align-items: center;
                height: 33px;
                border: 1px solid $light-gray;
            }

            input {
                border-left: none !important;
                border-right: none !important;
                border-radius: unset;
                text-align: center;
                min-width: 54px;
            }

            button {
                background-color: transparent;
            }
        }

        &__update,
        &__remove {
            background-color: unset;
            padding: 0;
            margin: 5px;
        }

        &__update {
            margin-left: 15px;
        }

        &__remove {
            @include font($font-default,  #{max(12px, vw(12px))}, $weight-thin, italic, 1);
            position: absolute;
            // right: min(-35px, vw(-60px));
            bottom: -25px;
            text-decoration: underline;

            &.giftcard-remove-control {
                bottom: -7px;
            }
            
            svg {
                display: none;
            }
        }

    }

    &--price {}

    &--total {}

    &--textarea {        
        margin-top: clamp(30px, 3.125vw, 60px);
        margin-bottom: clamp(30px, 3.125vw, 60px);
        
        textarea {
            display: flex;
            width: 100%;
            min-height: 140px;
            resize: vertical;
            border-radius: $well-type-border-radius;
            padding:$well-type-padding;
        }
    }
}

#{$umbrella} {
    .cart {
        padding-top: 20px;
    }

    .text-danger {
        font-size: 1.2rem;
    }
}

#{$umbrella} {
    .redeem {
        &__wrap {
            display: flex;
            flex-direction: column;
            margin-top: 50px;
        }

        &--item {
            display: flex;
            flex-direction: column;

            &:not(:last-child) {
                margin-bottom: 25px;
            }

        }

        &--heading {
            display: none;
        }

        &--content {
            display: flex;
            width: 100%;

            @media screen and (max-width: 600px) {
                flex-direction: column;
            }

            input {
                flex: 1;

                @media screen and (max-width: 600px) {
                    padding: 20px 15px;
                }
            }

            button {
                // width: 100%;
                // max-width: 135px;
                // padding: 5px 20px;
                // white-space: normal;
                
                // width: 100%;
                position: absolute;
                height: 100%;
                right: 0;
                background: transparent;
                border: none;
                width: 60px;
                outline: unset;
                
                &>i {
                    display: block;
                }

                // @media screen and (max-width: 600px) {
                //     max-width: unset;
                //     margin-top: 15px;
                //     padding: 20px 15px;
                // }
            }
        }
    }
}

#{$umbrella} {
    .popup-login {
        display: none;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;

        &>span {
            content: '';
            background-color: rgba(34, 34, 34, 0.75);
            display: flex;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
        }

        &.open {
            display: flex;
        }

        &__wrap {
            background-color: $white;
            width: 100%;
            max-width: 600px;
            margin: 20px;
        }

        &__head {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: max(15px, vw(40px));
            border-bottom: 1px solid #e5e5e5;

            &__title {
                @extend .popup-login-title;
            }

            ._close {
                @extend .popup-login-close;
            }
        }

        .login-fields {
            width: 100%;

            &--wrap {
                display: flex;
                flex-direction: column;
                padding: 40px;

                &>* {
                    &:not(:last-child) {
                        margin-bottom: 15px;
                    }
                }

                a {
                    text-decoration: underline;
                    text-align: right;
                }

                button {
                    @extend .cta;
                    border-radius: 100px;
                    padding-top: max(10px, vw(20px));
                    padding-bottom: max(10px, vw(20px));

                    &:hover {
                        color: $black !important;
                        background-color: transparent !important;
                        border-color: $black !important;
                    }
                }
            }
        }
    }
}


#{$umbrella} {    
    .checkout-header {
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-bottom: vw(100px);

        &__wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: max(0px, vw(50px));

            @media screen and (max-width: $checkout-bp) {
                flex-direction: column;
                justify-content: center;
                text-align: center;
                padding-top: max(70px, vw(135px));
            }

            &>* {
                width: 300px;
                color: var(--primary-color);

                * {
                    white-space: nowrap;
                }
            }

        }

        &__block {
            @media screen and (max-width: $checkout-bp) {
                order: 2;
            }
        }

        &--back-link {
            @media screen and (max-width: $checkout-bp) {
                margin-bottom: 5px;
            }
        }

        &--logo {
            display: flex;
            flex: auto;
            justify-content: center;

            @media screen and (max-width: $checkout-bp) {
                order: 1;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                background-color: $white;
                padding-top: 15px;
                padding-bottom: 15px;
                z-index: 1;
            }

            svg {
                display: flex;
                width: max(135px, vw(240px));
            }
        }

        &--assist {
            text-align: right;
            @media screen and (min-width: ($checkout-bp + 1)) {
                padding-right: 25px;
            }

            @media screen and (max-width: $checkout-bp) {
                display: flex;
                flex-direction: column;
                width: auto;
                text-align: center;
            }
        }

        ._underline {
            text-decoration: underline;
        }

        .support-icon {
            position: relative;
            top: 5px;
            margin: 0 5px;
        }
    }

    #d_social_login {
        width: unset;
        max-width: unset;
    }
    .alert-danger {
        background-color: unset;
        border-radius: 100px;
        border-color: $red;
        color: $red;
        text-align: center;
    }
    #d_social_login {
        background-color: $block-theming;
        display: block;
        margin: unset;        
        padding-bottom: 25px;

        .d-social-login {
            &__label {
                display: none;
            }

            &__button {
                box-shadow: unset;

                &__icon,
                &__label {
                    display: none;
                }

                &-list {
                    display: flex;
                    justify-content: center;
                }

                width: 40px;
                height: 40px;
                background-color: unset;

                &:before {
                    content: "";
                    display: flex;
                    width: 40px;
                    height: 40px;
                }

                &--facebook {
                    //margin-right: 15px;


                    &:before {
                        content: "";
                        //facebook
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.5 27.4' style='enable-background:new 0 0 27.5 27.4' xml:space='preserve'%3E%3Cpath d='M15.3 23.9h-3.8v-9.1h-3v-3.9h3V8.4c0-.7.1-1.3.3-1.9.5-1.4 1.5-2.3 3-2.7.4-.1.8-.2 1.3-.2.8 0 1.6 0 2.4.1h.6v3.5h-2c-.3 0-.5 0-.8.1-.5.1-.8.5-.9 1v2.5h3.5l-.5 3.9h-3v1l-.1 8.2zm-3.6-.3H15v-9.1h3.1l.4-3.3H15V8.3c.1-.6.4-1.1 1.1-1.2.3-.1.6-.2.9-.2h1.8v-3h-.3c-.8-.1-1.6-.1-2.4-.1-.4 0-.8.1-1.2.2-1.4.4-2.3 1.2-2.8 2.5-.2.6-.3 1.1-.3 1.8v2.8h-3v3.3h2.9v9.2z'/%3E%3Cpath d='M13.7 27.4C6.1 27.4 0 21.3 0 13.7S6.1 0 13.7 0s13.7 6.2 13.7 13.7-6.1 13.7-13.7 13.7zm0-27.2C6.3.2.2 6.2.2 13.7s6 13.5 13.5 13.5 13.5-6 13.5-13.5S21.1.2 13.7.2z'/%3E%3C/svg%3E");
                    }
                }

                &--google {
                    &:before {
                        content: "";
                        //google
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.8 27.8' style='enable-background:new 0 0 27.8 27.8' xml:space='preserve'%3E%3Cpath d='M13.9.1C6.3.1.1 6.3.1 13.9s6.2 13.8 13.8 13.8 13.8-6.2 13.8-13.8S21.5.1 13.9.1zm3.4 13.8c0 3.5-2.8 6.3-6.3 6.3s-6.3-2.8-6.3-6.3S7.5 7.6 11 7.6c1.4 0 2.7.4 3.8 1.3l-1.5 1.9c-.7-.5-1.5-.8-2.4-.8C8.8 10 7 11.7 7 13.9s1.7 3.9 3.9 3.9c1.7 0 3.2-1.1 3.7-2.7h-3.7v-2.4h6.3l.1 1.2zm6.6.8h-1.8v1.8h-1.6v-1.8h-1.8v-1.6h1.8v-1.8h1.6v1.8h1.8v1.6z' style='fill:none;stroke:%23000;stroke-width:.25;stroke-miterlimit:10'/%3E%3C/svg%3E");
                    }
                }
            }
        }
    }

    @media screen and (max-width: $tablet-sm) {
        .redeem,
        .orphan--giftwrap,
        .checkout__cart__output {
            padding-left: 35px;
            padding-right: 35px;
        }
    }

    .js-select2 {
        & + .select2-container {
            min-width: 100%;
            max-width: 100%;
        }
    }

    .js-select2--full-w {
        & + .select2-container {
            width: 100% !important;
            min-width: unset;
            max-width: 450px !important;
            margin-bottom: 5px;
        }
    }
}

.checkout {
    input[type="radio"] {
        @extend .radio-input;
    }
    
    input[type="checkbox"] {
        @extend .checkbox-input;
    }    

    input[type="checkbox"] {
        & + span,
        & + label {
            @extend .checkbox-radio-inputs;
            display: inline;
            line-height: 1.5;
        }
    }

    .bank-transfer-instructions {
        background-color: transparent;        
        border-radius: $well-type-border-radius;
        padding: $well-type-padding;
    }
}

.underline-empty {
    margin-top: max(20px, vw(40px));
    margin-bottom: max(30px, vw(65px));
    width: 80px;
    height: 1px;
    background-color: $gray-dark;

    @media screen and (max-width: $tablet-sm) {
        display: none;
    }
}

.orphan--giftwrap {
    display: flex;
    justify-content: flex-end;
    margin-top: max(20px, vw(50px));
    margin-bottom: max(5px, vw(35px));
    @media screen and (max-width: $tablet-sm) {
        justify-content: center;
    }

    label {
        span {           
            text-transform: none;
        }
    }
}

#warning-messages {
    margin-left: 20px;
    margin-right: 20px;

    .alert.alert-danger {
        max-width: 600px;
        margin: auto;
        margin-top: max(80px, vw(100px));
        border-radius: 100px;
        background-color: transparent;
        border-color: $red;
        color: $red;
        text-align: center;
    }
}

#checkout-safety-net {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;


    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
      }
      .lds-ripple div {
        position: absolute;
        border: 4px solid #fff;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }
      .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
      }
      @keyframes lds-ripple {
        0% {
          top: 36px;
          left: 36px;
          width: 0;
          height: 0;
          opacity: 0;
        }
        4.9% {
          top: 36px;
          left: 36px;
          width: 0;
          height: 0;
          opacity: 0;
        }
        5% {
          top: 36px;
          left: 36px;
          width: 0;
          height: 0;
          opacity: 1;
        }
        100% {
          top: 0px;
          left: 0px;
          width: 72px;
          height: 72px;
          opacity: 0;
        }
      }
      
}