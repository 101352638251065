footer {
    margin: 0;
    background-color: $gray;
    color: $primary_color;
    .footer  {
        &--container {
            padding: 50px max(30px, vw(140px)) 24px;
            @media (max-width: $laptop) {
                padding: 50px 50px 24px;
            }
            @media (max-width: $tablet) {
                padding: 50px 30px 0;
            }
            @media (max-width: $tablet-sm) {
                padding: 0 0 50px;
            }
        }
        &--wrapper {
            display: flex;
            @media (max-width: $laptop-sm) {
                flex-wrap: wrap;
            }
            @media (max-width: $tablet-sm) {
                flex-direction: column;
            }
        }
        &--column {
            //flex-basis: 16.666%;
            width: 100%;
            &:nth-child(1){
                max-width: 220px;
                @media (max-width: $tablet-sm) {
                    &._open {
                        .footer--body {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: flex-start;
                            .payment--cards {
                                padding-top: 0;
                                max-width: 135px;
                            }
                        }
                    }
                }
            }
            &:nth-child(2){
                max-width: 330px;
            }
            &:nth-child(3){
                max-width: 245px;
            }
            &:nth-child(4){
                max-width: 290px;
            }
            &:nth-child(5){
                max-width: 235px;
            }
            &:nth-child(6){
                max-width: 240px;
            }
            &:not(:last-child) {
                margin-right: 15px;
                @media (max-width: $tablet-sm) {
                    margin-right: 0;
                }
            }
            @media (max-width: $laptop-sm) {
                flex-basis: calc(33.3333% - 15px);
                margin-bottom: 50px;
                width: unset;
                &:nth-child(1){
                    max-width: unset;
                }
                &:nth-child(2){
                    max-width: unset;
                }
                &:nth-child(3){
                    max-width: unset;
                }
                &:nth-child(4){
                    max-width: unset;
                }
                &:nth-child(5){
                    max-width: unset;
                }
                &:nth-child(6){
                    max-width: unset;
                }
            }
            @media (max-width: $tablet-sm) {
                flex-basis: 100%;
                margin-bottom: 0;
                border-bottom: 1px solid $black;
                &:first-child {
                    border-top: 1px solid $black;
                }
                &.follow {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                &._open {
                    .footer--body {
                        display: block;
                    }
                }
            }
            &._open {
                .footer--title {
                   &.extend {
                       &:after {
                           transform: translateY(-50%) rotate(180deg);
                           transition: transform 0.3s ease;
                       }
                   }
                }
            }
        }
        &--title {
            @include font($font-default, #{max(15px, vw(15px))}, $weight-thin, normal, 1);
            color: $black;
            margin-bottom: 30px;
            @media (max-width: $tablet-sm) {
                margin: 0;
                padding: 15px;
                position: relative;
                &.extend {
                    &:after {
                        display: inline-block;
                        content: "";
                        background-image: url('../../../icons/arrow-down.svg');
                        padding:  7px 12px;
                        background-color: transparent;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        -moz-transform: translateY(-50%);
                        -o-transform: translateY(-50%);
                        transform: translateY(-50%);
                        transition: transform 0.3s ease;
                    }
                }
            }
        }
        &--body {
            @media (max-width: $tablet-sm) {
                display: none;
                padding: 0 15px;
                &.open {
                    display: block;
                }
                .footer--list {
                    margin-bottom: 10px;
                }
            }
        }
        &--list {
            li {
                font-size: 15px;
                font-weight: 100;
                margin-bottom: 0;
            }
        }
        &--social {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 0!important;
            @media (max-width: $tablet-sm) {
                height: 20px;
            }
            li {
                display: flex;
                margin-bottom: 0;
                &:not(:last-child) {
                    margin-right: 15px;
                }
            }
        }
        &--link {
            display: inline-block;
            @include font($font-default, #{max(15px, vw(15px))}, $weight-thin, normal, 1);
            &:hover, &:focus, &:visited {
                color: $black;
                text-decoration: none;
            }
        }
    }
    .credits-container {
        font-size: 12px;
        letter-spacing: 1px;
        color: #000000;
        font-weight: 300;
        text-align: right;
        padding: 0 max(30px, vw(60px)) 17px;
        @media (max-width: $tablet-sm) {
            text-align: center;
            padding: 0 0 17px;
        }
        .credits-wrapper {
            padding-top: 5px;
            border-top: 1px solid #000000;
            .saintAthens {
                margin-right: 15px;
            }
            .eight8-logo {
                display: inline-block;
                width: 100%;
                max-width: 41px;
                position: relative;
                top: 4px;
                svg {
                    width: 100%;
                    max-width: 41px;
                }
            }
            a {
                text-decoration: underline;
            }
        }
    }

    .payment {
        &--cards {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            max-width: 170px;
            padding-top: 10px;
            li {
                margin-bottom: 5px;
                &:not(:last-child) {
                    margin-right: 8px;
                }
            }
        }
        &--icon {
            display: inline-block;
            width: auto;
            svg {
                display: inline-block;
                width: auto;
                height: 15px;
                @media (max-width: $mobile-sm) {
                    height: 13px;
                }
            }
            &.maestro-icon , &.american-express-icon{
                svg {
                    height: 25px;
                    @media (max-width: $mobile-sm) {
                        height: 22px;
                    }
                }
            }
        }
    }

    .footer-newsletter {

        .newsletter-form {
            @media (max-width: $tablet) {
                max-width: 320px;
            }
        }
        .newsletter-wrapper {
            position: relative;
            width: 100%;
            margin-bottom: 20px;
            @media (max-width: $mobile-sm) {
                max-width: 200px;
            }
            input[type="email"] {
                padding: 12px 20px;
            }
        }


        .newsletter-subscribe-btn {
            display: flex;
            align-items: center;
            cursor: pointer;
            position: absolute;
            bottom: 0;
            right: 2px;
            padding: 12px 20px;
            .newsletter-submit-arrow {
                display: flex;
                width: 10px;
                height: 16px;
                svg {
                    display: flex;
                    width: 10px;
                    height: 16px;
                    .st0{
                        fill:none;
                        stroke:$gray-light;
                        stroke-width:1.0006;
                        stroke-linejoin:bevel;
                        stroke-miterlimit:10;
                    }
                }
            }
        }
    }

    .mobile--logo--wrapper {
        display: none;
        @media (max-width: $tablet-sm) {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px 15px;
            .mobile--logo {
                width: 100px;
                height: 30px;
                display: inline-block;
                svg {
                    width: 100px;
                    height: 30px;
                    display: inline-block;
                }
            }
        }
    }
}
#subscribe_result * {
    @include font($font-default, #{max(15px, vw(15px))}, $weight-normal, normal, 1);
}

.social--icon {
    display: inline-block;
    height: 29px;
    width: auto;
    @media (max-width: $tablet-sm) {
        height: 20px;
    }
    svg {
        display: inline-block;
        height: 29px;
        width: auto;
        @media (max-width: $tablet-sm) {
            height: 20px;
        }
        .st0 {
            fill: #333333;
        }
        .st1{
            fill:none;
            stroke:#333333;
            stroke-width:0.8504;
            stroke-linejoin:bevel;
            stroke-miterlimit:10;
        }
    }
    &.youtube {
        height: 24px;
        @media (max-width: $tablet-sm) {
            height: 15px;
        }
        svg {
            height: 24px;
            @media (max-width: $tablet-sm) {
                height: 15px;
            }
        }
    }
}