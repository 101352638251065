/*========================================================*/
/*** MENU ***/
/*========================================================*/
#mega_menu {
    //background-color: $beige;
    @media (max-width: 1200px) {
        position: static;
        .responsive.megamenu-style-dev {
            position: static;
            > .navbar-default {
                position: static;
                > .container-megamenu.horizontal {
                    position: static;
                    .navbar-header {
                        position: absolute;
                        top: 50%;
                        left: 30px;
                        z-index: 999;
                        transform: translate(0, -50%);
                        @media (max-width: $tablet-sm) {
                            left: 15px;
                        }
                    }
                }
            }
        }
    }
    .container-megamenu  {
        .megamenu-wrapper {
            ul.megamenu {
                display: flex;
                justify-content: space-between;
                padding: 0 50px;
                @media (max-width: 1200px) {
                    padding: 0 30px;
                    height: calc(var(--screen-height) - var(--header--upper));
                    justify-content: flex-start;
                }
                @media (max-width: $tablet-sm) {
                    padding: 0 15px;
                }
                &.white-menu {
                    .subcategory {
                        .main-menu, .title-submenu {
                            color: $gray-light!important;
                            &:hover {
                                color: $white!important;
                            }
                        }
                    }

                    li {
                        a {
                            color: $white!important;
                            &:hover {
                                color: $white!important;
                            }
                            strong {
                                &:after {
                                    background-color: $white;
                                }
                            }
                        }
                        .sub-menu  {
                            .content {
                                .menu-banner-image {
                                    .title-submenu {
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                }
                li {

                    &:not(:last-child) {
                        margin-right: 20px;
                        @media (max-width: 1200px) {
                            margin-right: 0;
                        }
                    }
                    @media (max-width: 1200px) {
                        position: relative;
                        //max-height: 60px;
                        &.active-show.click {
                            position: fixed;
                            top: var(--header-upper);
                            left:0;
                            z-index: 9;
                            height: calc(var(--screen-height) - var(--header--height));
                            a.clearfix {
                                padding: 15px 30px;
                                @media (max-width: $tablet-sm) {
                                    padding: 15px!important;
                                }
                            }
                        }
                    }
                    @media (max-width: $tablet-sm) {
                        &.active-show.click {
                            height: calc(var(--screen-height) - var(--header--upper));
                        }
                    }
                    &:hover, a:hover, &.active>a, &:hover>a {
                        background-color: transparent;
                        color: $primary_color;
                    }

                    .sub-menu {
                        background-color: $beige;
                        left: 0;
                        padding-top: 0;
                        .content {
                            background-color: unset;
                            padding: 10px 100px 20px 50px!important;
                            -webkit-box-shadow: none;
                            -moz-box-shadow: none;
                            box-shadow: none;
                            display: flex;
                            justify-content: space-between;

                            @media (max-width: 1200px) {
                                margin: 15px 30px!important;
                                flex-direction: column;
                                height: calc(var(--screen-height) - var(--header-height) - 80px);
                                overflow-y: auto;
                                padding: 0!important;
                                &::-webkit-scrollbar {
                                    width: 6px;
                                }

                                &::-webkit-scrollbar-track {
                                    @include prefix((box-shadow: inset 0 0 6px transparent), webkit moz);
                                    border-radius: 4px;
                                }

                                &::-webkit-scrollbar-thumb {
                                    @include prefix((box-shadow: inset 0 0 6px $gray-light), webkit moz);
                                    background-color: $gray-light;
                                    border-radius: 4px;
                                }
                            }

                            @media (max-width: $tablet-sm) {
                                margin: 15px!important;
                            }
                            .flex-row {
                                display: flex;
                                flex-direction: row;
                                @media (max-width: 1200px) {
                                    flex-direction: column;
                                }
                            }
                            .submenu-col {
                                flex: 1;
                                max-width: 200px;
                            }

                            .menu-banner-image {
                                padding-top: 30px;
                                display: flex;
                                flex-basis: 25%;
                                align-items: flex-end;
                                justify-content: flex-end;
                                a {
                                    padding: 0;
                                }
                                @media (max-width: 1200px) {
                                    flex-direction: column-reverse;
                                    align-items: flex-start;
                                }
                                .title-submenu {
                                    @include font($font-default, #{max(13px, vw(13px))}, $weight-thin, normal, 1);
                                    text-transform: uppercase;
                                    max-width: 160px;
                                    text-align: right;
                                    margin-right: 10px;
                                    @media (max-width: 1200px) {
                                        max-width: 100%;
                                        text-align: left;
                                        margin-right: 0;
                                    }
                                }
                                video, img {
                                    max-width: 225px;
                                }
                            }

                            .menu-category-link {
                                display: inline-block;
                                @extend .menu__sub-category;
                                color: $gray-medium;
                                &:hover {
                                    color: $black;
                                }

                                @media (max-width: 1200px) {
                                    font-size: 16px;
                                    padding: 10px 0;
                                }
                            }

                            .hover-menu {
                                .menu {
                                    ul {
                                        line-height: 1;
                                        padding-left: 25px;
                                        @media (max-width: 1200px) {
                                            padding-left: 0;
                                        }
                                    }
                                }
                            }
                        }
                        .subcategory {
                            @media (max-width: 1200px) {
                                .row {
                                    display: none;
                                }
                                li {
                                    &._open {
                                        .row {
                                            display: block;
                                        }
                                    }
                                }
                            }
                            .title-submenu {
                                z-index: 99;
                                padding: 7px 0 0;
                                @extend .menu__sub-category;
                                color: $gray-medium;
                                &:hover {
                                    color: $black;
                                }
                                @media (max-width: 1200px) {
                                    font-size: 16px;
                                    display: inline-block;
                                    max-width: unset;
                                    margin-top: 0;
                                    margin-bottom: 0;
                                }
                            }
                            .menu {
                                .main-menu {
                                    width: unset;
                                    @extend .menu__sub-category;
                                    padding: 3px 0;
                                    text-transform: unset;
                                    color: $gray-medium;
                                    &:hover {
                                        color: $black;
                                    }
                                }
                            }
                            img {
                                display: none;
                            }
                        }
                    }

                    a {
                        padding: 20px 0 20px;
                        @media (max-width: 1200px) {
                            font-size: 20px;
                            padding: 20px 0;
                            display: flex;
                            justify-content: space-between;
                        }
                        &.viewAll {
                            font-size: 16px;
                            padding: 10px 0;
                            font-style: italic;
                            text-transform: capitalize;
                        }

                        @extend .menu__category;
                        text-transform: uppercase;
                        strong {
                            position: relative;
                            font-weight: 300;
                            &:after {
                                content: '';
                                display: inline-block;
                                width: 0;
                                height: 1px;
                                background-color: $black;
                                position: absolute;
                                bottom: -7px;
                                left: 0;
                                @include transition;
                                @media (max-width: 1200px) {
                                    display: none;
                                }
                            }
                        }
                        &:after {
                            display: none;
                        }
                        &:hover {
                            text-decoration: none;
                            strong {
                                &:after {
                                    width: 100%;
                                }
                            }

                        }
                        .caret {
                            display: none;
                            @media (max-width: 1200px) {
                                display: flex;
                                float: right;
                                width: 24px;
                                height: 14px;
                                margin: 0;
                                border: none;
                                vertical-align: unset;
                                content: '' !important;
                                background-image: url('../../../icons/arrow-down.svg');
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: 25px 15px;
                                transition: transform 0.3s ease;
                            }
                        }
                    }

                    &.with-sub-menu.active-show.click {
                        .caret {
                            display: block;
                            transform: rotate(180deg);
                            transition: transform 0.3s ease;
                        }

                    }
                    &.full-width-expanded {
                        .sub-menu {
                            .content {
                                padding-bottom: 80px!important;
                                .flex-row {
                                    flex: 1;
                                    .submenu-col {
                                        flex: 1;
                                        margin-right: 10px;
                                        &:nth-child(1){
                                            max-width: 200px;
                                        }
                                        &:nth-child(2){
                                            max-width: 295px;
                                        }
                                        &:nth-child(3){
                                            max-width: 250px;
                                        }
                                        &:nth-child(4){
                                            max-width: 180px;
                                        }
                                        &:nth-child(5){
                                            max-width: 210px;
                                        }
                                        &:nth-child(6){
                                            max-width: 210px;
                                        }
                                        @media (max-width: 1200px) {
                                            flex: unset;
                                        }
                                        //&:not(:last-child) {
                                        //    margin-right: max(50px, vw(115px));
                                        //    @media (max-width: $tablet) {
                                        //        margin-right: 0;
                                        //    }
                                        //}
                                    }
                                }
                            }
                        }
                    }

                    &.full-width {
                        @media (max-width: 1200px) {
                            .menu__sub-category {
                                font-size: 16px;
                                margin-bottom: 7px;
                            }
                        }

                        .sub-menu {
                            .content {
                                .flex-row {
                                    width: 100%;
                                    .submenu-col {
                                        flex-basis: 50%;
                                        max-width: unset!important;
                                        @media (max-width: 1200px) {
                                           margin-bottom: 20px;
                                            .title-submenu {
                                                font-weight: 400;
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }

                    &.off-white{
                        .sub-menu {
                            .content {
                                .flex-row {
                                    flex: 1;
                                    .submenu-col {
                                        flex: unset;
                                        flex-basis: 15%;

                                        &:nth-child(2){
                                            .subcategory {
                                                ul {
                                                    padding-left: unset;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &.slide {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    li.active {
                        .sub-menu {
                            .container {
                                .content {
                                    border: none;
                                    padding: 50px 35px 40px 35px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }



    .mobile-enabled {

        a,
        h3 {
            font-family: $primary_font !important;
        }

        &.container {
            padding-bottom: 40px;
        }
    }



    .main_link {
        h3 {
            border-bottom: none;
            padding: 0;
            font-size: 1.6rem;
            line-height: 3.6rem;
            letter-spacing: 0.025em;
        }
    }

    #menu {
        margin-bottom: 0;
    }


    .container-sub-menu {
        width: 100%;
    }

    i.fa.fonticon {
        display: none;
    }
}


li.with-sub-menu.hover.active a.menutitle::after {
    width: 100%;
}

ul.list-unstyled.subcategory li.subtitle2 .newhover a {
    position: relative;
}

ul.list-unstyled.subcategory li.subtitle2 .newhover a::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 1px;
    background-color: $primary_color;
    position: absolute;
    bottom: 3px;
    left: 0;
    -webkit-transition: width .3s ease;
    -moz-transition: width .3s ease;
    -o-transition: width .3s ease;
    transition: width .3s ease;
}

ul.list-unstyled.subcategory li.subtitle2:hover .newhover a::after {
    width: 100%;
}

.sub-menu-banner {
    position: relative;
    transition: linear .3s;

    &:hover {
        opacity: 0.9;
        transition: linear .3s;
    }

    img {
        position: relative;
    }

    a.shop-now-btn {
        position: absolute;
        bottom: 10px;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        text-align: center;
        color: #fff;
        text-decoration: underline;
        font-size: 1.4rem;
        line-height: 3rem;
        font-weight: 600;
        letter-spacing: 0.025em;
    }
}

.megamenu-style-dev.responsive {
    margin-bottom: 0;
}

.megamenu-style-dev {
    .navbar-default {
        background-color: transparent;
        border-color: transparent;
    }

    .megamenu-wrapper {
        background-color: transparent;

        ul.megamenu {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            justify-content: center;

            li {}
        }
    }
}

@media screen and (min-width: 1200px + 1) {
    .megamenu {
        & > li {
            &.off-white {
                .menu__sub-category {
                    display: flex;
                }

                .sub-menu {
                    .flex-row {
                        justify-content: space-between;

                        .submenu-col {
                            max-width: unset !important;
                            flex-basis: unset !important;

                            & > * {
                                &:first-child {
                                    margin-right: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}



.megamenu-style-dev .container-megamenu.horizontal ul.megamenu>li>a:before {
    content: none;
}

/*submenu*/
.megamenu-wrapper ul.megamenu>li>.sub-menu {
    left: 0;
    padding-top: 35px;
}

.megamenu-wrapper ul.megamenu .title-submenu:before {
    content: none;
}

.megamenu-wrapper ul.megamenu .title-submenu {
    margin-bottom: 0;
}

.megamenu-wrapper ul.megamenu li .sub-menu .content .static-menu>.menu>ul>li {
    padding-top: 15px;
}

.megamenu-wrapper ul.megamenu li .sub-menu .content .static-menu a.main-menu {
    font-size: 1.4rem;
    padding: 0;
    width: auto;
    display: inline-block;
    position: relative;
}

ul.megamenu li .sub-menu .content .static-menu>.menu>ul>li {
    padding-top: 20px;
}

.megamenu-wrapper ul.megamenu .title-submenu {
    display: inline-block;
    width: auto;
    padding-bottom: 0;
    margin-bottom: 10px;
}

.megamenu-wrapper .menu-banner .title-submenu {
    position: relative;
}

.megamenu-wrapper .categories li ul li a:hover,
.megamenu-wrapper .subcategory li ul li a:hover {
    margin-left: 0;
}

.megamenu-wrapper .subcategory .title-submenu:after,
.megamenu-wrapper .categories li ul li a:after,
.megamenu-wrapper .subcategory li ul li a:after,
.megamenu-wrapper .manufacturer li a:after {
    content: '';
    width: 0;
    height: 2px;
    background-color: $primary_color;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-transition: width .3s ease;
    -moz-transition: width .3s ease;
    -o-transition: width .3s ease;
    transition: width .3s ease;
}

.megamenu-wrapper .subcategory .title-submenu:hover:after,
.megamenu-wrapper .categories li ul li a:hover:after,
.megamenu-wrapper .subcategory li ul li a:hover:after,
.megamenu-wrapper .manufacturer li a:hover:after {
    width: 100%;
    margin-left: 0;
    -webkit-transition: width .3s ease;
    -moz-transition: width .3s ease;
    -o-transition: width .3s ease;
    transition: width .3s ease;
}

.megamenu-wrapper .menu-banner-link {
    position: relative;
}

.megamenu-wrapper .menu-banner-link .text {
    font-size: 1.4rem;
    font-weight: 600;
    color: #fff;
}

.megamenu-wrapper ul.megamenu .menu-banner .title-submenu {
    text-align: center;
    width: 100%;
}

.megamenu-wrapper .menu-banner a {
    display: inline-block;
}

.megamenu-wrapper .menu-banner-link .text {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 20px;
    padding-bottom: 5px;
    border-bottom: 2px solid #fff;
}





.megamenu-wrapper {
    ul.megamenu {
        .sub-menu {
            .content {
                .static-menu {
                    .menu {
                        ul {
                            margin-top: 10px;

                            li {
                                padding-top: 20px;
                            }
                        }
                    }

                    a.main-menu {
                        font-size: 1.4rem;
                        padding: 0;
                    }
                }
            }
        }

        .title-submenu {
            margin-bottom: 0;

            &:before {
                content: none;
            }
        }
    }

    .categories,
    .subcategory {
        li {
            ul {
                li {
                    a {
                        -webkit-transition: .3s;
                        -moz-transition: .3s;
                        -ms-transition: .3s;
                        -o-transition: .3s;
                        transition: .3s;
                    }
                }
            }
        }

    }

    .menu-banner-link {
        position: relative;

        .text {
            font-size: 1.4rem;
            font-weight: 600;
            color: #fff;
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
            bottom: 20px;
            padding-bottom: 5px;
            border-bottom: 2px solid #fff;
        }
    }

    .menu-banner {
        .title-submenu {
            text-align: center;
        }

        a {
            display: inline-block;
        }
    }

    .manufacturer {
        li {
            display: block;

            a {
                color: $primary_color;
                border: 0;
                display: inline-block;
                width: auto;
                position: relative;
                padding: 0;
            }
        }
    }
}

@media (max-width: 1200px) {
    .megamenu-style-dev {
        position: fixed;
        z-index: 99;
        left: 15px;

        .navbar-default {
            .navbar-toggle {
                background-color: transparent;
                border: none;
                border-radius: 0;
                width: 30px;
                padding: 0;
                margin: 0;
                z-index: 999;
                display: inline-block;

                span {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 2px;
                    background-color: $black;
                    -webkit-transition: all 0.3s ease;
                    -moz-transition: all 0.3s ease;
                    -o-transition: all 0.3s ease;
                    transition: all 0.3s ease;

                    &:not(:last-child) {
                        margin-bottom: 6px;
                    }
                }

                &.white-burger {
                    span {
                        background-color: $white;
                    }
                }


                &.active {
                    span {
                        &:nth-child(2) {
                            opacity: 0;
                            -webkit-transition: all 0.3s ease;
                            -moz-transition: all 0.3s ease;
                            -o-transition: all 0.3s ease;
                            transition: all 0.3s ease;
                        }

                        &:nth-child(1) {
                            -webkit-transform: rotate(45deg);
                            -moz-transform: rotate(45deg);
                            -o-transform: rotate(45deg);
                            transform: rotate(45deg);
                            -webkit-transition: all 0.3s ease;
                            -moz-transition: all 0.3s ease;
                            -o-transition: all 0.3s ease;
                            transition: all 0.3s ease;
                            top: 6px;
                        }

                        &:nth-child(3) {
                            -webkit-transform: rotate(-45deg);
                            -moz-transform: rotate(-45deg);
                            -o-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                            -webkit-transition: all 0.3s ease;
                            -moz-transition: all 0.3s ease;
                            -o-transition: all 0.3s ease;
                            transition: all 0.3s ease;
                            bottom: 10px;
                        }
                    }
                }
            }
        }

        .megamenu-wrapper {
            width: 45%;
            position: fixed;
            top: var(--header--upper);
            left: -100%;
            z-index: 9;
            height: calc(var(--screen-height) - var(--header--upper));
            max-height: unset;
            padding: 0;
            opacity: 0;
            visibility: hidden;
            background-color: transparent;
            overflow-y: hidden;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            border: none;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;

            &.so-megamenu-active {
                left: 0;
                opacity: 1;
                visibility: visible;
                @media (max-width: $tablet-sm) {
                    width: 100%;
                }
            }

            .megamenu {
                flex-direction: column;

                li {
                    a {
                        color: #000;
                    }
                }
            }
        }
    }

    /*submenu*/
    .megamenu-wrapper .megamenu .sub-menu .content > .row > div {
        width: 100%;
    }

    .megamenu-wrapper ul.megamenu > li > .sub-menu {
        padding-top: 0;
    }



    .megamenu-wrapper {
        .sub-menu {
            padding-bottom: 20px;

            .discount-bar {
                display: none;
            }

            .content {
                .static-menu {
                    .menu {
                        ul {
                            li {
                                padding-top: 5px;
                            }
                        }
                    }
                }
            }
        }
    }


    /*menu*/
    .mobile-account-items {
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.7);
        max-height: 51px;
        height: 100%;

        a {
            display: inline-block;
            float: left;
            width: 33.333333%;
            background-color: #eaeaea;
            padding: 15px 15px 10px;
            text-align: center;
            color: #000;

            &:nth-child(2) {
                border-left: 1px solid rgba(0, 0, 0, 0.7);
                border-right: 1px solid rgba(0, 0, 0, 0.7);
            }

            .text {
                vertical-align: top;
            }
        }
    }


    .megamenu-wrapper ul.megamenu li .sub-menu .content .static-menu .menu ul {
        margin-top: 0;
    }

}


.megamenu-pattern-header {
    display: none;
    @media (max-width: 1200px) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: var(--header--logo);
        padding-right: 30px;
    }
    @media (max-width: $tablet-sm) {
        padding-right: 15px;
    }
    .logo--icon {
        //display: inline-block;
        display: none;
        width: 55px;
        height: 25px;
        svg {
            .st0 {
                fill: $black;
            }
        }
    }
}

.only-mobile {
    display: none !important;
    padding: 0 !important;
    @media (max-width: 1200px) {
        display: block !important;

        &>a {
            text-transform: capitalize !important;
            font-style: italic !important;
        }
    }
}

.offwhite-row,
.sales-row {
    display: flex;
    @media (max-width: 1200px) {
        flex-direction: column;
    }
    .subcategory {
        max-width: 200px;
    }
}

@media screen and (min-width: 1200px + 1) {
    .offwhite-row {
        & > * {
            flex: 1;
        }
    }
}


#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.custom-black-week a strong{
    font-weight: bold;
}
