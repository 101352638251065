@font-face {
    font-family: 'Helvetica';
    src: url('../../../fonts/HelveticaNeue-Thin.eot');
    src: url('../../../fonts/HelveticaNeue-Thin.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/HelveticaNeue-Thin.woff2') format('woff2'),
        url('../../../fonts/HelveticaNeue-Thin.woff') format('woff'),
        url('../../../fonts/HelveticaNeue-Thin.ttf') format('truetype'),
        url('../../../fonts/HelveticaNeue-Thin.svg#HelveticaNeue-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../../../fonts/HelveticaNeue-ThinItalic.eot');
    src: url('../../../fonts/HelveticaNeue-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/HelveticaNeue-ThinItalic.woff2') format('woff2'),
        url('../../../fonts/HelveticaNeue-ThinItalic.woff') format('woff'),
        url('../../../fonts/HelveticaNeue-ThinItalic.ttf') format('truetype'),
        url('../../../fonts/HelveticaNeue-ThinItalic.svg#HelveticaNeue-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../../../fonts/HelveticaNeue-Light.eot');
    src: url('../../../fonts/HelveticaNeue-Light.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/HelveticaNeue-Light.woff2') format('woff2'),
        url('../../../fonts/HelveticaNeue-Light.woff') format('woff'),
        url('../../../fonts/HelveticaNeue-Light.ttf') format('truetype'),
        url('../../../fonts/HelveticaNeue-Light.svg#HelveticaNeue-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../../../fonts/HelveticaNeue.eot');
    src: url('../../../fonts/HelveticaNeue.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/HelveticaNeue.woff2') format('woff2'),
        url('../../../fonts/HelveticaNeue.woff') format('woff'),
        url('../../../fonts/HelveticaNeue.ttf') format('truetype'),
        url('../../../fonts/HelveticaNeue.svg#HelveticaNeue') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../../../fonts/HelveticaNeue-Medium.eot');
    src: url('../../../fonts/HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/HelveticaNeue-Medium.woff2') format('woff2'),
        url('../../../fonts/HelveticaNeue-Medium.woff') format('woff'),
        url('../../../fonts/HelveticaNeue-Medium.ttf') format('truetype'),
        url('../../../fonts/HelveticaNeue-Medium.svg#HelveticaNeue-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}