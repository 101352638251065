.stocklist-container {
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  padding: 0 max(50px, vw(120px)) max(45px, vw(115px));
  @media screen and (max-width: $tablet-sm){
    background-image: unset!important;
    background-color: $beige;
  }
  .location-place{
    @include font($font-default, #{max(15px, vw(27px))}, $weight-normal, normal, 1);
    margin-bottom: max(20px, vw(45px));
  }
  .shop{
    margin-bottom: max(20px, vw(40px));
    h3{
      @include font($font-default, #{max(13px, vw(27px))}, $weight-thin, normal, 1.2);
    }
  }
  .desktop-container{
    display: grid;
    grid-template-columns: 3fr 1fr;
    @media screen and (max-width: $tablet-sm){
      display: none;
    }
    .stocklist-shops-container{
      h2{
        font-size: 27px;
        font-weight: 400;
        margin-bottom: 40px;
      }
      .greek-locations-container{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-right: 2%;
      }
    }
  }
  .mobile-container{
    min-height: 50vh;
    @media screen and (min-width: $tablet-sm + 1){
      display: none;
    }
    #select2-stockilist-region-container{
      font-size: 15px;
      padding: 10px;
    }
    select{
      &+span{
        margin-bottom: 30px;
        width: 100% !important;
      }
    }
    .stocklist-shops-container{
      display: none;
      .location{
        max-width: 200px;
        margin: 0 auto;
      }
    }
  }
}
