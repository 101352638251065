.main-size-guide-container{
  padding: max(65px, vw(170px)) max(35px, vw(95px)) max(35px, vw(45px));

  .size-guide-tabs {
    & > * {
        @media screen and (max-width: $laptop-xs){
        padding-left: unset !important;
        padding-right: unset !important;
      }
    }
  }
}
.size-guide {
  &-container-bridal {
    min-height: 578px;
    border: 1px solid #c7c7c7;
    @media screen and (max-width: $laptop-xs){
      border: none;
    }
  }
  &__title {
    @include font($font-default, #{max(30px, vw(63px))}, $weight-thin, normal, 1);
    margin-bottom: 30px;
    text-align: center;
    &.space-up {
      margin-top: max(30px, vw(60px));

      @media screen and (max-width: $laptop-xs){
        padding-top: 30px;
        border-top: 1px solid #c7c7c7;
      }
    }
  }
}
.size-guide-container{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border: 1px solid #c7c7c7;
  padding-top: 40px;
  padding-bottom: 90px;
  position: relative;
  @media screen and (max-width: $laptop-xs){
    border: none;
    padding-top: unset;
    padding-bottom: unset;
  }
  @media screen and (max-width: $tablet-sm){
    flex-direction: column;
  }
  &>div{
    @media screen and (max-width: $laptop-xs){
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .size-guide-table-container{
    margin-bottom: max(15px, vw(85px));
    margin-right: 10px;
    h1{
      font-size: 17px;
      font-weight: 100;
      margin-bottom: 25px;
      text-align: center;
      @media screen and (max-width: $laptop-xs){
        font-size: 14px;
        font-weight: 400;
      }
    }
    p{
      font-size: 17px;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 30px;
      text-align: center;
      @media screen and (max-width: $laptop-xs){
        font-size: 14px;
      }
    }
    .units-container{
      display: flex;
      justify-content: center;
      gap: 15px;
      margin-bottom: 40px;
      @media screen and (max-width: $laptop-xs){
        gap: 5px;
      }
      div {
        &:hover{
          cursor: pointer;
        }
      }
      .active{
        border-bottom: 1px solid #707070;
      }
    }
    .tables-container{
      h2{
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 20px;
        @media screen and (max-width: $laptop-xs){
          font-size: 14px;
          margin-bottom: 40px;
        }
      }
      table{
        border: 1px solid #ccc;
        th{
          font-size: 17px;
          font-weight: 100;
          color: #fff;
          background: #333;
          border: 1px solid #ccc;
          width: 75px;
          height: 40px;
          padding-left: 5px;
          @media screen and (max-width: $laptop-xs){
            font-size: 14px;
            width: 75px;
            height: 30px;
          }
        }
        td{
          font-size: 17px;
          font-weight: 100;
          width: 85px;
          height: 40px;
          padding-left: 5px;
          border-right: 1px solid #ccc;
          @media screen and (max-width: $laptop-xs){
            font-size: 14px;
            width: 60px;
            height: 30px;
          }
        }
      }
      .table-container{
        &.in{
          display: none;
        }
      }
    }
  }
  .size-guide-img-container{
    .title-container{
      @media screen and (max-width: $laptop-xs){
        width: 100%;
        max-width: 300px;
      }
      h2,h3{
        font-size: 17px;
        font-weight: 100;
        margin-bottom: max(12px, vw(35px));
        @media screen and (max-width: $laptop-xs){
          font-size: 12px;
          font-weight: 400;
        }
      }
      h3{
        text-transform: uppercase;
        margin-bottom: unset;
      }
    }
    .img-text-container{
      display: flex;
      padding-top: vw(70px);
      @media screen and (max-width: $laptop-xs){
        flex-direction: column;
        align-items: center;
      }
      .img-container{
          max-width: max(180px, vw(300px));
      }
      .text-container{
        padding-left: vw(45px);
        font-size: 12px;
        font-weight: 100;
        p {
          @media (max-width: $tablet-sm) {
            margin-bottom: 0;
          }
        }
        @media screen and (max-width: $laptop-xs){
          padding-left: unset;
        }
      }
    }
  }
}
