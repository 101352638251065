// * BUTTON STRACTURE
// * $padding-y, $padding-x, $border-radius, $border-width, $border-style
@mixin buttonsStracture( //

    $padding-y: null,
    $padding-x: null,
    $border-radius: null,
    $border-width: null,
    $border-style: null,
    //

) {

    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 1;

    @if type-of($padding-y) !=null {
        padding-top: $padding-y;
        padding-bottom: $padding-y;
    }

    @if type-of($padding-x) !=null {
        padding-left: $padding-x;
        padding-right: $padding-x;

    }

    @if type-of($border-radius) !=null {
        border-radius: $border-radius;
    }

    @if type-of($border-width) !=null {
        border-width: $border-width;
    }

    @if type-of($border-style) !=null {
        border-style: $border-style;
    }
}

@mixin button($background,
    $color,
    $hover-background:null,
    $hover-color:null) {

    @include transition;
    background-color: $background;
    color: $color;

    @if $background==transparent {
        border-color: $color;
    }

    @else {
        border-color: $background;
    }

    @if type-of($hover-background) !=null and type-of($hover-color) !=null {
        &:hover {
            background-color: $hover-background;
            color: $hover-color;

            @if $hover-background==transparent {
                border-color: $hover-color;
            }

            @else {
                border-color: $hover-background;
            }
        }
    }
}