// * $font-family, $font-size, $font-weight, $font-style, $line-height, $letter-spacing
// * usage:
// * @include font($some-font, 24px, 500, normal, 30px, 2.56em);
// ? use interpolation for expressions e.g #{max(17px, vw(17px))}
// * To ignore order use declaration of any attribute
// * @include font($font-family:val, $font-size:val, $font-weight:val, $font-style:val, $line-height:val, $letter-spacing:val);
@mixin font($font-family: null,
    $font-size: null,
    $font-weight: null,
    $font-style: null,
    $line-height: null,
    $letter-spacing: null) {

    // * FONT FAMILY
    @if type-of($font-family) !=null {
        font-family: $font-family;
    }

    // * FONT SIZE
    @if type-of($font-size) !=null {
        font-size: $font-size;
    }

    // * FONT WEIGHT
    @if type-of($font-weight) !=null {
        font-weight: $font-weight;
    }

    // * FONT STYLE
    @if type-of($font-style) !=null {
        font-style: $font-style;
    }

    // * LINE HEIGHT
    @if type-of($line-height) !=null {
        line-height: $line-height;
    }

    // * LETTER SPACING
    @if type-of($letter-spacing) !=null {
        letter-spacing: $letter-spacing;
    }
}