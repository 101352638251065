.about-video-container {
  width: 100%;
  padding: 0 max(30px, vw(100px));
  max-width: 100%;
  margin: max(15px, vw(70px)) auto max(20px, vw(125px));
  @media screen and (max-width: $tablet) {
    padding: 0 30px;
  }
  @media screen and (max-width: $tablet-sm) {
    padding: 0;
  }
  video {
    width: 100%;
    max-width: 1710px;
    margin: 0 auto;
  }
}

.about-container {
  padding: 0 max(15px, vw(50px));
  .about-text-container {
    font-family: $font-default;
    text-align: center;
    width: 100%;
    max-width: max(260px, vw(795px));
    margin: 0 auto;
    font-weight: 300;
    .about-title {
      font-weight: 100;
      font-style: normal;
      font-size: max(22px, vw(63px));
      margin-bottom: max(20px, vw(50px));
    }
    p {
      font-weight: 300;
      font-style: normal;
      display: block;
      font-size: 15px;
      @media screen and (max-width: $mobile) {
        font-size: 13px;
      }
    }
  }
}
.about-photo-container {
  width: 100%;
  max-width: max(329px, vw(985px));
  margin: max(15px, vw(135px)) auto max(35px, vw(215px));
  @media screen and (max-width: $mobile) {
    margin: 15px auto 35px;
  }
}
