// thin 100
// light 300
$weight-thin: 100;
$weight-light: 300;
$weight-normal: 400;
$weight-medium: 500;

.title {
    &--big {
        //tropopoiisi apo 33px se 30px gia na xwraei se 375px to insta title opws sto psd
        @include font($font-default, #{max(30px, vw(93px))}, $weight-thin, normal, 1.2);
    }

    &--medium,
    &--team {
        @include font($font-default, #{max(30px, vw(63px))}, $weight-thin, normal, 1);
    }

    &--small,
    &--out-of-stock {
        @include font($font-default, #{max(20px, vw(22px))}, $weight-thin, normal, 1);
    }

}

.text {
    @include font($font-default, #{max(16px, vw(16px))}, $weight-medium, normal, 1);

    &--team {
        @include font($font-default, #{max(14px, vw(15px))}, $weight-thin, normal, 1);
    }

    &--breadcrumbs {
        @include font($font-default, #{max(13px, vw(13px))}, $weight-thin, normal, 1);
        &--static {
            @include font($font-default, #{max(10px, vw(13px))}, $weight-thin, normal, 1);
        }
    }

    &--light {
        @include font($font-default, #{max(14px, vw(14px))}, $weight-light, normal, 1);
        
        &--big {
            @include font($font-default, #{max(18px, vw(18px))}, $weight-light, normal, 1);
        }
    }

    &--normal {
        @include font($font-default, #{max(14px, vw(14px))}, $weight-normal, normal, 1);
    }

    // &--big {
    //     @include font($font-default, #{max(14px, vw(18px))}, $weight-medium, normal, 1);
    // }

    // &--medium {
    //     @include font($font-default, #{max(13px, vw(14px))}, $weight-medium, normal, 1);
    // }

    &--small {
        @include font($font-default, #{max(14px, vw(14px))}, $weight-thin, normal, 1);
    }
}

.details {
    @include font($font-default, #{max(12px, vw(12px))}, $weight-medium, normal, 1);

    &--small {
        @include font($font-default, #{max(8px, vw(8px))}, $weight-light, normal, 1);
    }

    &--big {
        @include font($font-default, #{max(24px, vw(24px))}, $weight-light, normal, 1);
    }

    &--big-thin {
        @include font($font-default, #{max(13px, vw(24px))}, $weight-thin, normal, 1);
    }

    &--medium-thin {
        @include font($font-default, #{max(13px, vw(21px))}, $weight-thin, normal, 1);
    }

    &--discover,
    &--pre-order {
        @include font($font-default, #{max(15px, vw(15px))}, $weight-thin, normal, 1);
    }

    &--view-product {
        @include font($font-default, #{max(13px, vw(13px))}, $weight-thin, normal, 1);

    }
    
    &--appointment {
        @include font($font-default, #{max(12px, vw(18px))}, $weight-light, normal, 1);
    }

    &--pop-up {
        &-title {
            @include font($font-default, #{max(18px, vw(18px))}, $weight-light, normal, 1);
        }

        &-list {
            @include font($font-default, #{max(13px, vw(13px))}, $weight-thin, normal, 1);
        }

        &-cta {
            @include font($font-default, #{max(17px, vw(24px))}, $weight-thin, normal, 1);
        }
    }

    &--shop-the-look {
        @include font($font-default, #{max(13px, vw(22px))}, $weight-thin, normal, 1);
    }

    &--italic {
        @include font($font-default, #{max(12px, vw(12px))}, $weight-thin, italic, 1);
    }

    &--sizes {
        @include font($font-default, #{max(10px, vw(12px))}, $weight-medium, normal, 1);
    }


}

.menu {
    &__category {
        @include font($font-default, #{max(16px, vw(16px))}, $weight-thin, normal, 1);
    }

    &__sub-category {
        @include font($font-default, #{max(13px, vw(13px))}, $weight-light, normal, 1);
    }
}
