#information-information {
  font-family: $font-default!important;
  padding: max(55px, vw(90px)) max(15px, vw(250px)) 35px max(15px, vw(115px)) ;
  @media screen and (max-width: $tablet-sm){
    padding: 55px 15px 35px;
  }
  .legals-container {
    width: 100%;
    max-width: 1140px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $tablet-sm){
      flex-direction: column;
    }
    .information-menu {
      @media screen and (max-width: $tablet-sm){
        margin-bottom: 50px;
      }
      h2 {
        display: none;
      }
      .info-menu {
        li {
          margin-bottom: 15px;
          @include font($font-default, #{max(12px, vw(12px))}, $weight-normal, normal, 1);
          a {
            display: inline-block;
            text-transform: uppercase;
            &.active {
              font-weight: 500;
              border-bottom: 1px solid #909090;
              @media screen and (max-width: $tablet-sm){
                font-weight: 500;
              }
            }
            &:hover, &:active, &:visited{
              color:unset;
            }
          }
        }
      }
    }
    .legals-content-container {
      #content {
        width: 100%;
        max-width: 560px;
        @media screen and (max-width: $tablet){
          max-width: 450px;
          margin: 0 auto;
        }
        .legals-text-title {
          text-transform: uppercase;
          margin-bottom: 15px;
          @include font($font-default, #{max(17px, vw(17px))}, $weight-medium, normal, 1);
        }
        .legals-text {
          @include font($font-default, #{max(14px, vw(14px))}, $weight-normal, normal, 1.2);
        }
      }

    }
  }
}

