.account-voucher-container{
  padding: max(35px, vw(80px)) max(15px, vw(90px)) max(35px, vw(135px));
  @media (max-width: $tablet) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: $tablet-sm) {
    padding: 0 15px 55px;
  }
  .breadcrumbs-wrap {
    padding: 0;
  }
  .account-voucher-wrapper {
    display: flex;
    max-width: 1380px;
    @media (max-width: $tablet) {
      flex-direction: column;
    }
  }
  .account-voucher-col-left{
    width: 100%;
    max-width: 760px;
    .gift-slider {
      display: flex;
      padding-top: max(5px, vw(50px));
      .mz-hint {
        display: none;
      }
      @media (max-width: $laptop) {
        max-width: 610px;
      }
      @media (max-width: $laptop-sm) {
        max-width: 500px;
      }
      @media (max-width: 480px) {
        max-width: 347px;
      }
      a {
        img {
          border-radius: 13px;
          @media (max-width: $laptop) {
            border-radius: 7px;
          }
        }
      }
      #gift-zoom {
        margin-right: 15px;
        @media (max-width: $laptop-sm) {
          margin-right: 5px;
        }
        @media (max-width: 480px) {
          max-width: 275px;
        }
        img {
          object-fit: cover;
          object-position: center;
          height: 410px;
          @media (max-width: $laptop) {
            height: 328px;
          }
          @media (max-width: $laptop-sm) {
            height: 270px;
          }
          @media (max-width: 480px) {
            height: 197px;
          }
        }

      }
      .vertical-slider {
        display: flex;
        flex-direction: column;
        max-width: 140px;
        @media (max-width: $laptop) {
          max-width: 110px;
        }
        @media (max-width: $laptop-sm) {
          max-width: 94px;
        }
        @media (max-width: 480px) {
          max-width: 67px;
        }
        .gift-thumb {
          border: none;
          &:not(:last-child) {
            margin-bottom: 10px;
            @media (max-width: $laptop-sm) {
              margin-bottom: 5px;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .account-voucher-col-right {
    width: 100%;
    max-width: 600px;
    @media (max-width: $tablet) {
      padding-top: 30px;
    }
    .gift-card-details {
      width: 100%;
      padding-left: max(30px, vw(220px));
      .product-price-list {
        margin-bottom: 35px;
      }
      .main-info {
        .right {
          display: none;
        }
        @media (max-width: $tablet) {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          @media (max-width: 480px) {
            max-width: 360px;
          }
          .right {
            display: block;
            .share-section {
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              .share-choices {
                padding: 5px 0 0;
              }
            }
            .wishlist-btn {
              margin-top: 0;
              .desktop-only {
                display: none;
              }
            }
          }
        }
      }

      @media (max-width: $laptop-sm) {
        padding-left: 50px;
      }
      @media (max-width: $tablet) {
        padding-left: 0;
        max-width: 500px;
      }
      @media (max-width: 480px) {
        max-width: 335px;
      }
      @media (max-width: 480px) {
        max-width: 100%;
      }
      .gift_card_description {
        width: 100%;
        max-width: 190px;
        margin: 25px 0 20px;
        h4 {
          @include font($font-default, #{max(16px, vw(16px))}, $weight-thin, normal, 1);
          margin-bottom: 10px;
        }
        p {
          @include font($font-default, #{max(12px, vw(12px))}, $weight-light, normal, 1);
        }
        ul {
          padding-left: 15px;
          @include font($font-default, #{max(12px, vw(12px))}, $weight-light, normal, 1);
          li {
            list-style-type: circle!important;
          }
        }
      }
      .gift-card-form-wrapper {
        max-width: 330px;
      }

      .gift-card-form {
        .form-element {
          margin-bottom: 5px;
          border: none;
          &.voucher_theme {
            display: none;
          }
          .form-control {
            border-radius: 0;
            border: none;
            border-bottom: 2px solid $black;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            height: unset;
            padding: 10px 0 25px;
            @include font($font-default, #{max(16px, vw(16px))}, $weight-light, normal, 1);
            &::-webkit-input-placeholder {/* Chrome/Opera/Safari/Edge */
              @include font($font-default, #{max(16px, vw(16px))}, $weight-thin, normal, 1);
              color: $black;
            }

            &::-ms-input-placeholder { /* Microsoft Edge */
              @include font($font-default, #{max(16px, vw(16px))}, $weight-thin, normal, 1);
              color: $black;
            }

            &:-ms-input-placeholder {/* IE 10+ */
              @include font($font-default, #{max(16px, vw(16px))}, $weight-thin, normal, 1);
              color: $black;
            }

          }
        }

        .terms {
          margin-top: 15px;
        }
        .cta--outlined {
          width: 100%;
          max-width: 100%;
          font-size: max(24px, vw(24px));
          font-weight: 300;
          padding: 8px;
          margin-top: 15px;
        }
      }
    }
  }
}

.desktop-only {
  display: flex;
  @media (max-width: $tablet) {
    display: none;
  }
}

.alert {
  margin-top: 25px;
  @include font($font-default, #{max(14px, vw(14px))}, $weight-normal, normal, 1);
}
