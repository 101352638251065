/// Mixin to prefix several properties at once
/// @param {Map} $declarations - Declarations to prefix
/// @param {List} $prefixes (()) - List of prefixes to print
@mixin prefix($declarations, $prefixes: ()) {

    @each $property,
    $value in $declarations {
        @each $prefix in $prefixes {
            #{'-' + $prefix + '-' + $property}: $value;
        }

        // Output standard non-prefixed declaration
        #{$property}: $value;
    }
}